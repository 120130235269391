function identify(user) {
    if (window.hj) {
        window.hj(
            'identify',
            user.user_id,
            {
                'GGW ID': user.user_id, // Send numbers without quotes.
                'Country ID': user.country_id, // Send strings with quotes around them.
                'User type': user.main_type, // Send strings with quotes around them.
                'Plan': user.tariff, // Send strings with quotes around them.
                'Acceptation': user.is_accepted ? 'true' : 'false', // Send strings with quotes around them.
                'Register date': user.created_at.substr(0, 10), // Send dates in ISO-8601 format. (2019-06-20Z)
            }
        );
    } else {
        console.log('Hotjar doesn`t connected');
    }
}

export default function () {
    return {
        identify
    };
}
