<template>
  <MainLayout has-header has-footer>
    <MainContent is-big-width class="!bg-transparent">
      <AppHeader
        class="lg:hidden"
        is-btn-menu
        is-bg-white
        is-big-width
        has-logo
      >
        <ModalFilterMain
            class="lg:hidden absolute top-4 left-5 z-20"
            :sections="state.sections"
            :full-version="fullVersion"
            :count-filters-on="usedSectionsCount"
        />
        <img
            class="mx-auto h-8"
            src="/src/assets/images/welcome-logo.png"
            alt="logo Go Global World"
        >
      </AppHeader>
      <div class="mt-14 lg:flex gap-4">
        <div class="w-[232px] space-y-1 hidden lg:block relative">
          <div>
            <LockBlock
              v-if="!fullVersion && Object.keys(state.sections).length"
              link="/updating-plan"
            >
              <template #link> Upgrade plan </template>
              <template #text>
                <div class="text-center"> to use filters </div>
              </template>
            </LockBlock>
            <Card class="flex justify-between space-y-2 !py-3 !px-4 rounded mb-1">
              <Btn
                  class="w-full mr-3 cursor-default"
                  :theme="usedSectionsCount ? undefined : 'outline'"
                  :class="!usedSectionsCount ? 'bg-[#F3F5F8] !border-[#F3F5F8]' : ''"
                  size="xs"
              >
                <span>
                  <template v-if="usedSectionsCount">
                    +{{ usedSectionsCount }} Filters on
                  </template>
                  <template v-else>
                    No filters
                  </template>
                </span>
              </Btn>
              <Btn
                  class="max-w-max !mt-0"
                  :class="!usedSectionsCount ? 'bg-[#F3F5F8] !border-[#F3F5F8] cursor-default' : ''"
                  theme="outline"
                  size="xs"
                  @click="usedSectionsCount && clearAll()"
              >
                Clear all
              </Btn>
            </Card>
            <ItemFilter
              v-for="sectionName of Object.keys(state.sections)"
              class="mb-1"
              :id="sectionName"
              :key="sectionName"
              :data="state.sections[sectionName]"
            />
          </div>
          <div />
        </div>
        <div class="flex-1">
          <Members
            v-if="Object.keys(state.sections).length"
            :filters-state="state"
            :full-version="fullVersion"
          />
        </div>
      </div>
      <MenuBottom class="lg:hidden" is-big-width />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import Card from '@/screens/profile-go-global-world-static/components/Card.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import ItemFilter from '@/screens/connections/components/ItemFilter.vue';
import Btn from '@/components/Btn.vue';
import Members from '@/screens/connections/components/Members.vue';
import AppHeader from '@/components/AppHeader.vue';
import useMembersFilters from '@/compositions/useMembersFilters';
import ModalFilterMain from '@/screens/connections/components/ModalFilterMain.vue';
import useMembers from '@/compositions/useMembers';
import MenuBottom from '@/components/MenuBottom.vue';
import LockBlock from '@/screens/dashboard-desktop/components/LockBlock.vue';

const { state, load, clearSection, usedSectionsCount } = useMembersFilters();
const { fullVersionAvailable } = useMembers();
const fullVersion = fullVersionAvailable();

load();

function clearAll() {
  for (const id of Object.keys(state.sections)) {
    clearSection(state.sections[id]);
  }
}
</script>
