<template>
  <header
    class="flex-none fixed w-full z-50"
    :class="[
      isBigWidth ? 'max-w-[1120px]' : 'max-w-[640px]',
      { 'h-14': isAuthenticated },
    ]"
  >
    <div
      v-if="isAuthenticated"
      class="py-4 px-5 relative flex items-center h-full"
      :class="isBgWhite ? 'bg-white border-b border-[#DFE4E8]' : 'bg-[#008AFC]'"
    >
      <div class="w-5">
        <BtnBack
          v-if="isBtnBack"
          class="w-5"
          :fill="isBgWhite ? '#000000' : '#ffffff'"
          @click="back()"
        />
      </div>
      <div class="flex-1">
        <slot>
          <div
            v-if="hasLogo"
            class="flex items-center gap-1 mx-auto justify-center"
          >
            <img
              src="/src/assets/images/logo.png"
              alt="logo Go Global World"
              width="43"
              height="28"
            />
            <h2 class="font-bold">Go Global World</h2>
          </div>
        </slot>
      </div>
      <div class="w-5">
        <button v-if="isBtnMenu" class="w-5 relative" @click="openMenuModal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
          >
            <path
              d="M0 0H18V2H0V0ZM6 7H18V9H6V7ZM0 14H18V16H0V14Z"
              :fill="isBgWhite ? '#000000' : '#ffffff'"
            />
          </svg>
        </button>
      </div>

      <MenuModal ref="menuModal" />
    </div>
    <div
      v-else
      class="py-2 px-5 relative flex h-full"
      :class="isBgWhite ? 'bg-white border-b border-[#DFE4E8]' : 'bg-[#008AFC]'"
    >
      <div class="pt-[0.4rem]">
        <router-link to="/" class="flex items-center gap-0.5">
          <img
            src="/src/assets/images/logo.png"
            width="43"
            height="28"
            alt="logo"
          />
          <span class="text-lg font-bold leading-6 -tracking-[0.8px]"
            >Go Global World</span
          >
        </router-link>
      </div>
      <div class="ml-auto">
        <button
          class="flex-1 px-2 py-3 bg-white text-[#292C2E] font-bold rounded-l rounded-r mr-2 text-sm"
          @click="routeToSignIn"
        >
          Log In
        </button>
        <button
          class="flex-1 px-2 py-3 bg-[#008AFC] text-white font-bold rounded-r rounded-l whitespace-nowrap text-sm"
          @click="routeToSignUp"
        >
          Sign Up
        </button>
      </div>
    </div>
  </header>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '@/compositions/useAuth.js';
import BtnBack from './BtnBack.vue';
import MenuModal from './MenuModal.vue';

const props = defineProps({
  isBtnBack: {
    type: Boolean,
    default: false,
  },
  isBtnMenu: {
    type: Boolean,
    default: false,
  },
  isBgWhite: {
    type: Boolean,
    default: false,
  },
  hasLogo: {
    type: Boolean,
    default: false,
  },
  isBigWidth: { type: Boolean, default: false },
  asLogged: { type: Boolean, default: false },
});

const router = useRouter();
const { state } = useAuth();
const menuModal = ref(null);
const isAuthenticated = computed(() => {
  return props.asLogged || state.isAuthenticated;
});

const routeToSignUp = async () => {
  await router.push({ path: '/sign-up' });
};

const routeToSignIn = async () => {
  await router.push({ path: '/login' });
};

function openMenuModal() {
  menuModal.value.openModal();
}

function back() {
  router.back();
}
</script>
