import { computed, reactive } from 'vue';
import useMatchEndpoint from "@/compositions/useMatchEndpoint.js";

const COMMAND_GET_CARDS = 'getcards';
const COMMAND_GET_FILTERS = 'getfilters';
const COMMAND_PUT_FILTERS = 'putfilters';
const COMMAND_STATUS = 'status';

export const COMMAND_LIKE = 'connect';
export const COMMAND_DISLIKE = 'pass';
export const COMMAND_THINK = 'forlater';
export const COMMAND_CRITERIA = 'criteria';

const state = reactive({
  profile: null,
  isPending: false,
  noMore: false,
});

const profileData = computed(() => {
  if (state.profile === null) {
    return {};
  }

  return {};
});

const match = (command, params = {}) => useMatchEndpoint().send(command, params);
const getCards = async (role) => {
  state.isPending = true;

  try {
    const {
      user = {},
      company = { data: {} },
      sales = { data: {} },
      criteria = { data: {} },
      pitch = { data: {} },
    } = await match(COMMAND_GET_CARDS, { type: role });

    state.profile = {
      ...user,
      ...company.data,
      ...sales.data,
      ...criteria.data,
      ...pitch.data,
    };
    state.noMore = false;
  } catch ({ error }) {
    if (error === 'No more cards') {
      state.noMore = true;
    }
  }

  state.isPending = false;
};

const setFilters = async (role, filters = {}) => {
  await match(COMMAND_PUT_FILTERS, { filters });
  await getCards(role);
};

const like = async (params, onlyReaction = false) => {
  const result = await match(COMMAND_LIKE, params);

  if (onlyReaction) return result;

  await getCards(params.type);

  return result;
};

const dislike = async (params, onlyReaction = false) => {
  const result = await match(COMMAND_DISLIKE, params);

  if (onlyReaction) return result;

  await getCards(params.type);

  return result;
};

const think = async (params, onlyReaction = false) => {
  const result = await match(COMMAND_THINK, params);

  if (onlyReaction) return result;

  await getCards(params.type);

  return result;
};

const criteria = async () =>
  Promise.all([match(COMMAND_CRITERIA), match(COMMAND_GET_FILTERS)]);

const status = (params) => match(COMMAND_STATUS, params);

export default function useMatching() {
  return {
    state,

    profileData,

    getCards,
    setFilters,
    criteria,
    like,
    dislike,
    think,

    status,
  };
}
