<template>
  <MainLayout has-header class="!bg-white min-h-screen">
    <AppHeader
      is-bg-white
      :is-btn-back="state.isAuthenticated"
      is-big-width
      class="lg:hidden"
      has-logo
    />
    <div
      v-if="isPaymentOk"
      class="grid lg:grid-cols-2 justify-center lg:justify-left h-full"
      :style="'height:' + vh + 'px'"
    >
      <div class="flex flex-col justify-center items-end px-4 lg:pl-4 lg:pr-0">
        <div class="lg:max-w-[400px] lg:mr-[160px]">
          <img
            v-if="!isFree"
            class="w-14 mx-auto lg:ml-0"
            src="/src/assets/images/startup-profile/payment-success-icon.svg"
            alt=""
          />
          <h1
            class="mt-4 text-[#292C2E] text-2xl sm:text-3xl lg:text-5xl font-bold text-center lg:text-left"
          >
            <template v-if="isFree">Welcome to the platform!</template>
            <template v-else>Payment has completed!</template>
          </h1>
          <p
            class="mt-2 sm:mt-4 mb-6 sm:mb-8 text-base text-[#292C2E] text-center lg:text-left"
          >
            <template v-if="isFree">You're now on the free tier with restricted access to the platform.</template>
            <template v-else>Now, you can go back to Go Global World and find your perfect match! Have a great business.</template>

          </p>

          <router-link
            :to="{ name: 'WelcomeScreen' }"
            class="max-w-max mx-auto block lg:ml-0"
          >
            <Btn> Go to platform </Btn>
          </router-link>
        </div>
      </div>
      <div class="hidden lg:flex p-6 items-center">
        <img
          class="rounded-2xl 2xl:max-w-[672px]"
          src="/src/assets/images/startup-profile/payment-success.jpg"
          alt=""
        />
      </div>
    </div>
    <div
      v-else
      class="grid lg:grid-cols-2 justify-center lg:justify-left h-full"
      :style="'height:' + vh + 'px'"
    >
      <div class="flex flex-col justify-center items-end px-4 lg:pl-4 lg:pr-0">
        <div class="lg:max-w-[400px] lg:mr-[160px]">
          <img
            class="w-14 mx-auto lg:ml-0"
            src="/src/assets/images/startup-profile/payment-error-icon.svg"
            alt=""
          />
          <h1
            class="mt-4 text-[#292C2E] text-2xl sm:text-3xl lg:text-5xl font-bold text-center lg:text-left"
          >
            Payment has been declined!
          </h1>
          <p
            class="mt-2 sm:mt-4 mb-6 sm:mb-8 text-base text-[#292C2E] text-center lg:text-left"
          >
            It seems like something went wrong during the payment. Please try
            once again! If you’ve tried and still see this error, please contact
            us.
          </p>

          <div class="flex gap-2 justify-center lg:justify-start">
            <router-link
              :to="{ name: 'UpdatingPlanScreen' }"
              class="max-w-max w-full"
            >
              <Btn> Back to plans </Btn>
            </router-link>
            <Btn
              theme="outline"
              class="max-w-max w-full"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@goglobal.world"
            >
              Contact us
            </Btn>
          </div>
        </div>
      </div>
      <div class="hidden lg:flex p-6 items-center">
        <img
          class="rounded-2xl 2xl:max-w-[672px]"
          src="/src/assets/images/startup-profile/payment-error.jpg"
          alt=""
        />
      </div>
    </div>
    <MenuBottom is-big-width class="lg:hidden" />
  </MainLayout>
</template>

<script setup>
import { useRoute } from 'vue-router';
import useAuth from '@/compositions/useAuth.js';
import MainLayout from '../../layouts/MainLayout.vue';
import AppHeader from '../../components/AppHeader.vue';
import MenuBottom from '../../components/MenuBottom.vue';
import Btn from '../../components/Btn.vue';

const { state } = useAuth();

const route = useRoute();

const isFree = Boolean(route.query.free);
const vh = window.innerHeight - 64;

const isPaymentOk = route.params.status === 'success';
</script>
