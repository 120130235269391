export default function matchMiddleware(to, from, next) {
  if (
      ['MatchWith'].includes(to.name)
  ) {
    next();
    return;
  }

  next({ name: 'MatchWith' });
}
