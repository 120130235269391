import { cloneDeep } from 'lodash';

const normalizeAction = (action) => {
  if (typeof action !== 'string') {
    return action;
  }

  return {
    url: action,
    id: action.slice(0, -1),
  };
};

function prepareOptions(options) {
  if (!Array.isArray(options)) {
    return options;
  }

  return options.map((option) => {
    if (typeof option === 'string') {
      return {
        id: option,
        name: option,
      };
    }

    return option;
  });
}

function resolveValidationRules(rules) {
  if (!rules) {
    return rules;
  }

  if (typeof rules === 'string') {
    return rules;
  }

  if (!rules.regex) {
    return rules;
  }

  Object.assign(rules, { regex: new RegExp(rules.regex, 'i') });

  return rules;
}

export default class SmartForm {
  constructor(walker, schema = [], initialValues = {}) {
    this.walker = walker;
    this.additionalFields = {};

    this.visibilityState = {};
    this.initialValues = { ...initialValues };
    this.values = { ...initialValues };
    this.preparedValues = SmartForm.prepareValues({ ...initialValues });
    this.schema = this.prepareSchema(schema);
  }

  prepareSchema(structure) {
    const preparedSchema = [];

    if (!structure) {
      return preparedSchema;
    }

    const schema = cloneDeep(structure);

    this.walker.walk(schema, 'structure', (node) => {
      if (['text', 'textarea'].includes(node.type)) {
        Object.assign(node, {
          maxLength: SmartForm.getMaxLengthRule(node.rules),
        });
      }

      if (node.options) {
        Object.assign(node, { options: prepareOptions(node.options) });
      }

      if (node.action) {
        Object.assign(node, { action: normalizeAction(node.action) });
      }

      if (node.visibleConditions) {
        this.visibilityState[node.name] = true;
      }
    });

    return schema;
  }

  getAdditionalField(name) {
    return cloneDeep(this.additionalFields[name]?.structure || []);
  }

  getSchema() {
    return this.schema;
  }

  setSchema(schema) {
    this.schema = this.prepareSchema(schema);
  }

  getInitialValues() {
    return this.values;
  }

  getAdditionalFields() {
    return this.additionalFields;
  }

  getPreparedValues() {
    return this.preparedValues;
  }

  getFieldByName(name) {
    let result = null;

    this.walker.walk(this.schema, 'structure', (field) => {
      if (field.name === name) {
        result = field;
      }
    });

    return result;
  }

  static getValidationSchema(schema) {
    if (!schema) {
      return null;
    }

    return schema.reduce((acc, row) => {
      const res = row.reduce(
        (acc1, field) => ({
          ...acc1,
          [field.name]: resolveValidationRules(field.rules),
        }),
        {},
      );

      return { ...acc, ...res };
    }, {});
  }

  static prepareValues(values) {
    const result = {};

    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (Array.isArray(value)) {
        value.forEach((val, index) => {
          Object.keys(val).forEach((k) => {
            result[`${key}[${index}]${k}`] = val[k];
          });
        });
      } else {
        result[key] = value;
      }
    });

    return result;
  }

  static getMaxLengthRule(rules) {
    if (typeof rules === 'string') {
      const maxLength = rules
          .split('|')
          .find((rule) => rule.startsWith('max:'))
          ?.replace('max:', '');

      return maxLength ? Number(maxLength) : 0;
    } else if (rules?.max) {
      return Number(rules.max);
    }

    return 0;
  }
}
