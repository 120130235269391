<template>
  <input
    v-model="val"
    type="checkbox"
    :name="name"
  >
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  name: { type: String, required: true },
  value: { type: Boolean, default: false },
});

const emit = defineEmits({
  'update:model-value': null,
});
const { name } = toRefs(props);
const { value: fieldValue } = useField(
  name,
  undefined,
  { initialValue: props.modelValue || props.value },
);

const val = computed({
  get() {
    return props.modelValue || props.value;
  },

  set(value) {
    fieldValue.value = value;
    emit('update:model-value', value);
  },
});
</script>
