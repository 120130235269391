export default function referralMiddleware(to, from, next) {
  if (to.query.investor_id) {
    next({
      name: 'ProfileInvest',
      params: {
        id: to.query.investor_id,
      },
    });

    return;
  }

  next();
}
