<template>
  <ul class="space-y-4">
    <li v-if="event.ended_for" class="flex gap-2 items-start">
      <img src="/src/assets/images/icons/calendar-icon.svg" alt="" />
      <div>
        <div class="text-[#292C2E] text-base font-bold">Past Event</div>
        <div class="text-sm text-[#292C2E]">
          This event ended {{ event.ended_for }}
        </div>
      </div>
    </li>
    <li v-else class="flex gap-2 items-start">
      <img src="/src/assets/images/icons/calendar-icon.svg" alt="" />
      <div>
        <div class="text-[#292C2E] text-base font-bold">
          {{ startDateFormatted }}
        </div>
        <div class="text-sm text-[#292C2E]">
          {{ startTimeFormatted }} to {{ endTimeFormatted }} PST
        </div>
      </div>
    </li>
    <li v-if="event?.is_partisipate" class="flex gap-2 items-start">
      <img src="/src/assets/images/icons/globe-icon.svg" alt="" />
      <div style="width: 88%">
        <div class="text-[#292C2E] text-base font-bold">
          {{ isOnline ? 'Event Link' : 'Location' }}
        </div>
        <div
          v-if="isOnline"
          class="text-sm text-[#008AFC] overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <a :href="event?.private_desc" target="_blank">
            {{ event?.private_desc }}
          </a>
        </div>

        <div v-else class="text-sm text-[#292C2E]">
          {{ event?.location }}
        </div>
      </div>
    </li>
    <template v-else>
      <li class="flex gap-2 items-start">
        <img src="/src/assets/images/icons/globe-icon.svg" alt="" />
        <div>
          <div class="text-[#292C2E] text-base font-bold">Location</div>
          <div class="text-sm text-[#292C2E]">
            {{ isOnline ? 'Online' : event?.location }}
          </div>
        </div>
      </li>
    </template>
    <li class="flex gap-2 items-start">
      <img src="/src/assets/images/icons/ticket-icon.svg" alt="" />
      <div>
        <div class="text-[#292C2E] text-base font-bold">Tickets</div>
        <div class="text-sm text-[#292C2E]">{{ event?.price_level }}</div>
      </div>
    </li>
  </ul>
</template>

<script setup>
import useEvents from '@/compositions/useEvents';
import { computed } from 'vue';

const { event, startDateFormatted, startTimeFormatted, endTimeFormatted } =
  useEvents();

const isOnline = computed(() => {
  if (event.value?.is_online === '1') {
    return true;
  }
  return false;
});
</script>
