<template>
  <div
    class="fixed bottom-0 w-full bg-white z-30 border-t border-[#DFE4E8]"
    :class="isBigWidth ? 'max-w-[1120px]' : 'max-w-[640px]'"
  >
    <div
      class="flex justify-between p-4 mx-auto max-w-[375px] w-full"
    >
      <router-link
        :to="dashboardRoute"
      >
        <div
          class="w-6 h-6 flex mx-auto items-center justify-center"
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.6749 21.6V14.2964C9.6749 13.6241 10.248 13.0791 10.9549 13.0791H14.7949C15.5018 13.0791 16.0749 13.6241 16.0749 14.2964V21.6M12.1331 2.62527L3.81311 8.25179C3.47545 8.48014 3.2749 8.84979 3.2749 9.24381V19.7741C3.2749 20.7825 4.13452 21.6 5.1949 21.6H20.5549C21.6153 21.6 22.4749 20.7825 22.4749 19.7741V9.24381C22.4749 8.84979 22.2744 8.48014 21.9367 8.25179L13.6167 2.62527C13.1726 2.32494 12.5772 2.32494 12.1331 2.62527Z"
                :stroke="isActive(DASHBOARD) ? '#008AFC' : '#676C71'"
                stroke-width="2"
                stroke-linecap="round"
            />
          </svg>
        </div>
        <div
          class="mt-1 text-sm leading-4 text-center"
          :class="isActive(DASHBOARD) ? 'text-[#008AFC]' : 'text-[#676C71]'"
        >
          Home
        </div>
      </router-link>
      <router-link
        :to="{ name: 'Connections'}"
      >
        <div class="w-6 h-6 flex mx-auto items-center justify-center">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.02539 18.2168C4.12403 16.3843 6.5319 15.1301 10.2885 15.1301C14.0451 15.1301 16.453 16.3843 17.5516 18.2168M18.2466 13.7406C20.236 14.7352 21.2307 15.7299 22.2254 17.7193M16.1776 6.1376C17.1137 6.64115 17.75 7.62991 17.75 8.7673C17.75 9.87183 17.1499 10.8362 16.258 11.3522M13.2726 8.76727C13.2726 10.4153 11.9366 11.7513 10.2885 11.7513C8.64045 11.7513 7.30444 10.4153 7.30444 8.76727C7.30444 7.11922 8.64045 5.7832 10.2885 5.7832C11.9366 5.7832 13.2726 7.11922 13.2726 8.76727Z"
                :stroke="isActive(MEMBERS) ? '#008AFC' : '#676C71'"
                stroke-width="2"
                stroke-linecap="round"
            />
          </svg>
        </div>
        <div
          class="mt-1 text-sm leading-4 text-center"
          :class="isActive(MEMBERS) ? 'text-[#008AFC]' : 'text-[#676C71]'"
          >
          Community
        </div>
      </router-link>
      <router-link
        :to="matchRoute"
      >
        <div class="w-6 h-6 flex mx-auto items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M18.075 17.35L14.5673 13.2154H12.3519L10.875 11.7385L13.8288 7.30769H18.9981M5.70576 3.61538L7.27217 5.18179C8.11387 6.02349 8.37297 7.28581 7.93089 8.39101V8.39101C7.479 9.52074 6.38483 10.2615 5.16808 10.2615H2.01346M20.475 11C20.475 16.3019 16.1769 20.6 10.875 20.6C5.57306 20.6 1.27499 16.3019 1.27499 11C1.27499 5.69806 5.57306 1.39999 10.875 1.39999C16.1769 1.39999 20.475 5.69806 20.475 11Z"
              :stroke="isActive(MATCHING) ? '#008AFC' : '#676C71'"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="mt-1 text-sm leading-4 text-center"
          :class="isActive(MATCHING) ? 'text-[#008AFC]' : 'text-[#676C71]'"
        >
          Matching
        </div>
      </router-link>
      <router-link
        to="/messages"
        class="relative"
      >
        <div class="w-6 h-6 flex mx-auto items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M18.8417 8.86667V4.06667C18.8417 2.88846 17.8866 1.93333 16.7084 1.93333H3.90836C2.73015 1.93333 1.77502 2.88846 1.77502 4.06667V12.4145C1.77502 13.5927 2.73015 14.5478 3.90836 14.5478H5.57792V19L10.0301 14.5478H10.3084M15.8156 17.2841L18.5982 20.0667V17.2841H18.8417C20.0199 17.2841 20.975 16.3289 20.975 15.1507V11.5333C20.975 10.3551 20.0199 9.4 18.8417 9.4H12.4417C11.2635 9.4 10.3084 10.3551 10.3084 11.5333V15.1507C10.3084 16.3289 11.2635 17.2841 12.4417 17.2841H15.8156Z"
              :stroke="isActive(MESSAGES) ? '#008AFC' : '#676C71'"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="mt-1 text-sm leading-4 text-center"
          :class="isActive(MESSAGES) ? 'text-[#008AFC]' : 'text-[#676C71]'"
        >
          Chat
        </div>
        <span
          v-if="hasNotification"
          class="rounded-2xl py-0.5 px-1.5 text-white font-medium text-xs leading-none bg-[#FF002A] absolute -top-1 right-1 translate-x-1/2"
        >{{ state.unread }}</span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import useUser from '@/compositions/useUser';
import { USER_ROLE_ADVISOR, USER_ROLE_INVESTOR, USER_ROLE_STARTUP } from '@/constants';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import useMessages from '../compositions/useMessages';

defineProps({
  isBigWidth: { type: Boolean, default: false },
});

const route = useRoute();
const { state, unread } = useMessages();

const hasNotification = computed(() => Number(state.unread) !== 0);

const MATCHING = 'matching';
const MEMBERS = 'members';
const DASHBOARD = 'dashboard';
const MESSAGES = 'messages';

const { user, hasTariff } = useUser();

const matchRoute = computed(() => (
  hasTariff.value
    ? { name: 'Match' }
    : { name: 'UpdatingPlanScreen' }));

const dashboardRoute = { name: 'ProfileDashboard' };

onMounted(() => unread());

function isActive(type) {
  const { name } = route;

  if (type === MATCHING) {
    return ['Match', 'MatchWith'].includes(name);
  } else if (type === MEMBERS) {
    return ['Connections'].includes(name);
  } else if (type === MESSAGES) {
    return window.location.pathname.search('/messages') === 0;
  } else if (type === DASHBOARD) {
    return ['ProfileDashboard', 'DashboardScreen'].includes(name);
  }

  return false;
}
</script>
