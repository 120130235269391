<template>
  <Card class="space-y-2 !py-3 !px-4 rounded">
    <div class="flex justify-between gap-1">
      <div class="text-base text-[#292C2E] font-bold">
        {{ data.label }}
      </div>
      <button class="text-sm text-[#676C71]" @click="clearSection(data)">
        <XMarkIcon class="w-4" />
      </button>
    </div>
    <InputSearch v-if="tooManyCheckboxes" size="xs" @input="filterSection(data, $event.target.value)" />
    <div
      class="max-h-[168px] h-full scroll-custom flex flex-wrap gap-1 lg:block transition-all"
      :class="tooManyCheckboxes ? 'overflow-hidden overflow-y-scroll' : 'overflow-hidden'"
    >
      <CheckboxFilter v-for="(checkboxId, index) of checkboxesIds" :id="id + checkboxId" :key="index" :data="data.checkboxes[checkboxId]" />
    </div>
  </Card>
</template>
<script setup>
import Card from '@/screens/profile-go-global-world-static/components/Card.vue';
import CheckboxFilter from '@/screens/connections/components/CheckboxFilter.vue';
import InputSearch from '@/screens/connections/components/InputSearch.vue';
import useMembersFilters from '../../../compositions/useMembersFilters.js';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
  id: { type: String },
  data: { type: Object },
});

const { filterSection, getSortedKeys, clearSection } = useMembersFilters();

const tooManyCheckboxes = getCheckboxesCount(props.data.checkboxes) > 7;
const checkboxesIds = getSortedKeys(props.data.checkboxes);

function getCheckboxesCount(data) {
  const keys = Object.keys(data);
  let count = keys.length;

  for (const key of keys) {
    const checkbox = data[key];

    if (checkbox.children) {
      count += getCheckboxesCount(checkbox.children);
    }
  }

  return count;
}
</script>

<style scoped>
.scroll-custom::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.scroll-custom::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #9DA1A6;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.scroll-custom::-webkit-scrollbar-track {
  background-color: #DFE4E8;
  border-radius: 4px;
}
</style>
