import {
  format,
  formatDuration,
  intervalToDuration,
  isToday,
  isValid,
  isYesterday,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const TIME_ZONE_LA = 'America/Los_Angeles';

const normalizeDate = (date) => {
  if (typeof date === 'string') {
    // replace white space to 'T' for date in format 00000-00-00 00:00:00
    // because for safari this is invalid format
    return new Date(date.replace(' ', 'T'));
  }

  if (!(date instanceof Date)) {
    return new Date(date);
  }

  if (Number.isNaN(date)) {
    throw new Error('Parameter "date" have unsupported format');
  }

  return date;
};

const addTZOffset = (date) => {
  return zonedTimeToUtc(date, TIME_ZONE_LA);
};

function withNormalizedDate(fn) {
  return (date, ...props) => {
    if (!date) return '';

    const normalDate = normalizeDate(date);

    return fn(new Date(normalDate), ...props);
  };
}

export default class DateEntity {
  static isValid = withNormalizedDate(isValid);

  static format = withNormalizedDate(format);

  static addTZOffset = withNormalizedDate(addTZOffset);

  static duration = (start, end) => {
    const duration = intervalToDuration({
      start: normalizeDate(start),
      end: normalizeDate(end),
    });

    return formatDuration(duration, {
      format: ['months', 'days'],
      delimiter: ' and ',
    });
  };

  static isToday = withNormalizedDate(isToday);

  static isYesterday = withNormalizedDate(isYesterday);

  static FORMAT_DD_SLASH_MM_SLASH_YY = 'dd/MM/yy';

  static FORMAT_MM_SLASH_DD_SLASH_YY = 'MM/dd/yy';

  static FORMAT_MMMM_DD = 'MMMM dd';

  static FORMAT_HH_MM = 'HH:mm';

  static FORMAT_HH_MM_AMPM = 'hh:mm a';

  static FORMAT_SERVER = 'yyyy-MM-dd HH:mm:ss';

  static FORMAT_FULL_MONTH_DAY_COMMA_FULL_YEAR = 'MMMM dd, yyyy';
}
