import useAuth from '@/compositions/useAuth';
import useUser from '@/compositions/useUser';
import useBlocks from '@/compositions/useBlocks';

const { isAuthenticated } = useAuth();
const { user } = useUser();
const { getAll } = useBlocks();

export default async function profileMiddleware(to, from, next) {
  const loggedIn = await isAuthenticated();
  try {
    const { user: userProfile } = await getAll(to.params.id, 'profile');
    if (user?.user_id === to.params.id) {
      return next();
    }

    const hasTariff = userProfile?.tariff || userProfile?.tariff_text;

    if (loggedIn || hasTariff) {
      return next({ name: 'PublicStartupProfile', params: to.params });
    }

    return next({ name: 'WelcomeScreen' });
  } catch (error) {
    return next({ name: 'WelcomeScreen' });
  }
}
