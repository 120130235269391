import { USER_ROLE_ADVISOR, USER_ROLE_INVESTOR, USER_ROLE_STARTUP } from "../constants.js";
import { reactive, computed } from "vue";
import useFilters from "./useFilters.js";

const { store, restore, getCheckedIds, buildSection, getSortedKeys, clearSection, filterSection, fetchCriteria } = useFilters('members_filters');

const state = reactive({
    // search: '',
    hiddenViewedProfiles: false,
    sections: {}
});

const usedSectionsCount = computed(
    () => {
        const sectionsLengths = [
            getCheckedIds(state.sections?.role?.checkboxes || {}).length,
            getCheckedIds(state.sections?.stage?.checkboxes || {}).length,
            getCheckedIds(state.sections?.businessModel?.checkboxes || {}).length,
            getCheckedIds(state.sections?.focusVertical?.checkboxes || {}).length,
            getCheckedIds(state.sections?.minorities?.checkboxes || {}).length,
            getCheckedIds(state.sections?.focusGeography?.checkboxes || {}).length,
        ];

        return sectionsLengths.reduce((result, length) => length ? (result + 1) : result, 0);
    }
);

function save() {
    const data = {
        // search: state.search,
        hiddenViewedProfiles: state.hiddenViewedProfiles,
        sections: {
            role: getCheckedIds(state.sections.role.checkboxes),
            // status: getCheckedIds(state.sections.status.checkboxes),
            stage: getCheckedIds(state.sections.stage.checkboxes),
            businessModel: getCheckedIds(state.sections.businessModel.checkboxes),
            focusVertical: getCheckedIds(state.sections.focusVertical.checkboxes),
            minorities: getCheckedIds(state.sections.minorities.checkboxes),
            focusGeography: getCheckedIds(state.sections.focusGeography.checkboxes),
        }
    };

    store(data);
}

function getFromCookies(defaultDataOnly = false) {
    const defaultData = {
        // search: state.search,
        hiddenViewedProfiles: state.hiddenViewedProfiles,
        sections: {
            role: [],
            // status: [],
            stage: [],
            businessModel: [],
            focusVertical: [],
            minorities: [],
            focusGeography: [],
        }
    };
    try {
        return (!defaultDataOnly && restore()) || defaultData;
    } catch (e) {
        return defaultData;
    }
}

async function load(withEmptyFilters = false) {
    const storedValues = getFromCookies(withEmptyFilters);
    const rounds = await fetchCriteria('rounds');
    const transactions = await fetchCriteria('transactions');
    const verticals = await fetchCriteria('verticals');
    const regions = await fetchCriteria('region');
    const minorities = await fetchCriteria('minorities');

    // state.search = storedValues.search;
    state.hiddenViewedProfiles = storedValues.hiddenViewedProfiles;

    state.sections.role = {
        label: 'Role',
        checkboxes: {
            [USER_ROLE_INVESTOR]: { label: 'Investor', hidden: false, expanded: false, value: storedValues.sections.role.includes(USER_ROLE_INVESTOR) },
            [USER_ROLE_STARTUP]: { label: 'Startup', hidden: false, expanded: false, value: storedValues.sections.role.includes(USER_ROLE_STARTUP) },
            [USER_ROLE_ADVISOR]: { label: 'Advisor', hidden: false, expanded: false, value: storedValues.sections.role.includes(USER_ROLE_ADVISOR) }
        }
    };

    // state.sections.status = {
    //     label: 'Status',
    //     checkboxes: {
    //         ['some1']: { label: '???', hidden: false, expanded: false, value: storedValues.sections.role.includes('some1') },
    //         ['some2']: { label: '???', hidden: false, expanded: false, value: storedValues.sections.role.includes('some2') },
    //         ['some3']: { label: '???', hidden: false, expanded: false, value: storedValues.sections.role.includes('some3') }
    //     }
    // };

    state.sections.stage = buildSection(
    'Stage',
        rounds.length === 1 && rounds[0].children
            ? rounds[0].children
            : rounds,
        storedValues.sections.stage
    );

    state.sections.businessModel = buildSection(
    'Business model',
        transactions.length === 1 && transactions[0].children
            ? transactions[0].children
            : transactions,
        storedValues.sections.businessModel
    );

    state.sections.focusVertical = buildSection(
    'Focus vertical',
        verticals.length === 1 && verticals[0].children
            ? verticals[0].children
            : verticals,
        storedValues.sections.focusVertical
    );

    state.sections.minorities = buildSection(
    'Special groups',
        minorities.length === 1 && minorities[0].children
            ? minorities[0].children
            : minorities,
        storedValues.sections.minorities
    );

    state.sections.focusGeography = buildSection(
    'Geography',
        regions.length === 1 && regions[0].children
            ? regions[0].children
            : regions,
        storedValues.sections.focusGeography
    );
}

export default function() {
    return {
        state,
        usedSectionsCount,
        save,
        getFromCookies,
        load,
        filterSection,
        clearSection,
        getSortedKeys
    };
}
