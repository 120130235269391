import { reactive } from "vue";

export const ALL = 'all';

const state = reactive({
    search: '',
    type: ALL
});


export default function() {
    return {
        state,
    };
}
