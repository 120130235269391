<template>
  <div class="flex gap-3 lg:gap-4 items-center">
    <a class="flex gap-1.5" href="https://www.linkedin.com/company/go-global-world/" target="_blank">
      <img class="w-4 lg:w-3" src="/src/assets/images/profile-desktop/logo-linkedin.svg" alt="">
      <span class="text-base text-[#292C2E] hidden lg:inline">LinkedIn</span>
    </a>
    <a class="flex gap-1.5" href="https://www.goglobal.world/?utm_source=app-user-profile-id1" target="_blank">
      <img class="w-5 lg:w-3" src="/src/assets/images/profile-desktop/logo-website.svg" alt="">
      <span class="text-base text-[#292C2E] hidden lg:inline">Website</span>
    </a>
  </div>
</template>
