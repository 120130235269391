export default function makeTreeWalker() {
  return {
    walk(node, field, callback, parentNode = null) {
      node.forEach((item) => {
        if (Array.isArray(item)) {
          this.walk(item, field, callback, parentNode);
        } else if (item && typeof item === 'object') {
          callback(item, parentNode);

          if (Array.isArray(item[field])) {
            this.walk(item[field], field, callback, item);
          }
        }
      });
    },
  };
}
