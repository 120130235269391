import useAuth from '@/compositions/useAuth';
import useUser from '@/compositions/useUser';

const { isAuthenticated } = useAuth();
const { user } = useUser();
export default async function userRoleMiddleware(to, from, next) {
  await isAuthenticated();

  const { role } = user;

  if (role) {
    next();
  } else {
    next({ name: 'ChooseRoleScreen' });
  }
}
