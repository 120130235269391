export const getClipboardText = async () => {
  const TEXT_TYPE = 'text/plain';
  const content = await navigator.clipboard.read();
  let item;

  content.forEach((value) => {
    if (value.types.includes(TEXT_TYPE)) {
      item = value;
    }
  });
  if (!item) {
    return Promise.resolve('');
  }

  const blob = await item.getType(TEXT_TYPE);
  return blob.text();
};
