import { onMounted, provide, ref } from 'vue';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

const state = {};

const add = (id, node) => {
  state[id] = node;
};

const scrollInfoView = (id, params = {}) => {
  if (!state[id]) {
    return;
  }

  scrollIntoViewIfNeeded(state[id], {
    block: 'center',
    inline: 'center',
    behavior: 'smooth',
    ...params,
  });
};

export const provideScrollIntoView = () => {
  provide('scrollIntoView', scrollInfoView);
};

export default function useScrollIntoView(id) {
  const el = ref(null);

  onMounted(() => {
    add(id, el.value);
  });

  return {
    el,
  };
}
