<template>
  <MainLayout>
    <MainContent class="bg-white">
      <AppHeader is-btn-menu is-bg-white is-btn-back class="md:hidden">
        <img class="mx-auto h-8" src="/src/assets/images/welcome-logo.png" alt="logo Go Global World">
      </AppHeader>
      <div class="mt-14 px-4 pt-3 pb-6">
        <h1 class="text-lg text-[#292C2E] font-bold">
          Get help with signing up for free
        </h1>
        <p class="mt-1 text-sm text-[#292C2E]">
          Our experts can assist you to make an informative profile of your business!
        </p>

        <img class="w-full mt-4" src="/src/assets/images/profile-desktop/help.svg" alt="">

        <div class="mt-6 text-lg text-[#292C2E] font-bold">
          Teamwork to assemble a working puzzle.jpg
        </div>

        <ul class="mt-4 space-y-2.5">
          <li v-for="(item, index) in lists" :key="index" class="flex gap-3 items-center text-sm text-[#292C2E]">
            <span class="flex flex-none justify-center items-center w-9 h-9 border-2 border-[#008AFC] rounded-full text-lg leading-5 font-bold text-[#008AFC]">{{ item.number }}</span>
            {{ item.text }}
          </li>
        </ul>

        <Btn class="mt-6">
          Schedule a Zoom call
        </Btn>
      </div>
    </MainContent>
  </MainLayout>
</template>
<script setup>
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import AppHeader from '../../components/AppHeader.vue';
import Btn from '../../components/Btn.vue';

const lists = [
  {
    number: 1,
    text: 'We assist in the completion of your profile to locate and attract interested investors and advisors.',
  },
  {
    number: 2,
    text: 'We conduct onboarding in accordance with your expectations and goals.',
  },
  {
    number: 3,
    text: 'We authenticate your profile and provide access.',
  },
  {
    number: 4,
    text: 'Your profile becomes active and attracts potentially interested individuals.',
  },
];

</script>
