<template>
  <main
    class="flex flex-col flex-1 flex-grow mx-auto w-full overflow-hidden md:overflow-visible"
    :class="isBigWidth ? 'max-w-[1120px]' : 'max-w-[640px]'"
    :style="padding"
  >
    <div class="h-full flex flex-col overflow-auto md:overflow-visible">
      <slot />
    </div>
  </main>
</template>

<script setup>
import { computed } from 'vue';
import useFloatingPanel from '@/compositions/useFloatingPanel';

const { height } = useFloatingPanel();

const padding = computed(() =>
  height.value ? `padding-bottom: ${height.value + 20 + 76}px` : '',
);

defineProps({
  isBigWidth: { type: Boolean, default: false },
});
</script>
