import useMatchEndpoint from '@/compositions/useMatchEndpoint.js';
import {
  CONNECTION_REQUESTS,
  FOR_LATER,
  FUNDING_REQUESTS,
  ALL,
  MY_CONNECTION_REQUESTS,
  MY_PASS,
  MY_FOR_LATER,
  MY_FUNDING_REQUESTS,
} from '@/compositions/useConnectionFilters.js';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '../constants.js';

const fetchConnections = (params) => {
  const requestsMap = {
    [CONNECTION_REQUESTS]: 'connect',
    [FUNDING_REQUESTS]: 'funding_requests',
    [FOR_LATER]: 'forlater',
  };

  const myRequestsMap = {
    [MY_CONNECTION_REQUESTS]: 'connect',
    [MY_PASS]: 'pass',
    [MY_FOR_LATER]: 'forlater',
    [MY_FUNDING_REQUESTS]: 'funding_requests',
  };

  return useMatchEndpoint().send('members', {
    requests: requestsMap[params.type],
    actions: myRequestsMap[params.type],
    data: {
      relevant: params.relevant,
      role:
        params.userType === ALL
          ? [USER_ROLE_INVESTOR, USER_ROLE_STARTUP, USER_ROLE_ADVISOR]
          : [params.userType],
      offset: params.offset,
      limit: params.limit,
    },
  });
};

function buildFilters(filtersState) {
  return {
    userType: filtersState.userType,
    relevant: filtersState.relevant,
    type: filtersState.type,
  };
}

async function load(filtersState, offset, limit) {
  const params = {
    ...buildFilters(filtersState),
    offset,
    limit,
  };

  // return {
  //     count: 10,
  //     list: [
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //     ]
  // };

  return await fetchConnections(params);
}

export default function () {
  return {
    load,
  };
}
