<template>
  <Card>
    <h3 class="mb-3 sm:mb-4 text-[#292C2E] text-base sm:text-xl font-bold">
      Fundraising
    </h3>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
      <div>
        <div class="flex justify-between">
          <div class="text-[#292C2E] text-sm sm:text-base font-bold">
            Current
          </div>
          <div class="text-[#676C71] text-sm sm:text-base">
            Pre-Seed <span class="w-0.5 h-0.5 inline-block rounded-full bg-[#676C71] my-1" /> SAFE
          </div>
        </div>
        <div class="mt-1.5 relative">
          <div class="bg-[#DFE4E8] h-2 rounded overflow-hidden">
            <div class="bg-[#FF6242] hidden w-8 h-full" />
          </div>
          <div class="bg-[#68DB22] border-2 border-white rounded h-5 w-2 absolute top-0 right-1/3 -mt-2" />
        </div>
        <div class="mt-1.5 flex text-xs sm:text-sm justify-between">
          <div class="hidden text-[#292C2E] font-medium">
            $50,000
          </div>
          <div class="text-[#676C71]">
            Min, Target $300,000
          </div>
          <div class="text-[#292C2E] font-medium">
            $500,000
          </div>
        </div>
      </div>

      <List :lists="lists" />
    </div>
  </Card>
</template>
<script setup>
import Card from './Card.vue';
import List from './List.vue';

const lists = [
  {
    title: 'Raising',
    value: '$500’000',
  },
  {
    title: 'Pre-money valuation',
    value: '$5’000’000',
  },
  {
    title: 'Pro rata rights',
    value: 'Included',
  },
  {
    title: 'Syndicate Lead\'s Investment',
    value: '$100’000',
  },
  {
    title: 'Min check',
    value: '$50’000',
  },
  {
    title: 'Closing Deadline',
    value: 'June 01, 2023',
  },
];
</script>
