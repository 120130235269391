import { useCookies } from "@vueuse/integrations/useCookies";

const maxAge = 365 * 24 * 60 * 60;
const cookieKey = 'queryStringWithUtm';
const cookies = useCookies();

function collect() {
    const queryString = window.location.search;

    if (queryString.search('utm') !== -1) {
        cookies.set(cookieKey, queryString, { maxAge, path: '/' });
    }
}

function getQueryString() {
    return cookies.get(cookieKey);
}

export default function () {
    return {
        collect,
        getQueryString
    };
}
