<template>
  <p class="text-sm sm:text-base text-[#292C2E]">
    {{ textShowMoreTruncate }}
    <span v-if="isTextShort" class="-ml-1 mr-1">...</span>
    <button v-if="isTextShort" class="text-[#008AFC] font-medium" @click="showMoreText">
      Read more
    </button>
  </p>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  length: {
    type: String,
    default: '',
  },
});

const textShowMoreTruncate = ref(props.text.slice(0, props.length));
const isTextShort = ref(true);

const showMoreText = () => {
  isTextShort.value = false;
  textShowMoreTruncate.value = props.text.slice(0, props.text.length);
};
</script>
