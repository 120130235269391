<template>
  <div class="flex flex-col h-full">
    <div
      v-if="state.isAuthenticated"
      class="text-xl text-[#292C2E] font-bold hidden lg:block"
    >
      Event registration
    </div>
    <div class="lg:mt-6 h-full flex flex-col">
      <Login
        @complete-registration="SUBMIT"
        @switch-to-signup="toggleAuthComponent"
        @switchToVerification="toggleVerificationComponent"
        v-if="!showLogin && !showVerification"
      />
      <SignUp
        :event-id="event.id"
        @switch-to-login="toggleAuthComponent"
        @switchToVerification="toggleVerificationComponent"
        v-if="!state.isAuthenticated && showLogin && !showVerification"
      />
      <Verification v-if="!state.isAuthenticated && showVerification" />
      <TabGroup v-if="state.isAuthenticated && showLogin">
        <TabList class="grid grid-cols-3 rounded">
          <Tab
            v-for="(tab, index) in tabs"
            as="template"
            :key="index"
            v-slot="{ selected }"
            class="outline-none"
            @click="selectedTab = tab"
          >
            <button
              class="py-3 px-4 text-base text-[#292C2E] font-bold outline-none border border-[#DFE4E8] first:rounded-l last:rounded-r"
              :class="selected ? 'bg-[#DFE4E8]' : 'bg-white'"
            >
              {{ tab }}
            </button>
          </Tab>
        </TabList>
        <TabPanels class="flex-1 flex flex-col">
          <TabPanel class="flex-1 flex flex-col">
            <form class="mt-2 flex flex-col flex-1 justify-between">
              <div class="space-y-2 mb-6">
                <!-- <div
                  class="rounded border border-[#E7D3A4] px-2 py-3 bg-[#FFFDEB] flex items-center gap-2"
                >
                  <input
                    id="request"
                    aria-describedby="comments-description"
                    required="required"
                    type="checkbox"
                    class="mt-0.5 mx-0.5 h-5 w-5 rounded border-2 border-[#DFE4E8] text-[#008AFC]"
                  />
                  <label class="text-base text-[#292C2E]" for="request">
                    I request to join as an investor-judge
                  </label>
                </div> -->
                <div>
                  <FormInput v-model="company" name="name" placeholder-label>
                    Company name
                  </FormInput>
                </div>
                <div>
                  <FormInput
                    v-model="position"
                    name="position"
                    placeholder-label
                  >
                    Position
                  </FormInput>
                </div>
              </div>
              <Btn @click.prevent="SUBMIT()">Complete registration</Btn>
            </form>
          </TabPanel>
          <TabPanel class="flex-1 flex flex-col">
            <form class="mt-2 flex flex-col flex-1 justify-between">
              <div class="space-y-2 mb-6">
                <div>
                  <FormInput v-model="company" name="name" placeholder-label>
                    Company name
                  </FormInput>
                </div>
                <div>
                  <FormInput
                    v-model="position"
                    name="position"
                    placeholder-label
                  >
                    Position
                  </FormInput>
                </div>
              </div>
              <Btn @click.prevent="SUBMIT()">Complete registration</Btn>
            </form>
          </TabPanel>
          <TabPanel class="flex-1 flex flex-col">
            <form class="mt-2 flex flex-col flex-1 justify-between">
              <div class="space-y-2 mb-6">
                <div>
                  <FormInput v-model="company" name="name" placeholder-label>
                    Company name
                  </FormInput>
                </div>
                <div>
                  <FormInput
                    v-model="position"
                    name="position"
                    placeholder-label
                  >
                    Position
                  </FormInput>
                </div>
              </div>
              <Btn @click="SUBMIT()">Complete registration</Btn>
            </form>
          </TabPanel>
          <!-- <TabPanel class="flex-1 flex flex-col">
            <form
            
              class="mt-2 flex flex-col flex-1 justify-between lg:pb-6"
            >
              <div class="mb-6">
                <div class="space-y-2">
                  <div
                    class="rounded border border-[#E7D3A4] px-2 py-3 bg-[#FFFDEB] flex items-center gap-2"
                  >
                    <input
                      id="request"
                      aria-describedby="comments-description"
                      required="required"
                      type="checkbox"
                      class="mt-0.5 mx-0.5 h-5 w-5 rounded border-2 border-[#DFE4E8] text-[#008AFC]"
                    />
                    <label class="text-base text-[#292C2E]" for="request">
                      I request to join as an investor-judge
                    </label>
                  </div>
                  <div>
                    <FormInput name="name" placeholder-label>
                      Company name
                    </FormInput>
                  </div>
                  <div>
                    <FormInput name="position" placeholder-label>
                      Position
                    </FormInput>
                  </div>
                </div>
                <RadioGroup v-model="selectedTickets" class="mt-6">
                  <RadioGroupLabel class="text-base font-bold text-[#292C2E]">
                    Tickets
                  </RadioGroupLabel>

                  <div>
                    <RadioGroupOption
                      as="template"
                      v-for="ticket in tickets"
                      :key="ticket.id"
                      :value="ticket"
                      v-slot="{ active, checked }"
                    >
                      <div
                        :class="[
                          active
                            ? 'border-[#008AFC] ring-2 ring-[#008AFC]'
                            : 'border-[#DFE4E8]',
                          'mt-2 ml-0.5 mr-1.5 relative flex cursor-pointer rounded border bg-white p-3 focus:outline-none',
                        ]"
                      >
                        <span class="flex gap-2">
                          <span
                            :class="[
                              checked
                                ? 'bg-[#008AFC] border-transparent'
                                : 'bg-white border-[#DFE4E8]',
                              active ? 'ring-2 ring-[#008AFC]' : '',
                              'h-5 w-5 rounded-full border-2 flex items-center justify-center flex-none',
                            ]"
                            aria-hidden="true"
                          >
                            <span class="rounded-full bg-white w-2 h-2" />
                          </span>
                          <span class="flex flex-col">
                            <RadioGroupLabel
                              as="span"
                              class="block text-base text-[#676C71]"
                            >
                              {{ ticket.title }}
                            </RadioGroupLabel>
                            <RadioGroupDescription
                              as="span"
                              class="mt-2 text-base font-bold text-[#292C2E]"
                              >{{ ticket.price }}</RadioGroupDescription>
                            <RadioGroupDescription
                              as="span"
                              class="mt-2 text-sm text-[#292C2E]"
                            >
                              {{ ticket.description }}
                            </RadioGroupDescription>
                          </span>
                        </span>
                        <CheckCircleIcon
                          :class="[
                            !checked ? 'invisible' : '',
                            'h-5 w-5 text-indigo-600',
                          ]"
                          aria-hidden="true"
                        />
                        <span
                          :class="[
                            active ? 'border' : 'border-2',
                            checked ? 'border-[#008AFC]' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded',
                          ]"
                          aria-hidden="true"
                        />

                        <span
                          v-if="ticket.isRecommended"
                          class="inline-flex bg-[#008AFC] text-white uppercase py-0.5 px-1.5 rounded absolute -right-1.5 -top-1.5 text-[10px] leading-[14px] font-bold"
                        >
                          Recommended
                        </span>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
                <div class="mt-6">
                  <div class="text-base text-[#292C2E] font-bold">
                    Questions
                  </div>
                  <p class="mt-4 text-sm text-[#292C2E]">
                    1. What did you eat for breakfast today? Did you really like
                    it?
                  </p>
                  <div class="mt-1">
                    <FormInput name="position" placeholder-label>
                      Position
                    </FormInput>
                  </div>
                  <TitleLine class="my-4">Optional</TitleLine>
                  <p class="text-sm text-[#292C2E]">
                    1. What did you eat for breakfast today? Did you really like
                    it?
                  </p>
                  <div class="mt-1">
                    <FormInput name="answer" placeholder-label>
                      Answer here...
                    </FormInput>
                  </div>
                </div>
              </div>
              <Btn>Pay</Btn>
            </form>
          </TabPanel>
          <TabPanel class="flex-1 flex flex-col">
            <form class="mt-4 flex flex-col flex-1 justify-between">
              <div class="mb-6">
                <div>
                  <FormInput name="role" placeholder-label>
                    Your role
                  </FormInput>
                </div>
                <div class="mt-4">
                  <FormInput name="name" placeholder-label>
                    Company name
                  </FormInput>
                </div>
                <div class="mt-2">
                  <FormInput name="position" placeholder-label>
                    Position
                  </FormInput>
                </div>
                <div class="mt-6">
                  <div class="text-base text-[#292C2E] font-bold">
                    Questions
                  </div>
                  <p class="mt-4 text-sm text-[#292C2E]">
                    1. What did you eat for breakfast today? Did you really like
                    it?
                  </p>
                  <div class="mt-1">
                    <FormInput name="position" placeholder-label>
                      Position
                    </FormInput>
                  </div>
                  <TitleLine class="my-4">Optional</TitleLine>
                  <p class="text-sm text-[#292C2E]">
                    1. What did you eat for breakfast today? Did you really like
                    it?
                  </p>
                  <div class="mt-1">
                    <FormInput name="answer" placeholder-label>
                      Answer here...
                    </FormInput>
                  </div>
                </div>
              </div>
              <Btn @click.prevent="SUBMIT()">Complete registration</Btn>
            </form>
          </TabPanel> -->
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from '@headlessui/vue';
import FormInput from '@/components/FormInput.vue';
import Btn from '@/components/Btn.vue';
import TitleLine from '@/screens/update/components/TitleLine.vue';
import { post } from '@/services/httpClient';
import Login from '@/screens/events/components/Login.vue';
import SignUp from '@/screens/events/components/SignUp.vue';
import Verification from '@/screens/events/components/Verification.vue';
import useAuth from '@/compositions/useAuth';
import useEvents from '@/compositions/useEvents';

const { state } = useAuth();

const { COMPLETE_REGISTRATION, event } = useEvents();

const tabs = ['Investor', 'Startup', 'Other'];
const selectedTab = ref('Investor');

const emit = defineEmits(['close']);

const company = ref('');
const position = ref('');

const showLogin = ref(true);
const showVerification = ref(false);

const toggleAuthComponent = () => {
  showLogin.value = !showLogin.value;
};

const toggleVerificationComponent = () => {
  showVerification.value = true;
};

const SUBMIT = async () => {
  await COMPLETE_REGISTRATION({
    main_type: selectedTab.value.toLowerCase(),
    event_id: event.value.id,
    company: company.value,
    position: position.value,
  });
  emit('close');
};

// const tickets = [
//   {
//     id: 1,
//     title: 'Subscribe to GGW platform',
//     price: 'From $49/Month',
//     description: 'Free access to all GGW events based on subscription plans',
//     isRecommended: true,
//   },
//   {
//     id: 2,
//     title: 'General admission',
//     price: '$100',
//     description: 'This is a one-time non-refundable payment',
//   },
// ];

// const selectedTickets = ref(tickets[0]);
</script>
