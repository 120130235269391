import { useRouter } from 'vue-router';

export default function useEditableBlock(blockIndex) {
  const router = useRouter();

  const onEdit = async () => {
    await router.push({ name: 'EditBlockScreen', params: { id: blockIndex } });
  };

  return {
    onEdit,
  };
}
