import { ref } from 'vue';
import { post } from '@/services/httpClient';
import usePayments from '@/compositions/usePayments';
import useUser from '@/compositions/useUser';

const { manage } = usePayments();
const { user } = useUser();

let isLoaded = false;
const currentPlan = ref();

const makeRequest = async () => {
  try {
    const [plan, manageLink] = await Promise.all([
      post('payment/', { command: 'current' }),
      async () => (await manage()) || null,
    ]);

    currentPlan.value = { type: user.tariff, ...plan, manage_link: manageLink };
  } catch (e) {
    currentPlan.value = {
      tariff_name: 'Free',
      tariff_period: '',
      tariff_price: '0',
      payment_price: '0',
      payment_start: '',
      actions: '0',
    };
  }
};

export default function useCurrentPlan() {
  if (!isLoaded) {
    isLoaded = true;
    makeRequest();
  }

  return { currentPlan };
}
