<template>
  <button @click="openEditModal">
    <img src="/src/assets/images/girl-icon.svg" alt="">
  </button>

  <Modal ref="editModal" is-new-style is-btn-close>
    <div class="text-2xl text-center font-bold">
      Contact us
    </div>
    <p class="text-[#292C2E] text-base mt-2 text-center">
      If you have some issue during registration or some question to ask, feel free to contact us!
    </p>
    <Btn as="a" href="mailto:info@goglobal.world" class="mt-6">
      Email us
    </Btn>
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import Modal from './Modal.vue';
import Btn from './Btn.vue';

const editModal = ref(null);

function openEditModal() {
  editModal.value.openModal();
}

function closeEditModal() {
  editModal.value.closeModal();
}
</script>
