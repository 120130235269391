<template>
  <div>
    <button class="mt-1 bg-white w-full flex justify-between md:justify-center gap-2.5 items-center px-4 py-3 text-base text-[#008AFC] font-bold" @click="openAddModal">
      Add section <img src="/src/assets/images/plus-blue-icon.svg" alt="">
    </button>
    <Modal ref="addModal" is-new-style is-btn-close>
      <div class="py-4 md:py-0">
        <div class="text-lg md:text-2xl text-[#292C2E] font-bold">
          Add section
        </div>

        <ul class="mt-4 divide-y divide-[#DFE4E8]">
          <li v-for="(section, index) in addSections" :key="index">
            <button class="py-3 w-full flex justify-between items-center text-base text-[#292C2E]">
              {{ section.title }}
              <img class="w-6" src="/src/assets/images/plus-icon.svg" alt="">
            </button>
          </li>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Modal from '../../../components/Modal.vue';

const addModal = ref(null);

const addSections = [
  {
    title: 'Elevator Pitch',
  },
  {
    title: 'Fundraising',
  },
  {
    title: 'Sales metrics',
  },
  {
    title: 'Users / customers metrics',
  },
  {
    title: 'Verticals',
  },
  {
    title: 'Focus Geography',
  },
  {
    title: 'Business Model',
  },
  {
    title: 'Team & Office',
  },
];

function openAddModal() {
  addModal.value.openModal();
}
</script>
