<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Elevator Pitch
      </h1>
      <Completed has-text />
    </div>
    <div class="mt-4">
      <FormInput name="" placeholder-label>
        Elevator Pitch YouTube link
      </FormInput>
    </div>

    <p class="mt-1 pl-2 text-xs text-[#292C2E]">
      Recommended video 60 seconds or less. To make it best,
      broke it down into short segments:
    </p>
    <ul class="list-disc pl-7 text-xs text-[#292C2E]">
      <li v-for="(item, index) in lists" :key="index">
        {{ item }}
      </li>
    </ul>
    <TitleLine>Or / And</TitleLine>

    <FormTextarea placeholder-label name="">
      Elevator Pitch Text
    </FormTextarea>

    <p class="mt-1 pl-2 mb-4 text-xs text-[#292C2E]">
      What you do? Why are you unique? Do you have traction?
      Keep it as a story up to 500 symbols
    </p>
    <div class="flex gap-2 mt-auto">
      <Btn theme="white">
        Delete block
      </Btn>
      <Btn>Save & Next</Btn>
    </div>
  </div>
</template>

<script setup>
import FormInput from '../../../components/FormInput.vue';
import TitleLine from '../../update/components/TitleLine.vue';
import FormTextarea from '../../../components/FormTextarea.vue';
import Btn from '../../../components/Btn.vue';
import Completed from '../../update/components/Completed.vue';

const lists = [
  'introduction', 'problem and solutions', 'competitors', 'market opportunity', 'business model', 'achievements so far', 'investment request',
];
</script>
