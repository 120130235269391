import { ref, onUnmounted } from 'vue';

export const DAY = 1000 * 60 * 60 * 24;
export const HOUR = 1000 * 60 * 60;
export const MINUTE = 1000 * 60;
export const MILLISECONDS = 1000;
export const INTERVAL_DURATION = 1000;

export function padZerosInToNumber(number, maxLength = 2) {
  return String(number).padStart(maxLength, '0');
}

export default function useCountdown(date) {
  const timerTime = date.getTime();

  const days = ref('');
  const hours = ref('');
  const minutes = ref('');
  const seconds = ref('');
  const isExpired = ref(false);

  let interval;

  function tick() {
    const now = Date.now();
    const distance = timerTime - now;

    if (distance <= MILLISECONDS) {
      clearInterval(interval);
      isExpired.value = true;
      return;
    }

    days.value = padZerosInToNumber(Math.floor(distance / DAY));
    hours.value = padZerosInToNumber(Math.floor((distance % DAY) / HOUR));
    minutes.value = padZerosInToNumber(Math.floor((distance % HOUR) / MINUTE));

    seconds.value = padZerosInToNumber(
      Math.floor((distance % MINUTE) / MILLISECONDS),
    );
  }

  interval = setInterval(tick, INTERVAL_DURATION);

  tick();

  onUnmounted(() => clearInterval(interval));

  return {
    days,
    hours,
    minutes,
    seconds,
    isExpired,
  };
}
