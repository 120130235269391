<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Team & Office
      </h1>
      <Completed has-text />
    </div>
    <div class="mt-4">
      <FormInput name="" placeholder-label>
        Number of employees
      </FormInput>
    </div>
    <div class="mt-2">
      <FormInput name="" placeholder-label>
        Location
      </FormInput>
    </div>
    <div class="mt-1 flex gap-5 pl-1">
      <Radio v-for="item in locationType" :key="item.id" :item="item" />
    </div>

    <TitleLine>Optional</TitleLine>

    <FormTextarea placeholder-label name="">
      Any other important notes about your team
    </FormTextarea>
    <p class="mt-1 pl-2 text-xs text-[#292C2E]">
      Up to 500 symbols
    </p>

    <BtnAdd class="my-6">
      Another location
    </BtnAdd>

    <Btn class="mt-auto">
      Save
    </Btn>
  </div>
</template>

<script setup>
import BtnAdd from '../../update/components/BtnAdd.vue';
import FormInput from '../../../components/FormInput.vue';
import TitleLine from '../../update/components/TitleLine.vue';
import FormTextarea from '../../../components/FormTextarea.vue';
import Btn from '../../../components/Btn.vue';
import Radio from '../../update/components/Radio.vue';
import Completed from '../../update/components/Completed.vue';

const locationType = [
  { id: 'hq', title: 'This is HQ' },
  { id: 'incorporated', title: 'This is Incorporated' },
];
</script>
