<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" @click="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="flex min-h-full justify-start text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 -translate-x-4 sm:-translate-x-0 sm:scale-95"
            enter-to="opacity-100 translate-x-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-x-0 sm:scale-100"
            leave-to="opacity-0 -translate-x-4 sm:-translate-x-0 sm:scale-95"
          >
            <DialogPanel
              class="relative flex flex-col transform w-5/6 overflow-hidden rounded-r-[10px] bg-white p-4 text-left transition-all"
              style="box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.3)"
            >
              <div class="flex items-center gap-4">
                <router-link
                  :to="{
                    name: USER_ROLE_TO_ROUTE_NAME[user.role],
                    params: { id: user.id },
                  }"
                  class="block w-[70px] h-[70px] rounded-full bg-[#C4C4C4] overflow-hidden text-center"
                  @click="closeModal"
                >
                  <img
                    v-if="user.avatar"
                    class="inline-block h-full"
                    :src="user.avatar"
                    alt="user avatar"
                  />
                </router-link>
                <div class="flex flex-col">
                  <router-link
                    :to="{
                      name: USER_ROLE_TO_ROUTE_NAME[user.role],
                      params: { id: user.id },
                    }"
                    class="block text-lg font-bold"
                    @click="closeModal"
                  >
                    {{ user.firstname }} {{ user.lastname }}
                  </router-link>

                  <router-link
                    :to="{
                      name: USER_ROLE_TO_ROUTE_NAME[user.role],
                      params: { id: user.id },
                    }"
                    class="text-black/50 text-sm"
                    @click="closeModal"
                  >
                    View profile
                  </router-link>
                </div>
              </div>

              <div class="mt-4 flex flex-col">
                <router-link
                  class="py-3 border-b border-[#DFE4E8]"
                  :to="{ name: 'Match' }"
                  @click="closeModal"
                >
                  Matching
                </router-link>

                <router-link
                  class="py-3 border-b border-[#DFE4E8]"
                  :to="{
                    name: 'UpdatingPlanScreen',
                  }"
                  @click="closeModal"
                >
                  Pricing
                </router-link>

                <a
                  v-if="user.role == USER_ROLE_STARTUP"
                  href="https://www.old.goglobal.world/perks"
                  class="py-3 border-b border-[#DFE4E8]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Perks
                </a>

                <a
                  v-if="user.role == USER_ROLE_STARTUP"
                  class="py-3 border-b border-[#DFE4E8]"
                  href="https://www.old.goglobal.world/services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Services
                </a>

                <router-link
                  class="py-3 border-b border-[#DFE4E8]"
                  :to="{ name: 'Events' }"
                  @click="closeModal"
                >
                  Events
                </router-link>

                <a
                  class="py-3 border-b border-[#DFE4E8]"
                  href="https://www.old.goglobal.world/blog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>

                <router-link
                  class="py-3 border-b border-[#DFE4E8]"
                  :to="{ name: 'SettingsScreen' }"
                  @click="closeModal"
                >
                  Settings
                </router-link>
              </div>
              <div class="flex justify-between items-center py-3 mt-auto">
                <a
                  href="mailto:info@goglobal.world"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>

                <img
                  src="@/assets/images/logo.png"
                  width="43"
                  height="28"
                  alt=""
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import useUser from '../compositions/useUser';

import { USER_ROLE_TO_ROUTE_NAME, USER_ROLE_STARTUP } from '../constants';

const { user } = useUser();
const isOpen = ref(false);

function openModal() {
  isOpen.value = true;
}

function closeModal() {
  isOpen.value = false;
}

defineExpose({
  openModal,
  closeModal,
});
</script>
