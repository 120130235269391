<template>
  <div class="relative w-full group">
    <input
      v-if="mask"
      ref="el"
      :type="typeValue"
      :class="[
        { '!border-red-600': error, 'has-unit': Boolean(unit) },
        placeholderLabel
          ? 'pt-6 border-transparent bg-[#F3F5F8]'
          : 'pt-2 border-[#676C71] bg-transparent',
      ]"
      class="input"
      v-bind="$attrs"
      :placeholder="placeholder"
    />
    <input
      v-else
      :value="modelValue"
      :type="typeValue"
      :class="{ '!border-red-600': error, 'has-unit': Boolean(unit) }"
      class="input"
      v-bind="$attrs"
      @input="changeValue($event.target.value)"
    />

    <label
      v-if="placeholderLabel"
      class="flex justify-between absolute top-4 left-3 right-3 pointer-events-none transition-all text-[#676C71]"
    >
      <slot />

      <span
        v-if="maxLength && modelValue?.length"
        :class="{ 'text-red-600': maxLength < modelValue?.length }"
      >
        {{ modelValue.length }}/{{ maxLength }}
      </span>
    </label>
    <span
      v-if="labelSecond"
      class="absolute leading-[56px] pr-3 pl-1 top-px right-px rounded bg-[#F3F5F8] text-base text-[#676C71]"
    >
      {{ labelSecond }}
    </span>

    <template v-if="type === 'password'">
      <component
        :is="typeValue === 'password' ? EyeIcon : EyeSlashIcon"
        class="w-4 h-4 absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
        @click="onChangeType"
      />
    </template>

    <span
      v-if="unit"
      class="absolute right-3 top-1/2 -translate-y-1/2 text-[#676C71]"
    >
      {{ unit }}
    </span>
  </div>

  <div v-if="error" class="text-sm text-red-600 mb-2 text-center">
    {{ error }}
  </div>
</template>

<script setup>
import { watch, ref } from 'vue';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline';
import useIMask, { OPTIONS_LATINIC_INPUT } from '../compositions/useIMask';

const props = defineProps({
  modelValue: { type: [String, Number], default: '', required: false },
  type: { type: String, default: 'text', required: false },
  error: { type: String, default: null, required: false },
  mask: { type: Object, default: () => OPTIONS_LATINIC_INPUT },
  placeholderLabel: { type: Boolean, default: false },
  labelSecond: { type: String, default: '' },
  placeholder: { type: String, default: ' ', required: false },
  unit: { type: String, default: '' },
  maxLength: { type: Number, default: 0 },
});

const { el, unmasked } = useIMask({
  ...props.mask,
  defaultValue: props.modelValue,
});

const emit = defineEmits({
  'update:modelValue': null,
});

const changeValue = (value) => {
  emit('update:modelValue', value);
};

const typeValue = ref(props.type);

const onChangeType = () => {
  if (typeValue.value === 'password') {
    typeValue.value = 'text';
  } else {
    typeValue.value = 'password';
  }
};

watch(unmasked, (value) => {
  emit('update:modelValue', value);
});

watch(
  () => props.modelValue,
  () => {
    if (props.mask && props.modelValue) {
      unmasked.value = props.modelValue.toString();
    }
  },
);
</script>

<style scoped>
input[type='radio'],
input[type='checkbox'] {
  @apply w-auto;
}

.input {
  @apply w-full text-base pb-2 px-3;
  @apply rounded border text-[#292C2E] outline-none transition;
}

.input.filled,
.input:focus,
.input:not(:placeholder-shown) {
  @apply border-[#676C71] bg-transparent text-[#292C2E];
}

.input:focus + label,
.input:not(:placeholder-shown) + label {
  @apply text-sm top-2 leading-4;
}

.input:focus + label + span,
.input:not(:placeholder-shown) + label + span {
  @apply !bg-white;
}

.input.has-unit {
  @apply pr-6;
}

.input[disabled] {
  @apply cursor-not-allowed bg-[#F3F5F8] border-0 text-[#9DA1A6];
}

.input[disabled] + label {
  @apply text-[#BDBDBD];
}
</style>
