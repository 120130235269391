import useUser from '@/compositions/useUser';
import { computed, reactive, watch } from 'vue';
import useMatchModal from '@/compositions/useMatchModal.js';
import { TYPE_MVP_M, TYPE_MVP_Y } from '@/compositions/usePayments';
import { ERROR_HAS_NOT_TARIFF, ERROR_NEED_UPGRATE_TARIFF } from '@/constants';
import useMatching, {
  COMMAND_DISLIKE,
  COMMAND_LIKE,
  COMMAND_THINK,
} from './useMatching';

const state = reactive(new Map());

const { like, dislike, think, status } = useMatching();
const { open } = useMatchModal();
const { user } = useUser();

const checkReaction = async (userId) => {
  if (state.has(userId)) {
    return;
  }

  state.set(userId, {});
  const response = await status({ id: userId });
  state.set(userId, response);
};

export default function useReaction(userId) {
  const reaction = computed(() => {
    const { response = null } = state.get(userId) || {};

    return response;
  });

  const canMakeReaction = computed(() => {
    if (!userId) {
      return false;
    }

    if (userId === user.user_id) {
      return false;
    }

    return reaction.value === null || reaction.value === COMMAND_THINK;
  });

  const matched = computed(() => {
    // eslint-disable-next-line no-shadow
    const { matched = null } = state.get(userId) || {};

    return matched;
  });

  const makeReaction = async (type, onlyReaction = false) => {
    let result;

    switch (type) {
      case COMMAND_LIKE:
        result = await like({ id: userId }, onlyReaction);
        break;
      case COMMAND_DISLIKE:
        result = await dislike({ id: userId }, onlyReaction);
        break;
      case COMMAND_THINK:
        result = await think({ id: userId }, onlyReaction);
        break;
      default:
    }

    state.set(userId, result);

    if (result?.matched) {
      open(result.matched);
    }

    return result;
  };

  watch(canMakeReaction, () => checkReaction(userId));

  checkReaction(userId);

  return {
    matched,
    reaction,
    canMakeReaction,
    makeReaction,
  };
}
