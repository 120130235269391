import axios, { AxiosError } from 'axios';
import { API_HOST } from '../constants';
import useCredentials from "./../compositions/useCredentials";

const axiosInstance = axios.create({ baseURL: API_HOST });

axiosInstance.interceptors.request.use((config) => {
  const { getEmail, getAuthToken } = useCredentials();

  const authToken = getAuthToken();
  const email = getEmail();

  if (config.data instanceof FormData) {
    config.data.append('email', email && email.toLowerCase());
    config.data.append('auth_token', authToken);

    return config;
  }

  if (authToken) {
    const data =
      typeof config.data === 'string'
        ? JSON.parse(config.data)
        : { ...config.data };

    return {
      ...config,
      data: {
        ...data,
        auth_token: authToken,
        email: email && email.toLowerCase(),
      },
    };
  }

  return config;
});

export const post = async (path, params = {}, rawError = false) => {
  try {
    const { data } = await axiosInstance.post(path, params);

    return data;
  } catch (error) {
    if (rawError) {
      throw error;
    }

    if (error instanceof AxiosError) {
      if (error.response) {
        throw error.response.data;
      }

      if (error.request) {
        throw error.request;
      }
    }

    return { error: error.message };
  }
};

export const get = () => {};
