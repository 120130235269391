<template>
  <div
    class="flex h-full flex-col"
    :class="{ '!bg-[#EEF2F5]': !transparentBackground }"
    :style="hasFooter ? `min-height: ${height}px` : ''"
  >
    <AppHeaderDesktop v-if="hasHeader" class="hidden lg:block" />
    <div
      :class="hasHeader ? 'lg:mt-[64px]' : ''"
      class="mx-auto flex w-full flex-1 flex-grow flex-col overflow-hidden md:overflow-visible"
    >
      <slot />
    </div>
    <AppFooter
      v-if="hasFooter"
      class="mt-[120px] hidden lg:block"
      :style="mtFooter ? `margin-top: ${mtFooter}px` : ''"
    />
  </div>
</template>

<script setup>
import AppHeaderDesktop from '@/components/AppHeaderDesktop.vue';
import AppFooter from '@/components/AppFooter.vue';
import { onMounted, onUnmounted, ref } from 'vue';

defineProps({
  hasHeader: { type: Boolean, default: false },
  hasFooter: { type: Boolean, default: false },
  mtFooter: { type: Boolean, default: false },
  transparentBackground: { type: Boolean, default: false },
});

const height = ref(window.innerHeight);

onMounted(() => {
  window.addEventListener('resize', () => {
    height.value = window.innerHeight;
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    height.value = window.innerHeight;
  });
});
</script>
