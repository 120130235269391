<template>
  <button class="py-2 px-3 border border-[#DFE4E8] rounded font-bold text-[#292C2E] text-sm relative" @click="onClick">
    <span v-if="isCopied" class="py-1 px-2 text-sm leading-[18px] copied absolute -top-9 left-1/2 -translate-x-1/2 bg-black text-white rounded-3xl font-normal">Copied!</span>
    <slot />
    <span class="hidden">{{ value }}</span>
  </button>
</template>

<script setup>
import useClipboard from '../compositions/useClipboard';

const { isCopied, copy } = useClipboard();

const props = defineProps({
  value: { type: String, default: '' },
});

const emit = defineEmits({
  copy: null,
});

const onClick = () => {
  copy(props.value).then(() => {
    emit('copy', props.value);
  });
};
</script>

<style scoped>
.copied {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
}

.copied:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  position: absolute;
  bottom: -6px;
  left: 50%;
  margin-left: -3px;
}
</style>
