<template>
  <div>
    <div class="flex gap-1 items-center">
      <div
        class="text-base text-[#292C2E] font-bold group-hover:underline transition"
      >
        {{ name }}
      </div>
      <img
        v-if="verified"
        class="w-4"
        src="/src/assets/images/profile-desktop/check-verification.svg"
        alt=""
      />
      <div
        class="text-[#292C2E] text-[10px] leading-[14px] font-bold py-0.6 px-1.5 border border-[#DFE4E8] bg-[#F3F5F8] rounded uppercase"
      >
        {{ type }}
      </div>
    </div>
    <div class="text-sm text-[#676C71]">{{ headliner }}</div>
    <div class="text-sm font-bold text-[#149911]">{{ status }}</div>
    <div class="mt-1 text-sm text-[#676C71] flex gap-1.5 items-center">
      <img src="/src/assets/images/profile-desktop/map-pin-icon.svg" alt="" />
      <span>{{ country }}</span>
    </div>
  </div>
</template>

<script setup>
defineProps({
  name: {
    type: String,
    default: 'Name',
  },
  type: {
    type: String,
    default: 'investor',
  },
  headliner: {
    type: String,
    default: 'Headliner text',
  },
  status: {
    type: String,
    default: 'Actively investing',
  },
  country: {
    type: String,
    default: 'Country',
  },
  verified: {
    type: Boolean,
    default: false,
  },
});
</script>
