<template>
  <ul class="mt-4 grid grid-cols-3 sm:gap-x-6 gap-y-1 sm:gap-y-2">
    <li v-for="(item, index) in lists" :key="index" class="relative after:w-px after:h-5 after:bg-[#DFE4E8] after:absolute after:right-0 after:top-2.5 nth-3n pr-4 mr-4 lg:pr-0 lg:mr-0 lg:after:hidden">
      <span class="block text-sm sm:text-base text-[#676C71]">{{ item.title }}</span>
      <span class="block text-sm sm:text-base text-[#292C2E] font-bold">{{ item.value }}</span>
    </li>

    <li class="self-center">
      <a
        href="#sales"
        class="text-[#008AFC] text-sm font-bold"
        @click.prevent="onClick"
      >Show more</a>
    </li>
  </ul>
</template>

<script setup>
import { inject } from 'vue';

const scrollToHash = inject('scrollToHash');

const lists = [
  {
    title: 'ARR',
    value: '$60K',
  },
  {
    title: 'MRR',
    value: '$5K',
  },
  {
    title: 'LTV',
    value: '$3K - $5K',
  },
  {
    title: 'Paying Users',
    value: '81',
  },
  {
    title: 'Retention',
    value: '79%',
  },
];

const onClick = () => {
  scrollToHash('#sales');
};
</script>

<style scoped>
.nth-3n:nth-child(3n):after {
  display: none;
}

.nth-3n:nth-child(3n) {
  margin-right: 0;
  padding-right: 0;
}
</style>
