<template>
  <MainLayout>
    <MainContent>
      <AppHeader is-btn-menu is-btn-back>
        <h1 class="text-xl text-white text-center">
          Legal
        </h1>
      </AppHeader>
      <div class="pt-[72px] mt-5 pb-12">
        <ul class="divide-y divide-gray-200 border-b border-gray-200">
          <li
            v-for="(link, index) in documentsLinks"
            :key="index"
            class="py-5 px-7"
          >
            <a
              :href="link.href"
              target="_blank"
              class="text-lg text-[#008AFC]"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </MainContent>
  </MainLayout>
</template>
<script setup>
import AppHeader from '../../components/AppHeader.vue';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';

const documentsLinks = [
  {
    title: 'Go Global World’s Terms of Service ',
    href: 'https://www.goglobal.world/terms-of-service',
  },
  {
    title: 'Privacy Policy',
    href: 'https://www.goglobal.world/privacy-policy',
  },
  {
    title: 'GDPR',
    href: 'https://www.goglobal.world/gdpr',
  },
  {
    title: 'CCPA policy - Don’t sell my information',
    href: 'https://www.goglobal.world/do-not-sell',
  },
  {
    title: 'Sanctions Compliance Policy',
    href: 'javascript:void(0);',
  },
];
</script>
