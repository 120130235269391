import {
  breakpointsTailwind,
  useBreakpoints as initUseBreakpoints,
} from '@vueuse/core';

const breakpoints = initUseBreakpoints(breakpointsTailwind);

const isDesktop = breakpoints.greater('lg');
const isMobile = breakpoints.smaller('lg');

export default function useBreakpoints() {
  return {
    isDesktop,
    isMobile,
  };
}
