<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Company information
      </h1>
      <Completed has-text />
    </div>
    <div class="mt-4 flex items-center gap-3">
      <EmptyDiv class="w-12 h-12">
        <img src="/src/assets/images/profile/camera-icon.svg" alt="">
      </EmptyDiv>
      <div class="text-base text-[#676C71]">
        Add Logo
      </div>
    </div>
    <div class="mt-2">
      <FormInput name="" placeholder-label>
        Startup name
      </FormInput>
    </div>
    <div class="mt-2">
      <FormInput name="" placeholder-label>
        Country of HQ
      </FormInput>
    </div>
    <div class="mt-4 text-sm text-[#676C71]">
      Investment status
    </div>

    <RadioGroup v-model="selected" class="mt-1 space-y-1">
      <RadioGroupOption
        v-for="status in statuses"
        :key="status.id"
        v-slot="{ active }"
        :value="status.id"
        as="div"
      >
        <div
          :class="{
            'bg-[#008AFC] text-white border-[#008AFC]': active,
            'border-[#DFE4E8] text-[#292C2E]': !active,
          }"
          class="inline-block py-1.5 px-2 border rounded text-sm cursor-pointer"
        >
          {{ status.title }}
        </div>
      </RadioGroupOption>
    </RadioGroup>

    <div class="mt-4">
      <FormInput name="" placeholder-label>
        Link to PDF pitch deck
      </FormInput>
      <p class="mt-1 pl-2 text-xs text-[#292C2E]">
        Only investors will have access to your pitch deck
      </p>
    </div>

    <div class="mt-4">
      <FormTextarea name="" placeholder-label>
        One liner text
      </FormTextarea>
      <p class="mt-1 pl-2 text-xs text-[#292C2E]">
        Up to 500 symbols
      </p>
    </div>

    <TitleLine>Optional</TitleLine>

    <FormInput name="" placeholder-label>
      Linkedin
    </FormInput>

    <div class="mt-2">
      <FormInput name="" placeholder-label>
        Website
      </FormInput>
    </div>

    <Btn class="mt-4">
      Save & Next
    </Btn>
  </div>
</template>

<script setup>
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { ref } from 'vue';
import FormInput from '../../../components/FormInput.vue';
import FormTextarea from '../../../components/FormTextarea.vue';
import TitleLine from '../../update/components/TitleLine.vue';
import Btn from '../../../components/Btn.vue';
import EmptyDiv from '../../update/components/EmptyDiv.vue';
import Completed from '../../update/components/Completed.vue';

const statuses = [
  {
    title: 'Actively Raising',
    id: '1',
  },
  {
    title: 'Open to investment',
    id: '2',
  },
  {
    title: 'Not looking for investments',
    id: '3',
  },
];

const selected = ref(statuses[0]);
</script>
