<template>
  <Card class="space-y-2 !py-3 !px-0 lg:!px-4 !rounded-none lg:rounded">
    <div class="text-base text-[#292C2E] font-bold">
      {{ data.label }}
    </div>
    <div>
      <template v-if="data.type === 'radio'">
        <div v-for="option in options" :key="option.id">
          <FormRadio
              v-model="value[0]"
              :value="option.id"
              :name="id"
              :label="option.label"
          />
        </div>
      </template>
      <FormTreeSelect
          v-else
          multiple
          v-model="value"
          @update:model-value="emit('change', value)"
          :options="options"
          :name="id"
          :default-expand-level="1"
      />
    </div>
  </Card>
</template>

<script setup>
import Card from '@/screens/profile-go-global-world-static/components/Card.vue';
import FormTreeSelect from '@/components/FormTreeSelect.vue';
import { ref, watch } from 'vue';
import FormRadio from "@/components/FormRadio.vue";
import useMembersFilters from "@/compositions/useMembersFilters.js";

const props = defineProps({
  id: { type: String },
  data: { type: Object },
});

const emit = defineEmits({
  'change': null,
});

const { getSortedKeys } = useMembersFilters();

const options = ref([]);
const value = ref([]);

watch(
  props.data,
  () => {
    value.value = [];
    options.value = parseCheckboxes(props.data.checkboxes);
  },
  {
    immediate: true,
  },
);

watch(
  value,
  () => {
    emit('change', value.value);
  },
  {
    immediate: true,
  },
);

function parseCheckboxes(checkboxes) {
  const result = [];

  for (const id of getSortedKeys(checkboxes)) {
    const checkbox = checkboxes[id];
    const option = { id, label: checkbox.label };

    if (checkbox.value) {
      value.value.push(id);
    }

    if (checkbox.children) {
      option.children = parseCheckboxes(checkbox.children);
    }

    result.push(option);
  }

  return result;
}

function isAllPicked(options) {
  let result = true;

  for (const option of options) {
    result = result && value.value.includes(option.id);
  }

  return result;
}
</script>

<style>
.modal-filter-child .vue-treeselect__menu-container {
  @apply mt-5;
}
.modal-filter-child .vue-treeselect--open .vue-treeselect__control {
  @apply border-none bg-[#F3F5F8] rounded;
}
</style>
