import { ref, computed } from 'vue';
import { post } from '@/services/httpClient';
import DateEntity from '@/entities/DateEntity';

const isLoading = ref(false);
const events = ref([]);
const event = ref(null);

const formatEventDate = (date) =>
  DateEntity.format(date, DateEntity.FORMAT_MMMM_DD);
const formatEventTime = (time) =>
  DateEntity.format(time, DateEntity.FORMAT_HH_MM_AMPM);

const startDateFormatted = computed(() =>
  event.value ? formatEventDate(event.value.started_at) : '',
);
const startTimeFormatted = computed(() =>
  event.value ? formatEventTime(event.value.started_at) : '',
);
const endTimeFormatted = computed(() =>
  event.value ? formatEventTime(event.value.ended_at) : '',
);

const useEvents = () => {
  const GET_EVENT = async (id) => {
    isLoading.value = true;
    try {
      const { response } = await post('events/', {
        command: 'info',
        id,
      });
      event.value = response;
    } finally {
      isLoading.value = false;
    }
  };

  const GET_EVENTS = async () => {
    isLoading.value = true;
    try {
      const { response } = await post('events/', { command: 'list' });
      events.value = response;
    } finally {
      isLoading.value = false;
    }
  };

  const COMPLETE_REGISTRATION = async ({ main_type, event_id, company, position }) => {
    await post('events/', { command: 'participate', main_type, event_id, company, position });

    GET_EVENT(event.value.id);
  };

  return {
    isLoading: computed(() => isLoading.value),
    events: computed(() => events.value),
    event: computed(() => event.value),
    startDateFormatted,
    startTimeFormatted,
    endTimeFormatted,
    formatEventDate,
    formatEventTime,
    GET_EVENT,
    GET_EVENTS,
    COMPLETE_REGISTRATION
  };
};

export default useEvents;
