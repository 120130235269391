<template>
  <Card>
    <h3 class="mb-3 sm:mb-4 text-[#292C2E] text-base sm:text-xl font-bold">
      Users
    </h3>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 items-center">
      <div>
        <ul class="space-y-1">
          <li v-for="(item, index) in users" :key="index" class="rounded text-sm leading-4 sm:leading-5 relative bg-[#F3F5F8]">
            <div class="absolute rounded z-0 h-full" :class="item.bgColor + ' ' + item.width" />
            <div class="relative z-10 flex py-2.5 sm:py-1.5 px-2">
              <span class="text-[#292C2E]">{{ item.title }}</span>
              <span class="ml-auto mr-1 text-[#292C2E] font-bold">{{ item.text1 }}</span>
              <span class="text-[#676C71]">{{ item.text2 }}</span>
            </div>
          </li>
        </ul>
      </div>
      <List :lists="listUsers" />
    </div>
  </Card>
</template>
<script setup>
import Card from './Card.vue';
import List from './List.vue';

const users = [
  {
    title: 'Paying Users',
    text1: '81',
    text2: '100%',
    bgColor: 'bg-[#D9E0F1]',
    width: 'w-full',
  },
  {
    title: 'Retention',
    text2: '79%',
    bgColor: 'bg-[#F1D9ED]',
    width: 'w-[79%]',
  },
  {
    title: 'Churn Rate',
    text2: '21%',
    bgColor: 'bg-[#F9E1C6]',
    width: 'w-[21%]',
  },
];

const listUsers = [
  {
    title: 'All Users',
    value: '1710',
  },
  {
    title: 'Active Users',
    value: '469',
  },
  {
    title: 'Growth Rate',
    value: '41%',
  },
];
</script>
