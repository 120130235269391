import useUtmCollector from "@/compositions/useUtmCollector";

export { default as authMiddleware } from './authMiddleware';
export { default as connectionsMiddleware } from './connectionsMiddleware';
export { default as adminModeMiddleware } from './adminModeMiddleware';
export { default as logOutMiddleware } from './logOutMiddleware';
export { default as userRoleMiddleware } from './userRoleMiddleware';
export { default as matchMiddleware } from './matchMiddleware';
export { default as profileStatusMiddleware } from './profileStatusMiddleware';
export { default as referralMiddleware } from './referralMiddleware';
export { default as profileMiddleware } from './profileMiddleware';
export { default as prefetchDashboardMiddleware } from './prefetchDashboardMiddleware';
export { default as investorProfileMiddleware } from './investorProfileMiddleware';
export { default as loadDialogs } from './loadDialogs';

class Middleware {
  constructor(middlewares = []) {
    this.middlewares = middlewares;
  }

  use(callback) {
    this.middlewares.push(callback);
  }

  execute(to, from, callback) {
    let index = 0;

    const next = (result) => {
      if (result === false || result instanceof Object) {
        callback(result);
        return;
      }

      if (index < this.middlewares.length) {
        const middleware = this.middlewares[index];
        index += 1;
        middleware(to, from, next);
      } else {
        callback(result);
      }
    };

    next();
  }
}
export function beforeEach(to, from, next) {
  const { collect } = useUtmCollector();
  const { middlewares } = to.meta;

  collect();

  if (!middlewares || middlewares.length === 0) {
    next();
    return;
  }

  new Middleware(middlewares).execute(to, from, next);
}

export function beforeResolve(to, from, next) {
  const { prefetchMiddlewares } = to.meta;

  if (!prefetchMiddlewares || prefetchMiddlewares.length === 0) {
    next();
    return;
  }

  new Middleware(prefetchMiddlewares).execute(to, from, next);
}
