<template>
  <div>
    <div
      id="g_id_onload"
      :data-client_id="GOOGLE_CLIENT_ID"
      data-context="signin"
      data-ux_mode="popup"
      data-callback="onSignIn"
      data-auto_prompt="false"
    />

    <slot v-if="$slots.default" :click="click" />

    <div
      v-show="!$slots.default"
      ref="node"
      class="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      :data-text="buttonText"
      data-size="large"
      data-logo_alignment="left"
      data-locale="en_US"
    />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { GOOGLE_CLIENT_ID } from '@/constants';
import useGoogleSignIn from '../compositions/useGoogleSignIn';

const { promise, load, unload } = useGoogleSignIn();
const node = ref(null);

const click = () => {
  node.value.querySelector('[role="button"]').click();
};

defineProps({
  buttonText: {
    type: String,
    default: 'signin_with',
    validator: () => ['continue_with', 'signin_with', 'signup_with'],
  },
});

onMounted(async () => {
  await load();
  await promise;

  window.location.reload();
});

onUnmounted(() => {
  unload();
});
</script>

<style scoped></style>
