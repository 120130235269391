import { reactive } from 'vue';
import { post } from '../services/httpClient';

export const COMMAND_DASHBOARD = 'dashboard';

export const COMMAND_STATISTICS = 'statistics';

export const REACTIONS_OTHER = 'other';

export const PERFORMANCE = 'performance';

const state = reactive({
  [REACTIONS_OTHER]: null,
  [PERFORMANCE]: {
    views: 0,
    pass: 0,
    requests: 0,
    inReview: 0,
    matched: 0
  },
});

export const initStatistic = async () => {
  state[REACTIONS_OTHER] = await post('user/', { command: COMMAND_DASHBOARD });
};

const initPerformance = async () => {
  const response = await post('user/', { command: COMMAND_STATISTICS });

  state[PERFORMANCE].views = response?.stats?.all?.views || 0;
  state[PERFORMANCE].pass = response?.stats?.all?.pass || 0;
  state[PERFORMANCE].requests = response?.stats?.all?.connect || 0;
  state[PERFORMANCE].inReview = response?.stats?.all?.forlater || 0;
  state[PERFORMANCE].matched = response?.stats?.all?.matched || 0;
}

export default function useDashboard() {
  return {
    stats: state,
    getAll: async () => Promise.all([
      initStatistic(),
      initPerformance(),
    ]),
  };
}
