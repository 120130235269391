import useAuth from '@/compositions/useAuth';
import useUser from '@/compositions/useUser';
import { USER_ROLE_ADVISOR } from '@/constants';

const { isAuthenticated } = useAuth();
const { user } = useUser();

export default function authMiddleware(isAuthPage = false) {
  return async function auth(to, form, next) {
    const isLoggedIn = await isAuthenticated();

    if (isAuthPage) {
      if (isLoggedIn) {
        next({ name: 'ProfileDashboard' });
      } else {
        next();
      }

      return;
    }

    if (isLoggedIn) {
      next();
    } else {
      next({ name: 'WelcomeScreen' });
    }
  };
}
