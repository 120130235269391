<template>
  <MainLayout has-header has-footer>
    <MainContent class="!bg-[#EEF2F5] lg:!pb-0" is-big-width>
      <AppHeader
        class="lg:hidden"
        is-btn-menu
        is-bg-white
        is-big-width
        has-logo
      />

      <div class="mt-14 space-y-1 lg:space-y-0 lg:flex items-start gap-4">
        <UserBlock class="lg:max-w-[376px] flex-none w-full" />
        <BaseBlock class="py-3 px-4 lg:py-4 lg:px-6 flex-1">
          <h2 class="text-base font-bold text-[#292C2E] lg:text-xl">
            Profile creation
          </h2>
          <p class="mt-1 lg:mt-2 text-sm lg:text-base text-[#292C2E]">
            Please choose your role so we can give you the best experience and
            related functionality.
          </p>

          <div class="mt-3 lg:mt-4 divide-y divide-[#DFE4E8]">
            <button
              v-for="(profile, index) in profiles"
              :key="index"
              type="button"
              class="py-3 lg:py-4 pl-0.5 pr-2 flex gap-2.5 items-center w-full"
              @click="onClick(profile.role)"
            >
              <img class="w-5" :src="profile.icon" alt="icon" />
              <span class="text-base">{{ profile.title }}</span>

              <img
                class="ml-auto"
                src="/src/assets/images/chevron-right-black.svg"
                alt=""
              />
            </button>
          </div>
        </BaseBlock>
        <BaseBlock v-if="false" class="lg:hidden">
          <button type="button" class="font-bold h-full w-full text-center">
            Log out
          </button>
        </BaseBlock>
      </div>
      <MenuBottom is-big-width class="lg:hidden" />
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { useRouter } from 'vue-router';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants';
import MenuBottom from '@/components/MenuBottom.vue';
import UserBlock from '@/components/blocks/UserBlock.vue';
import BaseBlock from '@/components/blocks/BaseBlock.vue';
import AppHeaderDesktop from '@/components/AppHeaderDesktop.vue';
import useRequest from '../../compositions/useRequest';
import useUser from '../../compositions/useUser';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import AppHeader from '../../components/AppHeader.vue';

const profiles = [
  {
    icon: new URL(
      '../../assets/images/profile/investor-icon.svg',
      import.meta.url,
    ).pathname,
    title: 'Investor profile',
    role: USER_ROLE_INVESTOR,
  },
  {
    icon: new URL(
      '../../assets/images/profile/startup-icon.svg',
      import.meta.url,
    ).pathname,
    title: 'Startup profile',
    role: USER_ROLE_STARTUP,
  },
  {
    icon: new URL(
      '../../assets/images/profile/advisor-icon.svg',
      import.meta.url,
    ).pathname,
    title: 'Advisor / business provider / accelerator profile',
    role: USER_ROLE_ADVISOR,
  },
];

const router = useRouter();
const { update } = useUser();
const { request } = useRequest(update);

const onClick = async (role) => {
  await request({
    main_type: role,
  });

  await router.push({ name: 'ProfileDashboard' });
};
</script>
