<template>
  <MainLayout>
    <MainContent class="!bg-[#EEF2F5]" is-big-width>
      <div class="mt-14 overflow-y-auto h-full">
        <Card class="flex items-center gap-4 !py-4">
          <EmptyDiv class="w-[72px] h-[72px]">
            <img src="/src/assets/images/profile/camera-icon.svg" alt="" />
          </EmptyDiv>
          <div>
            <div>Alexander Suhov / Startup profile</div>
            <div class="text-[#676C71] text-sm flex items-center gap-2">
              CEO
              <span
                class="w-0.5 h-0.5 mx-1 bg-[#676C71] rounded-full inline-block"
              />
              China
            </div>
          </div>
        </Card>
        <div class="mt-2 flex gap-4">
          <TabGroup>
            <div class="max-w-[376px] w-full flex-none space-y-2">
              <Card class="!py-4">
                <TabList>
                  <Tab
                    v-for="(tab, index) in tabs"
                    :key="index"
                    v-slot="{ selected }"
                    class="w-full outline-none"
                  >
                    <button
                      class="w-full text-left p-3 pr-4 flex gap-4 items-center text-base outline-none rounded"
                      :class="{ 'bg-[#F3F5F8]': selected }"
                    >
                      <span class="inline-flex w-4/6 text-[#292C2E] font-bold">
                        {{ tab.title }}
                      </span>
                      <span class="text-[#676C71]">0/6</span>
                      <img
                        class="ml-auto"
                        src="/src/assets/images/chevron-right-black.svg"
                      />
                    </button>
                  </Tab>
                </TabList>
              </Card>
              <AddSectionModal />
              <button
                class="flex w-full bg-white py-3 px-4 text-base font-bold text-[#FC0000] justify-center items-center"
              >
                Delete profile
              </button>
            </div>
            <div class="flex-1">
              <Card>
                <TabPanels>
                  <TabPanel>
                    <HeroEdit />
                  </TabPanel>
                  <TabPanel>
                    <ElevatorPitchEdit />
                  </TabPanel>
                  <TabPanel>
                    <FoundersEdit />
                  </TabPanel>
                  <TabPanel>
                    <UsersEdit />
                  </TabPanel>
                  <TabPanel>
                    <SalesEdit />
                  </TabPanel>
                  <TabPanel>
                    <FundraisingEdit />
                  </TabPanel>
                  <TabPanel>
                    <BusinessModelEdit />
                  </TabPanel>
                  <TabPanel>
                    <VerticalsEdit />
                  </TabPanel>
                  <TabPanel>
                    <FocusGeographyEdit />
                  </TabPanel>
                  <TabPanel>
                    <TeamOfficeEdit />
                  </TabPanel>
                </TabPanels>
              </Card>
            </div>
          </TabGroup>
        </div>
      </div>
    </MainContent>
  </MainLayout>
</template>
<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import Card from '../profile-go-global-world-static/components/Card.vue';
import EmptyDiv from './components/EmptyDiv.vue';
import HeroEdit from '../profile-desktop/components/HeroEdit.vue';
import ElevatorPitchEdit from '../profile-desktop/components/ElevatorPitchEdit.vue';
import FoundersEdit from '../profile-desktop/components/FoundersEdit.vue';
import UsersEdit from '../profile-desktop/components/UsersEdit.vue';
import SalesEdit from '../profile-desktop/components/SalesEdit.vue';
import FundraisingEdit from '../profile-desktop/components/FundraisingEdit.vue';
import BusinessModelEdit from '../profile-desktop/components/BusinessModelEdit.vue';
import VerticalsEdit from '../profile-desktop/components/VerticalsEdit.vue';
import FocusGeographyEdit from '../profile-desktop/components/FocusGeographyEdit.vue';
import TeamOfficeEdit from '../profile-desktop/components/TeamOfficeEdit.vue';
import AddSectionModal from './components/AddSectionModal.vue';

const tabs = [
  {
    title: 'Hero',
  },
  {
    title: 'Elevator Pitch',
  },
  {
    title: 'Founders',
  },
  {
    title: 'Users',
  },
  {
    title: 'Sales',
  },
  {
    title: 'Fundraising',
  },
  {
    title: 'Business Model',
  },
  {
    title: 'Verticals',
  },
  {
    title: 'Focus Geography',
  },
  {
    title: 'Team & Office',
  },
];
</script>
