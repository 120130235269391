import { ref } from 'vue';

const modal = ref(null);
const dialogId = ref(null);

function open(dialog) {
    dialogId.value = dialog;

    modal.value?.openModal();
}

function close() {
    dialogId.value = null;

    modal.value?.closeModal();
}

export default function () {
    return {
        modal,
        dialogId,
        open,
        close
    };
}
