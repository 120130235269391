import { createApp } from 'vue';
import { init } from '@/compositions/useSentry';
import './styles/style.css';

import App from './App.vue';
import router from './router';

const app = createApp(App).use(router);

init(app, router);

app.mount('#app');
