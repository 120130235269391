<template>
  <FormAutocomplete
    v-model:selected="selected"
    v-model:query="query"
    :list="list"
    :name="name"
    :multiple="multiple"
    :placeholder="placeholder"
    :placeholder-label="placeholderLabel"
  >
    <slot />
  </FormAutocomplete>
</template>
<script setup>
import { computed } from 'vue';
import useCountriesSearch from '../compositions/useCountriesSearch';

import FormAutocomplete from './FormAutocomplete.vue';

const { query, list, countriesMap } = useCountriesSearch();

const props = defineProps({
  modelValue: { type: [Array, Object, String], default: () => [] },
  name: { type: String, default: 'country' },
  multiple: FormAutocomplete.props.multiple,
  placeholder: FormAutocomplete.props.placeholder,
  placeholderLabel: FormAutocomplete.props.placeholderLabel,
});

const emit = defineEmits({
  'update:model-value': null,
});

const selected = computed({
  get() {
    const val = Array.isArray(props.modelValue)
      ? props.modelValue
      : [props.modelValue];

    const result = val
      .map((id) => countriesMap.value[id])
      .filter(Boolean);

    return props.multiple ? result : result[0] || {};
  },
  set(val) {
    emit('update:model-value', props.multiple ? val.map((item) => item.id) : val.id);
  },
});
</script>
