<template>
  <ModalFilter is-btn-close>
    <template #imgButtonOpen>
      <div class="relative">
        <img src="/src/assets/images/adjustments-horizontal-icon.svg" alt="">
        <div
            v-if="countFiltersOn"
            class="absolute text-white w-[18px] h-[18px] text-xs top-[-6px] right-[-25px] bg-[#008AFC] rounded-full"
            style="font-size: 11px;text-align: center;line-height: 17px;"
        >+{{ countFiltersOn }}</div>
      </div>
    </template>

    <template #title>
      {{ title }}
    </template>

    <template #bodyModal="modal">
      <div class="pb-14 divide-y divide-[#DFE4E8] px-4 relative">
        <LockBlock v-if="!fullVersion" link="/updating-plan" style="z-index: 100;">
          <template #link>
            Upgrade plan
          </template>
          <template #text>
            <div class="text-center">
              to use filters
            </div>
          </template>
        </LockBlock>
        <template v-for="sectionName of Object.keys(sections)" :key="sectionName">
          <ItemFilterMobile
              :id="sectionName"
              :data="sections[sectionName]"
              @change="onChange(sectionName, $event)"
          />
        </template>

        <div class="fixed z-20 w-full border-t border-[#DFE4E8] bg-white bottom-0 inset-x-0 px-4 py-2" style="box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.12);">
          <Btn class="w-full" size="xs" @click="done(modal)">
            Apply filters
          </Btn>
        </div>
      </div>
    </template>
  </ModalFilter>
</template>

<script setup>
import ItemFilterMobile from '@/screens/connections/components/ItemFilterMobile.vue';
import ModalFilter from '@/screens/connections/components/ModalFilter.vue';
import Btn from '@/components/Btn.vue';
import LockBlock from '@/screens/dashboard-desktop/components/LockBlock.vue';

const props = defineProps({
  title: { type: String, default: 'Filters' },
  sections: { type: Object, default: () => ({}) },
  countFiltersOn: { type: Number },
  fullVersion: { type: Boolean },
});

const freeSelectStorage = {};

function onChange(sectionName, checkedIds) {
  freeSelectStorage[sectionName] = checkedIds;
}

function done(modal) {
  for (const sectionName of Object.keys(props.sections)) {
    updateCheckboxes(props.sections[sectionName].checkboxes, freeSelectStorage[sectionName]);
  }

  modal.close();
}

function updateCheckboxes(checkboxes, checkedIds, forcedValue = false) {
  for (const id of Object.keys(checkboxes)) {
    const checkbox = checkboxes[id];

    checkbox.value = checkedIds.includes(id) || forcedValue;

    if (checkbox.children) {
      updateCheckboxes(checkbox.children, checkedIds, checkbox.value);
    }
  }
}
</script>
