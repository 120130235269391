<template>
  <router-view :key="$route.path" v-slot="{ Component }">
    <KeepAlive>
      <component :is="Component" />
    </KeepAlive>
  </router-view>

  <Modal ref="modal" is-desktop disable-closing max-width="378px">
    <div class="pt-1 pb-2">
      <div class="text-xl font-bold text-[#292C2E]">
        Action Unavailable
      </div>
      <div class="mt-2 text-base text-[#676C71]">
        <p>
          Please note that you can <b>only access if</b>:
        </p>
        <p class="pl-2 pt-2">
          {{ user.is_accepted === '2' ? '✅' : '⛔' }} Your profile is approved.
        </p>
        <p v-if="startupCustomModal" class="pt-0" style="padding-left: 2.25rem;">Please complete it.</p>
        <p v-if="isStartup" class="pl-2" :class="{ hidden: startupCustomModal }">
          {{ startupTariffFits ? '✅' : '⛔' }} You have purchased a subscription
        </p>
        <p v-else class="pl-2">
          ⛔️All your required fields are completed in your profile.
        </p>
      </div>
      <p class="mt-6 text-base text-[#676C71]">
        Any questions or issues? We will help. Please email us at
        <a href="#" class="font-bold text-[#292C2E]">info@goglobal.world</a>
      </p>
      <div class="mt-6 flex gap-2">
        <CopyButton value="info@goglobal.world" class="max-w-max w-full">
          Copy our email
        </CopyButton>
        <Btn
            as="a"
            size="xs"
            :href="isStartup && user.is_accepted && !startupTariffFits ? '/updating-plan' : '/block/on-boarding'"
            rel="noopener noreferrer"
        >
          Continue
        </Btn>
      </div>
    </div>
  </Modal>
  <MatchModal />
</template>

<script setup>
import { provideScrollIntoView } from '@/compositions/useScrollIntoView';
import Modal from '@/components/Modal.vue';
import MatchModal from '@/screens/match/components/MatchModal.vue';
import Btn from '@/components/Btn.vue';
import CopyButton from '@/components/CopyButton.vue';
import useIncompleteProfileModal from '@/compositions/useIncompleteProfileModal.js';
import useUser from "@/compositions/useUser.js";
import { USER_ROLE_STARTUP, USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y } from "@/constants.js";
import { computed } from "vue";
import { useRoute } from 'vue-router';

const route = useRoute();
const { modal, open } = useIncompleteProfileModal();
const { user, hasTariff } = useUser();

const isStartup = computed(() => user.role === USER_ROLE_STARTUP);
const startupCustomModal = computed(() => route.name === 'Connections' && [USER_TARIFF_MVP_Y, USER_TARIFF_MVP_M].includes(user.tariff));
const startupTariffFits = computed( () => user.tariff && ![USER_TARIFF_MVP_Y, USER_TARIFF_MVP_M].includes(user.tariff));

provideScrollIntoView();
</script>
