<template>
  <Card>
    <TabGroup>
      <TabList class="flex gap-4">
        <h2 class="text-[#292C2E] text-base sm:text-xl font-bold">
          Elevator Pitch ####3
        </h2>
        <Tab
          v-for="(tab, index) in tabs"
          :key="index"
          v-slot="{ selected }"
          class="outline-none"
        >
          <button
            :class="
              selected
                ? 'text-[#292C2E] border-[#292C2E]'
                : 'text-[#AAAAAA] border-transparent'
            "
            class="text-base sm:text-xl outline-none font-bold pb-0.5 sm:pb-2 border-b-2"
          >
            {{ tab }}
          </button>
        </Tab>
      </TabList>
      <TabPanels class="mt-4">
        <TabPanel>
          <div class="h-[184px] sm:h-[369px]">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/sIOE9chl7Ss"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </TabPanel>
        <TabPanel>
          <p class="text-sm sm:text-base">
            At GGW, we save time of investors to source high value deal flow,
            access to LPs and co-investors avoiding irrelevant connections. For
            Founders faster access to global capital. Unlike static, complex &
            outdated databases, our SaaS platform offers a perfect AI
            matchmaking with real verified people and companies. It comes with
            Advanced Filters, Analytics, Messenger, CRM and a global VC &
            Startup community. Achieving $53k Revenue in 2022, 80+ paying
            customers and thousands of members, we seek $500k SAFE for growth &
            RnD. Let’s change the tech world together!
          </p>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </Card>
</template>
<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import Card from './Card.vue';

const tabs = ['Video', 'Text'];
</script>
