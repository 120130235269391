<template>
  <div
    class="fixed right-0 left-0 top-0 z-50 w-full bg-white px-4 py-3 border-b border-[#DFE4E8] shadow-sm"
  >
    <div class="mx-auto flex max-w-[1120px] items-center">
      <router-link to="/" class="flex items-center gap-0.5">
        <img
          src="/src/assets/images/logo.png"
          alt="logo"
          width="43"
          height="28"
        />
        <span class="text-xl font-bold leading-6 -tracking-[0.8px]"
          >Go Global World</span
        >
      </router-link>
      <nav class="mx-auto flex items-center gap-4 text-sm">
        <template v-for="(item, index) in hrefs" :key="index">
          <component
            :is="item.href ? 'a' : 'router-link'"
            :to="item.route"
            :href="item.href"
            class="relative cursor-pointer text-base p-3 leading-4 hover:bg-[#F3F5F8] rounded duration-200"
            :class="false ? 'text-[#292C2E]' : 'text-[#676C71] font-bold'"
            :target="item.target"
          >
            {{ item.label }}
            <span
              v-if="hasNotification && item.label === 'Chat'"
              class="absolute top-0 right-0 rounded-2xl bg-[#FF002A] py-0.5 px-1.5 text-xs font-medium leading-none text-white"
              >{{ state.unread }}</span
            >
          </component>
        </template>
      </nav>

      <router-link
        v-if="profileRoute"
        :to="profileRoute"
        class="profile-link flex items-center gap-3 text-sm text-[#292C2E] font-bold"
      >
        <div
          class="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-[#F3F5F8]"
        >
          <img :src="avatar" alt="logo" />
        </div>
        {{ user?.firstname }}
      </router-link>
      <div v-else-if="!user?.id" class="flex">
        <button
          class="flex-1 px-2 py-3 sm:p-3 bg-white text-[#292C2E] font-bold rounded-l rounded-r mr-2"
          @click="routeToSignIn"
        >
          Log In
        </button>
        <button
          class="flex-1 px-2 py-3 sm:p-3 bg-[#008AFC] text-white font-bold rounded-r rounded-l whitespace-nowrap"
          @click="routeToSignUp"
        >
          Sign Up
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import useUser from '@/compositions/useUser';
import useMessages from '@/compositions/useMessages.js';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants.js';

const router = useRouter();
const { user } = useUser();
const { state } = useMessages();

const hasNotification = computed(() => Number(state.unread) !== 0);

const profileRoute = computed(() => {
  if (user.main_type === USER_ROLE_ADVISOR) {
    return { name: 'ProfileAdvisor', params: { id: user.user_id } };
  }
  if (user.main_type === USER_ROLE_INVESTOR) {
    return { name: 'ProfileInvest', params: { id: user.user_id } };
  }
  if (user.main_type === USER_ROLE_STARTUP) {
    return { name: 'ProfileStartup', params: { id: user.user_id } };
  }

  return null;
});

const hrefs = computed(() => {
  if (user.id) {
    return [
      {
        label: 'Home',
        route: { name: 'ProfileDashboard' },
      },
      {
        label: 'Matching',
        route: { name: 'Match' },
      },
      {
        label: 'Community',
        route: { name: 'Connections' },
      },
      {
        label: 'Chat',
        route: { name: 'MessagesScreen' },
      },
      {
        label: 'Events',
        route: { name: 'Events' },
      },
      {
        label: 'Pricing',
        route: { name: 'UpdatingPlanScreen' },
      },
      {
        label: 'Settings',
        route: { name: 'SettingsScreen' },
      },
    ];
  }

  return [];
});

const avatar = computed(
  () =>
    user.avatar ||
    new URL('../assets/images/user-icon.svg', import.meta.url).pathname,
);

const routeToSignUp = async () => {
  await router.push({ path: '/sign-up' });
};

const routeToSignIn = async () => {
  await router.push({ path: '/login' });
};
</script>

<style scoped>
.router-link-active:not(.profile-link) {
  @apply font-bold text-[#292C2E] bg-[#F3F5F8] rounded;
}
</style>
