<template>
  <MainLayout>
    <MainContent>
      <AppHeader is-btn-back class="z-20" />
      <div class="pt-12 mb-6">
        <div class="pt-12 px-7 pb-14 relative">
          <h1 class="text-white text-center font-bold text-4xl relative z-10">
            Warning!
          </h1>
          <img
            class="absolute inset-0 w-full h-full z-0"
            src="/src/assets/images/bg-ellipse.png"
            alt=""
          />
        </div>
        <div class="px-7">
          <p class="mt-16 text-xl font-bold text-center">
            You are moving to an external resource where they may collect your
            email or personal information.
          </p>
          <template v-if="query.security">
            <p class="text-xl font-bold text-center">
              Please be careful and do not enter personal or even payment information
              in case where you’re not sure it’s secure.
            </p>

            <p class="text-xl font-bold text-center">
              Do not hesitate to
              <a
                class="text-[#008AFC]"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@goglobal.world"
              >
                contact us
              </a>
              for help.
            </p>
          </template>
          <template v-else>
            <p class="text-xl font-bold text-center">
              If you want to hide it you can use our email
              <a
                class="text-[#008AFC]"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@goglobal.world"
              >
                info@goglobal.world
              </a>
              as default in there.
            </p>
          </template>

          <Btn class="mt-20 flex justify-center" @click="onClick">
            I agree, continue
          </Btn>
        </div>
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';

import Btn from '../../components/Btn.vue';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import AppHeader from '../../components/AppHeader.vue';

const router = useRouter();
const route = useRoute();
const { externalLink } = window.history.state;
const { query } = route;

if (!externalLink) {
  router.push({ path: '/' });
}

const onClick = () => {
  window.open(externalLink, '_blank');
  router.back();
};
</script>
