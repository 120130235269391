<template>
  <div>
    <button
      type="button"
      class="flex items-center gap-1"
      @click="openModal"
    >
      <slot name="imgButtonOpen" />
    </button>
    <Dialog :open="isOpen" class="relative z-50" @close="closeModal">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full bg-white flex-col flex-1">
          <DialogPanel class="pt-14 flex flex-col flex-1">
            <AppHeader class="inset-x-0 top-0" is-bg-white is-big-width>
              <div class="flex items-center justify-center">
                <button v-if="isBtnBack" class="absolute top-4 left-4" @click="closeModal">
                  <ChevronLeftIcon class="w-6 text-[#292C2E]" />
                </button>
                <div class="flex gap-1 items-center justify-center text-[#292C2E] text-base font-bold">
                  <img class="mx-auto h-6" src="/src/assets/images/logo-small.png" alt="logo Go Global World">
                  <slot name="title" />
                </div>
                <button v-if="isBtnClose" class="absolute top-5 right-5" @click="closeModal">
                  <img src="/src/assets/images/x-mark-icon-black.svg" alt="">
                </button>
              </div>
            </AppHeader>

            <slot name="bodyModal" :close="closeModal" />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import { ChevronLeftIcon } from '@heroicons/vue/20/solid';
import AppHeader from '@/components/AppHeader.vue';

defineProps({
  isBtnBack: {
    type: Boolean,
    default: false,
  },
  isBtnClose: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}
function openModal() {
  isOpen.value = true;
}
</script>
