import { ref, reactive, computed } from 'vue';
import useSentry from '@/compositions/useSentry';
import { USER_ROLE_ADVISOR } from '@/constants';
import { useCookies } from '@vueuse/integrations/useCookies';
import { post } from '@/services/httpClient';
import useHotjar from '@/compositions/useHotjar';

const cookies = useCookies();
const { setUser } = useSentry();
const { identify } = useHotjar();

const COMMAND_GET_PROFILE = 'getprofile';
const COMMAND_GET_REFERRAL = 'refferal';

const user = reactive({
  id: null,
  firstname: null,
  lastname: null,
  avatar: null,
  countryId: null,
  linkedIn: null,
  shortBio: null,
  email: null,
  role: null,
  authToken: null,
  position: null,
  tariff: null,
  viewed_demo: null,
  projects: [],
});

const personalInformationIsFilled = computed(() => {
  const requiredFields = ['first_name', 'last_name', 'position', 'country_id'];

  return requiredFields.every((field) => Boolean(user[field]));
});

const updateUser = (response) => {
  if (user.id === null && response.user_id) {
    setUser({ id: response.user_id });
  }

  identify(response);

  const countryId = response.country_id === '0' ? null : response.country_id;

  Object.assign(user, {
    id: response.user_id,
    firstname: response.first_name,
    lastname: response.last_name,
    avatar: response.avatar,
    countryId,
    linkedIn: response.linkedin,
    shortBio: response.short_bio,
    email: cookies.get('email'),
    role: response.main_type,
    position: response.position,
    tariff: response.tariff,
    ...response,
    country_id: countryId,
    viewed_demo: response.viewed_demo,
  });
};

const profile = ref(null);

const fetchUser = () =>
  post('user/', { command: COMMAND_GET_PROFILE }).then((response) => {
    profile.value = Array.isArray(response) ? response[0] : response;
  });

const profileById = (userId) =>
  post('user/', {
    command: COMMAND_GET_PROFILE,
    userid: userId,
  }).then((response) => {
    if (Array.isArray(response) && response.length) {
      return response[0];
    }

    return response;
  });

const getUserInfo = async () => {
  const response = await post('user/');

  updateUser(response);

  if (user.role && user.role === USER_ROLE_ADVISOR) {
    await fetchUser();
  }
};

const update = async (params = {}) => {
  const response = await post('user/', { update: params });

  updateUser(response);
};

const unMatch = async (params = {}) => {
  await post('user/', { command: 'unmatch', ...params });
  await fetchUser();
};

const setViewedDemo = async () => {
  await post('user/', {
    command: 'viewed_demo',
  });
};

const hasTariff = computed(() => Boolean(user.tariff || user.tariff_text));

const getReferral = () => post('user/', { command: COMMAND_GET_REFERRAL });

const isOwnAccount = (id) => user.user_id === id;

export default function useUser() {
  const isAccepted = computed(() => Boolean(Number(user.is_accepted || 0)));
  const isVerified = computed(() => Boolean(Number(user.is_verified || 0)));

  return {
    user,
    profile,
    getUserInfo,
    fetchUser,
    profileById,
    update,
    unMatch,
    getReferral,
    isOwnAccount,

    personalInformationIsFilled,
    isAccepted,
    isVerified,
    hasTariff,
    setViewedDemo,
  };
}
