<template>
  <!--  статичная страница для показа инвесторам по ссылке /profile/goglobal-->

  <div ref="element" class="bg-[#F3F5F8] overflow-y-auto h-full">
    <div class="max-w-[1112px] mx-auto lg:flex gap-2">
      <div class="w-full space-y-1 pb-1">
        <Hero />
        <HeroRight class="lg:hidden" />
        <ElevatorPitch />
        <Founders class="lg:hidden" />
        <Users />
        <Sales />
        <Fundraising />
        <Card v-for="(field, index) in fields" :key="index">
          <h3 class="mb-3 sm:mb-4 text-[#292C2E] text-base sm:text-xl font-bold">
            {{ field.label }}
          </h3>
          <Specification :list="field.items" is-scroll-x />
        </Card>
      </div>

      <div class="w-full lg:w-[376px] flex-none space-y-1">
        <HeroRight class="hidden lg:block" />
        <Founders class="hidden lg:block" />
        <TeamOffice />
        <HeroRight class="lg:hidden" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { provide } from 'vue';
import useScrollTo from '../../compositions/useScrollTo';

import Hero from './components/Hero.vue';
import Card from './components/Card.vue';
import Specification from './components/Specification.vue';
import Fundraising from './components/Fundraising.vue';
import Sales from './components/Sales.vue';
import Users from './components/Users.vue';
import ElevatorPitch from './components/ElevatorPitch.vue';
import HeroRight from './components/HeroRight.vue';
import Founders from './components/Founders.vue';
import TeamOffice from './components/TeamOffice.vue';

const { scrollToHash, element } = useScrollTo();

const fields = [
  { label: 'Business Model', items: ['B2B'] },
  { label: 'Verticals', items: ['B2B SaaS', 'AI', 'CommunityTech', 'Social'] },
  { label: 'Focus Geography', items: ['United States of America'] },
];

provide('scrollToHash', scrollToHash);

</script>
