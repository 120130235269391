<template>
  <Card id="sales">
    <h3 class="mb-3 sm:mb-4 text-[#292C2E] text-base sm:text-xl font-bold">
      Sales
    </h3>
    <TextShowMore text="The GGW platform provides various subscription plans that grant access to a verified network and facilitate matchmaking among Founders, Investors, and Advisors for services such as Analytics, CRM, deals, co-investors, LPs, and other related features. Moreover, the platform generates extra revenue through licensed services such as US Incorporation, Tax Advising, Accounting, and so on." length="159" />
    <div class="mt-3 sm:mt-4 mb-1 sm:mb-2 text-sm sm:text-base text-[#676C71]">
      Sales Geography
    </div>
    <TagList :max="3" :list="countries" is-scroll-x />

    <div class="mt-3 sm:mt-4 grid items-start grid-cols-1 sm:grid-cols-2 sm:gap-6 divide-y divide-[#DFE4E8] sm:divide-y-0">
      <List :lists="lists1" />
      <List class="!border-b border-[#DFE4E8]" :lists="lists2" />
    </div>
  </Card>
</template>
<script setup>
import Card from './Card.vue';
import TagList from './TagList.vue';
import List from './List.vue';
import TextShowMore from './TextShowMore.vue';

const countries = ['United States of America'];

const lists1 = [
  {
    title: 'ARR',
    value: '$60\'000',
  },
  {
    title: 'MRR',
    value: '$5\'000',
  },
  // {
  //   title: 'Sales Growth Rate',
  //   value: 'X% per month for X months',
  // },
  // {
  //   title: 'Average Sale Price',
  //   value: '$X',
  // },
  // {
  //   title: 'GMV/GSV',
  //   value: '$X quarterly',
  // },
  // {
  //   title: 'LTV',
  //   value: '$X',
  // },
  // {
  //   title: 'CAC',
  //   value: '$X',
  // },
  // {
  //   title: 'CAC payback',
  //   value: 'X months',
  // },
];

const lists2 = [
  {
    title: 'LTV',
    value: '$3\'000 - $5\'000',
  },
  // {
  //   title: 'Supply frequency',
  //   value: 'X transactions a month',
  // },
  // {
  //   title: 'Amount of signed contracts',
  //   value: 'X',
  // },
  // {
  //   title: 'Annual value of signed contracts',
  //   value: '$X',
  // },
  // {
  //   title: 'Amount of contracts in pipeline',
  //   value: 'X',
  // },
  // {
  //   title: 'Annual value of contracts in pipeline',
  //   value: '$X',
  // },
  // {
  //   title: 'Letters of Intent Signed',
  //   value: 'X',
  // },
];
</script>
