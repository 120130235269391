<template>
  <Card>
    <h4 class="mb-3 sm:mb-4 text-[#292C2E] text-base sm:text-xl font-bold">
      Founders
    </h4>

    <div class="space-y-4">
      <div v-for="(founder, index) in founders" :key="index">
        <div class="flex items-center gap-3">
          <img class="rounded w-12 h-12" :src="founder.photo" alt="">
          <div>
            <div class="text-[#292C2E] font-bold text-sm">
              {{ founder.name }}
            </div>
            <p class="flex items-center text-sm text-[#676C71]">
              <span>{{ founder.profession }}</span>
              <span v-if="founder.country" class="w-0.5 h-0.5 mx-1 bg-[#676C71] rounded-full" />
              <span>{{ founder.country }}</span>
            </p>
          </div>
          <a :href="founder.linkLinkedin" target="_blank" class="ml-auto">
            <img class="w-4 lg:w-3" src="/src/assets/images/profile-desktop/logo-linkedin.svg" alt="">
          </a>
        </div>
        <ul class="mt-2 list-disc space-y-1 text-sm text-[#292C2E] pl-5">
          <li v-for="(item, index) in founder.lists" :key="index">
            {{ item.text }}
          </li>
        </ul>
        <Btn v-if="index === 0" as="a" href="https://app.goglobal.world/?utm_source=profile-id-1" target="_blank" class="mt-2 flex gap-1 items-center max-w-max">
          <img src="/src/assets/images/profile-desktop/message-icon.svg" alt="">
          Write a message
        </Btn>
      </div>
    </div>
  </Card>
</template>
<script setup>
import Card from './Card.vue';
import Btn from './Btn.vue';

const founders = [
  {
    name: 'Danil Kislinskiy',
    photo: new URL('../../../assets/images/profile-desktop/danil_kislinskiy.jpg', import.meta.url).pathname,
    profession: 'CEO',
    country: 'United States of America',
    linkLinkedin: 'https://www.linkedin.com/in/danilkislinskiy/',
    lists: [
      {
        text: 'MBA in International Business BS/MS in Economics',
      },
      {
        text: '15+ years of experience in Sale, Marketing, Srategy',
      },
      {
        text: 'Global Community Builder, Speaker, and Connector',
      },
      {
        text: 'Founded 3 companies, 1 failed',
      },
      {
        text: '7 years of consulting to tech Startups',
      },
    ],
  },
  {
    name: 'Dmitry Khanukov',
    photo: new URL('../../../assets/images/profile-desktop/dmitry_khanukov.jpg', import.meta.url).pathname,
    profession: 'CTO',
    country: '',
    linkLinkedin: 'https://www.linkedin.com/in/khanukov/',
    lists: [
      {
        text: 'Master of Applied Informatics in AI',
      },
      {
        text: 'Serial entrepreneur',
      },
      {
        text: '2 exits, ex-Uber, ex-PIK-Arenda',
      },
      {
        text: 'Blockchain Advisor',
      },
      {
        text: 'Angel Investor',
      },
    ],
  },
  {
    name: 'Ana Pavlova',
    photo: new URL('../../../assets/images/profile-desktop/ana_pavlova.jpg', import.meta.url).pathname,
    profession: 'COO',
    country: '',
    linkLinkedin: 'https://www.linkedin.com/in/anapavlova/',
    lists: [
      {
        text: 'Master in Economics',
      },
      {
        text: '10+ years in Operations, Finance & Accounting',
      },
      {
        text: 'Global Community Builder',
      },
      {
        text: 'Experienced event manager online and offline',
      },
    ],
  },
];
</script>
