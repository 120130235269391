import useUser from "./useUser.js";
import { USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y } from "../constants.js";
import useMatchEndpoint from "@/compositions/useMatchEndpoint.js";

const fetchConnections = (params) => useMatchEndpoint().send('members', { data: params });

function buildCheckboxesResult(checkboxes) {
    const result = [];

    for (const id of Object.keys(checkboxes)) {
        const checkbox = checkboxes[id];

        if (checkbox.value) {
            result.push(id);
        } else if (checkbox.children) {
            result.push(...buildCheckboxesResult(checkbox.children));
        }
    }

    return result;
}

function buildFilters(filtersState) {
    return {
        // like: filtersState.search,
        hideViewedProfiles: filtersState.hiddenViewedProfiles,
        // status: buildCheckboxesResult(filtersState.sections.status.checkboxes),
        role: buildCheckboxesResult(filtersState.sections.role.checkboxes),
        stage: buildCheckboxesResult(filtersState.sections.stage.checkboxes),
        businessModel: buildCheckboxesResult(filtersState.sections.businessModel.checkboxes),
        focusVertical: buildCheckboxesResult(filtersState.sections.focusVertical.checkboxes),
        minorities: buildCheckboxesResult(filtersState.sections.minorities.checkboxes),
        focusGeography: buildCheckboxesResult(filtersState.sections.focusGeography.checkboxes)
    };
}

async function load(filtersState, offset, limit) {
    const params = {
        ...buildFilters(filtersState),
        offset,
        limit
    };

    // return await Promise.all([
    //         {
    //             id: "491",
    //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
    //             type: 'startup',
    //             name: 'Jenny Wilson',
    //             headliner: 'Co-Founder & Director of Business',
    //             status: 'Open for investments',
    //             geography: 'United Kindom of Great Britain and Northern Irela',
    //             canHelp: 'United Kindom of Great Britain and Northern Irela',
    //             needHelp: 'United Kindom of Great Britain and Northern Irela',
    //             is_verified: null,
    //             viewed: null,
    //             action: null,
    //             dialog_id: '1'
    //         },
    //     ]
    // );

    return (await fetchConnections(params));
}

function fullVersionAvailable() {
    const { user } = useUser();

    if (user.tariff && ![USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y].includes(user.tariff)) {
        return true;
    }

    return false;
}

export default function () {
    return {
        load,
        fullVersionAvailable
    };
}
