<template>
  <Modal ref="modal" is-desktop max-width="378px">
    <div class="pt-1 pb-2">
      <div class="text-xl font-bold text-[#292C2E]">
        Congratulations!
      </div>
      <div class="mt-2 text-base text-[#676C71]">
        <p>You've got a match!🎉</p>
      </div>
      <div class="mt-6 flex gap-2">
        <Btn theme="outline" class="max-w-max w-full" @click="close">Close</Btn>
        <Btn size="xs" @click="routeToDialog">Send message</Btn>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import Modal from '@/components/Modal.vue';
import Btn from '@/components/Btn.vue';
import useMatchModal from '@/compositions/useMatchModal.js';
import { useRouter } from "vue-router";

const router = useRouter();

const { modal, dialogId, close } = useMatchModal();

function routeToDialog() {
  router.push({ name: 'DialogScreen', params: { id: dialogId.value } });
  close();
}
</script>

<style scoped>

</style>
