import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies();

const maxAge = 365 * 24 * 60 * 60;

let email = cookies.get('email');
let authToken = cookies.get('authToken');

function save(newEmail, newAuthToken, rememberMe) {
    cookies.set('email', newEmail || '', {
        maxAge: rememberMe ? maxAge : null,
        path: '/',
    });

    cookies.set('authToken', newAuthToken || '', {
        maxAge: rememberMe ? maxAge : null,
        path: '/',
    });

    refresh();
}

function reset() {
    cookies.remove('authToken', { path: '/' });
    cookies.remove('email', { path: '/' });

    refresh();
}

function refresh() {
    email = cookies.get('email');
    authToken = cookies.get('authToken');
}

export default function () {
    return {
        getEmail: () => email,
        getAuthToken: () => authToken,
        save,
        reset,
        refresh
    };
}
