<template>
  <Input
    v-bind="$attrs"
    v-model="value"
    :name="name"
    :error="meta.touched && errorMessage ? error || errorMessage : ''"
    :type="type"
    :placeholder="placeholder"
    :placeholder-label="placeholderLabel"
    :label-second="labelSecond"
    :unit="unit"
    :disabled="isDisabled"
    :max-length="maxLength"
  >
    <slot />
  </Input>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useField } from 'vee-validate';

import Input from './Input.vue';

const props = defineProps({
  modelValue: { type: [String, Boolean, Number], default: '' },
  name: { type: String, required: true },
  initialValue: { type: String, default: '' },
  type: { type: String, default: 'text' },
  placeholderLabel: Input.props.placeholderLabel,
  labelSecond: Input.props.labelSecond,
  placeholder: Input.props.placeholder,
  error: { type: String, default: '' },
  unit: { type: String, default: '' },
  isDisabled: { type: Boolean, default: false },
  maxLength: { type: Number, default: 0 },
});

const emit = defineEmits({
  'update:model-value': null,
});

const { name } = toRefs(props);

const {
  value: fieldValue,
  errorMessage,
  meta,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    fieldValue.value = val;
    emit('update:model-value', val);
  },
});
</script>
