<template>
  <div class="w-full p-4">
    <div class="flex items-center justify-between max-w-[1120px] mx-auto">
      <div class="text-[#676C71] text-base uppercase flex-1">
        GO GLOBAL WORLD © 2023
      </div>
      <ul class="flex gap-4 items-center">
        <li v-for="(link, index) in links" :key="index">
          <a :href="link.link" target="_blank">
            <img :src="link.img" alt="" />
          </a>
        </li>
      </ul>
      <div class="flex-1 text-right">
        <a
          href="https://www.old.goglobal.world/privacy-policy"
          target="_blank"
          class="text-[#676C71] text-base"
        >
          Legal Notice
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
const links = [
  {
    img: new URL('../assets/images/icons/linkedin-logo.svg', import.meta.url),
    link: 'https://www.linkedin.com/company/go-global-world/',
  },
  {
    img: new URL('../assets/images/icons/twitter-logo.svg', import.meta.url),
    link: 'https://twitter.com/GoGlobalWorld',
  },
  {
    img: new URL('../assets/images/icons/facebook-logo.svg', import.meta.url),
    link: 'https://www.facebook.com/GoGlobalWorld1/',
  },
];
</script>
