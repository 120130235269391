<template>
  <div v-if="!data.hidden" class="flex items-center gap-2 lg:py-1 lg:px-1">
    <div>
      <input :id="id" type="checkbox" class="input hidden" :checked="data.value" @change="handleChange" />
      <div
        class="checkbox-custom"
        :class="{ active: data.value || (data.children && hasCheckedChild) }"
        @click="handleChange"
      >
        <img v-if="data.value" src="/src/assets/images/check-white-icon.svg" alt="">
        <img v-else-if="hasCheckedChild" src="/src/assets/images/minus-white-icon.svg" alt="">
      </div>
      <label class="checkbox-custom-mobile" :for="id">
        {{ data.label }}
        <XMarkIcon class="text-[#676C71] w-4" />
      </label>
    </div>
    <label class="text-sm text-[#676C71] cursor-pointer !hidden lg:!block" :for="id">
      {{ data.label }}
    </label>
    <div v-if="data.children" class="ml-auto cursor-pointer hidden lg:block">
      <ChevronDownIcon class="w-4 text-[#292C2E]" :class="{ 'rotate-180': data.expanded }" @click="data.expanded = !data.expanded;" />
    </div>
  </div>
  <div v-if="data.children && data.expanded" class="ml-3 pl-3 border-l border-[#DFE4E8]">
    <CheckboxFilter v-for="childId of checkboxesIds" :id="id + childId" :data="data.children[childId]" @change="handleChildChange" />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import useMembersFilters from "@/compositions/useMembersFilters.js";

const props = defineProps({
  id: { type: String, default: '' },
  data: { type: Object, default: () => ({}) },
});

const emit = defineEmits({
  change: null,
});

const { getSortedKeys } = useMembersFilters();

const checkboxesIds = props.data.children && getSortedKeys(props.data.children);
const hasCheckedChild = ref(checkedChild(props.data) !== null);

watch(
  props.data,
  () => {
    hasCheckedChild.value = checkedChild(props.data) !== null;
  },
);

function handleChange() {
  props.data.value = !props.data.value;

  setChildren(props.data);

  emit('change');
}

function handleChildChange() {
  let allChildChecked = true;

  for (const key of Object.keys(props.data.children)) {
    allChildChecked = allChildChecked && props.data.children[key].value;
  }

  props.data.value = allChildChecked;

  emit('change');
}

function setChildren(data) {
  if (data.children) {
    for (const id of Object.keys(data.children)) {
      const checkbox = data.children[id];

      checkbox.value = data.value;

      setChildren(checkbox);
    }
  }
}

function checkedChild(checkbox) {
  if (checkbox.children) {
    for (const id of Object.keys(checkbox.children)) {
      const has = checkedChild(checkbox.children[id]);

      if (has) {
        return has;
      }
    }
  }

  return checkbox.value ? checkbox : null;
}
</script>

<style scoped>
.checkbox-custom {
  @apply h-4 w-4 rounded-sm border border-[#DFE4E8] text-[#008AFC] bg-white cursor-pointer !hidden lg:!flex items-center justify-center;
}

.checkbox-custom img {
  @apply hidden w-2.5;
}

.checkbox-custom.active {
  @apply bg-[#008AFC] border-[#008AFC];
}

.checkbox-custom.active img {
  @apply block;
}

.checkbox-custom-mobile {
  @apply flex gap-1 items-center rounded p-2 text-sm text-[#292C2E] border border-[#DFE4E8] lg:hidden;
}

.checkbox-custom-mobile svg {
  @apply hidden;
}

.input:checked + .checkbox-custom + .checkbox-custom-mobile {
  @apply bg-[#DFE4E8];
}

.input:checked + .checkbox-custom + .checkbox-custom-mobile svg {
  @apply block;
}
</style>
