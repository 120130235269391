<template>
  <MainLayout has-footer has-header transparentBackground class="bg-white">
    <MainContent
      is-big-width
      class="lg:pb-0"
      :class="{ 'pb-[77px]': state.isAuthenticated }"
    >
      <AppHeader class="lg:hidden" is-big-width is-btn-menu is-bg-white>
        <div class="flex gap-1 items-center justify-center">
          <img class="h-6" src="/src/assets/images/logo-small.png" alt="" />
          <span class="text-base font-bold text-[#292C2E]">Events</span>
        </div>
      </AppHeader>
      <div
        v-if="!isLoading"
        class="mt-14 pt-4 pb-[88px] lg:pb-0 lg:pt-0 lg:mt-8 px-4"
      >
        <img class="rounded-2xl w-full" :src="event.picture" alt="" />
        <div class="lg:mx-20 mt-4 lg:mt-8 lg:flex gap-10">
          <div class="flex-1">
            <div
              v-if="event.is_partisipate"
              class="mb-4 lg:mb-8 text-[#149911] text-base font-bold flex items-center gap-1.5 justify-center py-2 px-4 rounded border border-[#BEE2B2] bg-[#EDF6EA]"
            >
              <CheckCircleIcon class="w-6" />
              You are registered!
            </div>

            <h1
              class="text-xl leading-7 lg:text-[32px] lg:leading-10 font-bold"
            >
              {{ event.name }}
            </h1>
            <EventDetails :event="event" class="lg:hidden mt-6" />

            <div
              v-if="!event.ended_for && event.is_partisipate"
              class="flex justify-center mt-6 lg:hidden"
            >
              <add-to-calendar-button
                styleLight="--btn-background: rgb(0 138 252 / var(--tw-bg-opacity));; --btn-text: #fff; --font: inherit; --btn-shadow: none; --btn-border: none;"
                hideBackground
                organizer="Go Global World|info@goglobal.world"
                :name="event.name"
                options="'Apple','Google','iCal','Outlook.com','Yahoo'"
                :location="
                  event.is_online === '1' ? event.private_desc : event.location
                "
                :startDate="startedDate"
                :endDate="endedDate"
                :startTime="startedTime"
                :endTime="endedTime"
                :description="event.description"
                timeZone="America/Los_Angeles"
              ></add-to-calendar-button>
            </div>

            <div
              v-if="!event.is_partisipate && !event.ended_for"
              class="fixed inset-x-0 w-full px-4 py-2 flex gap-2 bg-white lg:hidden"
              :class="[
                state.isAuthenticated ? 'bottom-[77px]' : 'bottom-[0px]',
              ]"
              style="
                box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.12);
                z-index: 1;
              "
            >
              <div class="flex gap-3 flex-none w-1/2">
                <div
                  class="w-12 h-12 rounded bg-[#F3F5F8] flex justify-center items-center"
                >
                  <img
                    class="w-6"
                    src="/src/assets/images/icons/ticket-icon.svg"
                    alt=""
                  />
                </div>
                <div>
                  <div class="text-base text-[#292C2E] font-bold">Tickets</div>
                  <div class="text-sm text-[#292C2E]">
                    {{ event?.price_level }}
                  </div>
                </div>
              </div>
              <RegisterButton
                size="xs"
                class="w-1/2"
                :event-striction="event?.price_type"
                @register="register"
                @setTariff="openTariffs"
                @signUp="openSignUp"
              />
            </div>

            <div class="mt-6 flex gap-2 items-center">
              <div v-if="event.attendees.list" class="flex ml-2">
                <!-- нужно вывести на десктопе 8 кругляшей, а на мобке 5-->
                <RoundImages
                  class="!border-white hidden md:block"
                  :img="item"
                  size="xl"
                  v-for="item in event.attendees?.list.slice(0, 8)"
                  :key="item"
                />
                <RoundImages
                  class="!border-white block md:hidden"
                  :img="item"
                  size="xl"
                  v-for="item in event.attendees?.list.slice(0, 5)"
                  :key="item"
                />
              </div>
              <div class="text-sm text-[#292C2E] font-bold">
                {{ event.attendees?.count }} attendees
              </div>
            </div>

            <div class="mt-6 space-y-4">
              <p
                class="text-base text-[#292C2E]"
                v-html="event.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"
              ></p>
            </div>

            <div class="mt-6 lg:mt-10">
              <h2 class="text-xl text-[#292C2E] font-bold">Speakers</h2>

              <ul class="mt-2 divide-y divide-[#DFE4E8]">
                <router-link
                  class="py-2 lg:py-4 flex gap-3"
                  v-for="item in event.speakers?.list"
                  :key="item.id"
                  :to="`/${item.type}/${item.id}`"
                  target="_blank"
                >
                  <RoundImages
                    class="!border-white !w-20 h-20"
                    :img="item.photo"
                  />

                  <div class="w-[calc(100%-5rem)]">
                    <div class="flex gap-1 items-center">
                      <div class="text-base text-[#292C2E] font-bold">
                        {{ item.name }}
                      </div>
                      <img
                        class="w-4"
                        src="/src/assets/images/profile-desktop/check-verification.svg"
                        alt="verified icon"
                      />
                      <div
                        class="text-[#292C2E] text-[10px] leading-[14px] font-bold py-0.6 px-1.5 border border-[#DFE4E8] bg-[#F3F5F8] rounded uppercase"
                      >
                        {{ item.type }}
                      </div>
                    </div>
                    <p class="text-sm text-[#676C71]">{{ item.headliner }}</p>
                    <div class="text-sm text-[#149911] font-bold">
                      {{ item.status }}
                    </div>
                    <div
                      class="mt-1 text-sm text-[#676C71] flex gap-1 items-center"
                    >
                      <img
                        src="/src/assets/images/profile-desktop/map-pin-icon.svg"
                        alt=""
                      />
                      <span>{{ item.geography }}</span>
                    </div>
                  </div>
                </router-link>
              </ul>
            </div>
          </div>
          <div class="lg:w-[330px] flex-none space-y-4 mt-6 lg:mt-0">
            <div class="rounded p-6 border border-[#DFE4E8] hidden lg:block">
              <EventDetails />
              <RegisterButton
                v-if="!event.is_partisipate && !event.ended_for"
                class="mt-6"
                :event-striction="event?.price_type"
                @register="register"
                @setTariff="openTariffs"
                @signUp="openSignUp"
              />
              <!-- <Btn
                v-else-if="!event.ended_for"
                as="a"
                target="_blank"
                :href="formattedGoogleCalendarLink"
                v-else
                class="mt-6 gap-2"
              >
                <img
                  class="w-6"
                  src="/src/assets/images/icons/calendar-white-icon.svg"
                  alt=""
                />
                Add to Calendar
              </Btn> -->
              <div
                v-else-if="!event.ended_for"
                class="flex justify-center mt-6"
              >
                <add-to-calendar-button
                  styleLight="--btn-background: rgb(0 138 252 / var(--tw-bg-opacity));; --btn-text: #fff; --font: inherit; --btn-shadow: none; --btn-border: none;"
                  hideBackground
                  organizer="Go Global World|info@goglobal.world"
                  :name="event.name"
                  options="'Apple','Google','iCal','Outlook.com','Yahoo'"
                  :location="
                    event.is_online === '1'
                      ? event.private_desc
                      : event.location
                  "
                  :startDate="startedDate"
                  :endDate="endedDate"
                  :startTime="startedTime"
                  :endTime="endedTime"
                  :description="event.description"
                  timeZone="America/Los_Angeles"
                ></add-to-calendar-button>
              </div>
            </div>
            <div class="rounded p-6 border border-[#DFE4E8]">
              <div class="text-xl text-[#292C2E] font-bold">Hosts</div>
              <ul class="mt-6 space-y-4">
                <li
                  class="flex gap-2"
                  v-for="(item, key) in event.hosts?.list"
                  :key="key"
                >
                  <img class="h-6" :src="item.picture" alt="" />
                  <span class="text-base font-bold text-[#292C2E]">
                    {{ item.name }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="flex gap-2">
              <CopyButton
                class="border border-[#DFE4E8] rounded p-2 text-sm leading-4 flex-1 flex gap-1 items-center justify-center"
                :value="currentUrl"
              >
                <img src="/src/assets/images/icons/share.svg" alt="" />
                Share
              </CopyButton>
              <a
                v-for="(link, index) in links"
                :key="index"
                :href="link.img"
                target="_blank"
                class="border border-[#DFE4E8] rounded p-2"
              >
                <img :src="link.img" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <MenuBottom v-if="state.isAuthenticated" class="lg:hidden" is-big-width />
      <ModalEvent class="z-[999]" ref="modalEvent" />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import AppHeader from '@/components/AppHeader.vue';
import Btn from '@/components/Btn.vue';
import MenuBottom from '@/components/MenuBottom.vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import RoundImages from '@/components/RoundImages.vue';
import ModalEvent from '@/screens/events/components/ModalEvent.vue';
import EventDetails from '@/screens/events/components/EventDetails.vue';
import RegisterButton from '@/screens/events/components/RegisterButton.vue';
import CopyButton from '@/components/CopyButton.vue';
import useEvents from '@/compositions/useEvents';
import useAuth from '@/compositions/useAuth.js';
import useUser from '@/compositions/useUser.js';
import 'add-to-calendar-button';

const { GET_EVENT, COMPLETE_REGISTRATION, isLoading, event } = useEvents();
const { user } = useUser();
const { state } = useAuth();

const route = useRoute();
const router = useRouter();

GET_EVENT(route.params.id);

const modalEvent = ref(null);
const currentUrl = ref(window.location.href);

const startedDate = computed(() => {
  return event.value ? event.value.started_at.split(' ')[0] : null;
});

const startedTime = computed(() => {
  return event.value ? event.value.started_at.split(' ')[1] : null;
});

const endedDate = computed(() => {
  return event.value ? event.value.ended_at.split(' ')[0] : null;
});

const endedTime = computed(() => {
  return event.value ? event.value.ended_at.split(' ')[1] : null;
});

function register() {
  if (user.main_type) {
    COMPLETE_REGISTRATION({
      main_type: user.main_type,
      event_id: event.value.id,
    });
  } else {
    modalEvent.value.openEventModal();
  }
}

async function openTariffs() {
  await router.push({ name: 'UpdatingPlanScreen' });
}

async function openSignUp() {
  await router.push({ name: 'SignUp', query: { event: event.value.id } });
}
</script>
724 721 716 690 669 660 656
