import {
  ref,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import IMask, { pipe } from 'imask';

export const OPTIONS_AMOUNT_INPUT = {
  mask: Number,
  thousandsSeparator: "'",
};

export const OPTIONS_LATINIC_INPUT = {
  mask: (val) => /^[ ~`!@#$%^&*()_+=[\]\\{}\n|;':",./<>?a-zA-Z0-9-]+$/.test(val),
};

export const format = (value, options = OPTIONS_AMOUNT_INPUT) => pipe(value, options);

export default function useIMask(options = {}) {
  const mask = ref();
  const el = ref(null);

  const masked = ref('');
  const unmasked = ref('');

  const _onAccept = () => {
    unmasked.value = mask.value.unmaskedValue;
    masked.value = mask.value.value;
  };

  const init = () => {
    if (!el.value) return;

    if (!options) return;

    if (options.defaultValue) {
      el.value.value = options.defaultValue;
    }

    mask.value = new IMask(el.value, options).on('accept', _onAccept);

    _onAccept();
  };

  const _destroyMask = () => {
    if (mask.value) {
      mask.value.destroy();
      mask.value = null;
    }
  };

  watch(unmasked, () => {
    if (mask.value) mask.value.unmaskedValue = unmasked.value;
  });

  onMounted(init);
  onUnmounted(_destroyMask);

  return {
    el,
    masked,
    unmasked,
  };
}
