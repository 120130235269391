<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Business Model
      </h1>
      <Completed has-text />
    </div>

    <div class="my-4 flex gap-2 flex-wrap">
      <div v-for="(model, index) in models" :key="index">
        <input :id="`model-${index}`" :name="`model-${index}`" type="checkbox" class="hidden" />
        <label :for="`model-${index}`" class="text-[#292C2E] cursor-pointer inline-flex text-sm py-1.5 px-2 border border-[#DFE4E8] rounded">{{ model }}</label>
      </div>
    </div>

    <Btn class="mt-auto">
      Save & Next
    </Btn>
  </div>
</template>

<script setup>
import Btn from '../../../components/Btn.vue';
import Completed from '../../update/components/Completed.vue';

const models = ['B2B', 'B2B2C', 'B2B2G', 'B2C', 'B2G', 'B2G2C', 'C2B', 'C2C', 'C2G', 'G2B', 'G2C', 'P2P'];
</script>

<style scoped>
input:checked + label {
  @apply bg-[#008AFC] text-white border-[#008AFC];
}
</style>
