import { useScroll } from '@vueuse/core';
import { nextTick, ref } from 'vue';

export default function useScrollTo() {
  const element = ref(null);
  const { y } = useScroll(element, { behavior: 'smooth' });

  const scrollToHash = async (hash) => {
    const el = document.querySelector(hash);
    const { top } = el.getBoundingClientRect();

    if (el) {
      await nextTick();
      y.value = top;
    }
  };

  return { element, scrollToHash };
}
