<template>
  <div class="relative">
    <textarea
      v-if="mask"
      ref="el"
      class="textarea"
      v-bind="$attrs"
      :class="[
        {
          '!border-red-600': meta.touched && errorMessage,
          'has-unit': Boolean(unit),
        },
        placeholderLabel ? 'pt-6' : 'pt-2',
      ]"
      :placeholder="placeholder"
    />
    <textarea
      v-else
      v-model="value"
      class="textarea"
      v-bind="$attrs"
      :class="{
        '!border-red-600': meta.touched && errorMessage,
        'has-unit': Boolean(unit),
      }"
    />
    <label
      v-if="placeholderLabel"
      class="flex justify-between absolute rounded bg-[#F3F5F8] top-px pt-4 left-px right-3 pl-3 pointer-events-none transition-all text-[#676C71]"
    >
      <slot />
      <span
        v-if="maxLength && modelValue?.length"
        :class="{ 'text-red-600': maxLength < modelValue?.length }"
      >
        {{ modelValue.length }}/{{ maxLength }}
      </span>
    </label>

    <span
      v-if="unit"
      class="absolute right-3 top-1/2 -translate-y-1/2 text-[#676C71]"
    >
      {{ unit }}
    </span>
  </div>
  <div
    v-if="meta.touched && errorMessage"
    class="text-sm text-red-600 mb-2 text-center"
  >
    {{ error || errorMessage }}
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { useField } from 'vee-validate';
import useIMask, { OPTIONS_LATINIC_INPUT } from '@/compositions/useIMask.js';

const props = defineProps({
  name: { type: String, required: true },
  modelValue: { type: String, default: '' },
  mask: { type: Object, default: () => OPTIONS_LATINIC_INPUT },
  placeholderLabel: { type: Boolean, default: false },
  placeholder: { type: String, default: ' ', required: false },
  error: { type: String, default: '' },
  unit: { type: String, default: '' },
  maxLength: { type: Number, default: 0 },
});

const { value, errorMessage, meta } = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const { el, unmasked } = useIMask({
  ...props.mask,
  defaultValue: props.modelValue,
});

const emit = defineEmits({
  'update:modelValue': null,
});

watch(unmasked, (val) => {
  emit('update:modelValue', val);
});

watch(
  () => props.modelValue,
  () => {
    if (props.mask && props.modelValue) {
      unmasked.value = props.modelValue.toString();
    }
  },
);
</script>

<style scoped>
.textarea {
  @apply rounded border border-transparent bg-[#F3F5F8] w-full text-base pt-6 py-4 px-3 outline-none h-[72px] text-[#292C2E];
}

.textarea:focus,
.textarea:not(:placeholder-shown) {
  @apply border-[#676C71] bg-transparent text-[#292C2E];
}

.textarea:focus + label,
.textarea:not(:placeholder-shown) + label {
  @apply text-sm pt-2 leading-4 bg-white;
}

.textarea.has-unit {
  @apply pr-6;
}
</style>
