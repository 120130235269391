<template>
  <MainLayout :transparent-background="true">
    <MainContent class="px-7">
      <div class="mt-14">
        <img class="w-16" src="/src/assets/images/ggw-logo-blue.svg" alt="" />
        <h1 class="text-[30px] leading-10 mt-10">
          Log in
        </h1>
        <form class="mt-5" @submit="request">
          <div class="mb-2">
            <FormInput
              v-model="state.email"
              name="email"
              placeholder-label
            >
              Email
            </FormInput>
          </div>

          <FormInput
            v-model="state.password"
            type="password"
            name="passwd"
            placeholder-label
          >
            Password
          </FormInput>

          <router-link
            :to="{ name: 'ForgotPasswordScreen' }"
            class="inline-flex mt-2 font-medium"
          >
            Forgot Password?
          </router-link>

          <Btn
            class="mt-10"
            type="submit"
            :disabled="isProcessing"
            :is-loading="isProcessing"
          >
            Log in
          </Btn>
        </form>
        <router-link :to="{ name: 'SignUp', query: route.query }" class="mt-6 flex justify-center">
          Sign up
        </router-link>

        <GoogleSignInButton class="mt-6 flex justify-center" />
      </div>
      <div class="mt-auto text-center mb-6">
        <a
          class="text-base text-[#008AFC]"
          href="https://www.goglobal.world"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go Global World
        </a>
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
import { object, string } from 'yup';
import Btn from '../../components/Btn.vue';
import FormInput from '../../components/FormInput.vue';
import useAuth from '../../compositions/useAuth';
import useRequest from '../../compositions/useRequest';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';

import GoogleSignInButton from '../../components/GoogleSignInButton.vue';

import parseServerErrors from '../../utils/parseServerErrors';

const router = useRouter();
const route = useRoute();
const { state, signIn } = useAuth();

const schema = object({
  email: string().email().required().label('Email'),
  passwd: string().required().label('Password'),
});

const { handleSubmit, setErrors } = useForm({ validationSchema: schema });
const { request, isProcessing } = useRequest(handleSubmit(async (values) => {
  try {
    const response = await signIn(
        values.email,
        values.passwd,
        null,
        null,
        {
          investor_id: route.query.investor_id
        }
    );

    if (route.query?.event) {
      await router.push({ name: 'Event', params: { id: route.query.event } });
    } else if (response.code === 'code') {
      await router.push({ name: 'VerificationScreen', query: route.query });
    } else {
      await router.push({ name: 'WelcomeScreen', query: route.query });
    }
  } catch (error) {
    setErrors(parseServerErrors(error.errors));
  }
}));

</script>

