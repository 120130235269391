<template>
  <MainLayout :transparent-background="true">
    <MainContent>
      <AppHeader is-bg-white is-btn-back as-logged>
        <div class="flex">
          <img
            class="mx-auto h-8"
            src="/src/assets/images/welcome-logo.png"
            alt="logo Go Global World"
          />
          <ModalContactUs />
        </div>
      </AppHeader>

      <div class="p-4 mt-14">
        <form @submit.prevent="onSubmit">
          <div class="flex gap-2">
            <div class="flex-1">
              <FormInput
                v-model.trim="state.firstName"
                name="first_name"
                placeholder-label
              >
                First Name
              </FormInput>
            </div>
            <div class="flex-1">
              <FormInput
                v-model.trim="state.lastName"
                name="last_name"
                placeholder-label
              >
                Last Name
              </FormInput>
            </div>
          </div>

          <div class="mt-2">
            <FormInput v-model="state.email" name="email" placeholder-label>
              Email
            </FormInput>
          </div>

          <div class="mt-2">
            <FormInput
              v-model="state.password"
              name="passwd"
              type="password"
              placeholder-label
            >
              Password
            </FormInput>
          </div>

          <div class="flex gap-4 py-4 items-center">
            <div class="h-px bg-[#DFE4E8] w-full" />
            <div class="text-[#292C2E] text-base flex-none">
              For better experience (optional)
            </div>
            <div class="h-px bg-[#DFE4E8] w-full" />
          </div>

          <FormInput v-model="state.linkedin" name="linkedin" placeholder-label>
            Linkedin
          </FormInput>

          <div class="mt-2">
            <FormCountryAutocomplete
              v-model="state.countryId"
              name="country_id"
              placeholder-label
            >
              Country
            </FormCountryAutocomplete>
          </div>

          <div class="mt-6 relative flex items-center">
            <label class="flex h-5 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                required="required"
                type="checkbox"
                class="h-4 w-4 rounded border-[#DFE4E8] text-[#008AFC]"
              />
            </label>
            <div class="ml-2 text-sm leading-4 text-[#676C71]">
              <label for="comments">
                I have read and agree with Go Global World’s
                <a
                  href="https://www.goglobal.world/terms-of-service"
                  class="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                ,
                <a
                  href="https://www.goglobal.world/privacy-policy"
                  class="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                ,
                <a
                  href="https://www.goglobal.world/gdpr"
                  class="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GDPR
                </a>
                ,
                <a
                  href="https://www.goglobal.world/do-not-sell"
                  class="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CCPA policy
                </a>
              </label>
            </div>
          </div>
          <Btn class="mt-6" type="submit" :is-loading="isProcessing">
            Sign up
          </Btn>
          <router-link :to="{ name: 'LoginScreen', query: route.query }">
            <Btn class="mt-2" theme="outline"> Already have an account </Btn>
          </router-link>
        </form>

        <GoogleSignInButton
          button-text="signup_with"
          class="mt-6 flex justify-center"
        />
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
import { object, string } from 'yup';

import Btn from '../../components/Btn.vue';
import useAuth from '../../compositions/useAuth';
import useRequest from '../../compositions/useRequest';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import FormInput from '../../components/FormInput.vue';
import AppHeader from '../../components/AppHeader.vue';
import ModalContactUs from '../../components/ModalContactUs.vue';
import FormCountryAutocomplete from '../../components/FormCountryAutocomplete.vue';
import GoogleSignInButton from '../../components/GoogleSignInButton.vue';
import parseServerError from '../../utils/parseServerErrors';
import useUtmCollector from '@/compositions/useUtmCollector.js';

const router = useRouter();
const route = useRoute();
const { signUp, state } = useAuth();

const schema = object({
  first_name: string().min(2).required().label('First name'),
  last_name: string().min(2).required().label('Last name'),
  email: string().email().required().label('Email'),
  passwd: string().required().label('Password'),
});

const { handleSubmit, setErrors } = useForm({ validationSchema: schema });
const { getQueryString } = useUtmCollector();
const { request, isProcessing } = useRequest(signUp);

const onSubmit = handleSubmit(async (values) => {
  try {
    await request({
      ...values,
      query_string: getQueryString(),
      investor_id: route.query.investor_id,
    });
    await router.push({ path: '/verification', query: route.query });
  } catch (error) {
    setErrors(parseServerError(error.errors));
  }
});
</script>
