<template>
  <div class="inline-flex items-center flex-wrap gap-2">
    <input
      :id="uid"
      v-model="radioValue"
      :name="name"
      type="radio"
      class="h-4 w-4 border-[#DFE4E8] text-[#008AFC] focus:ring-[#008AFC]"
      :value="inputValue"
    />
    <label :for="uid" class="text-base text-[#676C71]">
      {{ label }}
    </label>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { computed, getCurrentInstance, toRefs } from 'vue';

const { uid } = getCurrentInstance();

const emit = defineEmits({
  'update:model-value': null,
});

const props = defineProps({
  name: { type: String, required: true },
  modelValue: { type: [String, Number], required: true },
  label: { type: String, default: '' },
  value: { type: [String, Number], default: '' },
});

const { name } = toRefs(props);

// eslint-disable-next-line vue/no-setup-props-destructure
const inputValue = props.value;

const { value: fieldValue } = useField(name, undefined, {
  initialValue: props.modelValue,
});

const radioValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    fieldValue.value = val;
    emit('update:model-value', val);
  },
});
</script>
