import { ref } from 'vue';

export default function useClipboard(duration = 800) {
  const isCopied = ref(false);

  const copy = async (value) => {
    await navigator.clipboard.writeText(value);

    isCopied.value = true;

    setTimeout(() => {
      isCopied.value = false;
    }, duration);
  };

  return {
    isCopied,
    copy,
  };
}
