import { reactive } from 'vue';
import { useScriptTag } from '@vueuse/core';
import { decodeJwt } from 'jose';
import useAuth from './useAuth';
import useUtmCollector from "@/compositions/useUtmCollector.js";

const { signIn } = useAuth();
const { getQueryString } = useUtmCollector();

let resolver = null;

const promise = new Promise((resolve) => {
  resolver = resolve;
});

const userData = reactive({});

const { load, unload } = useScriptTag(
  'https://accounts.google.com/gsi/client',
  () => {},
  {
    async: true,
    manual: true,
  },
);

window.onSignIn = async function onSignIn(response) {
  const responsePayload = decodeJwt(response.credential);
  const query = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  userData.name = responsePayload.name;
  userData.email = responsePayload.email;
  userData.picture = responsePayload.picture;
  userData.token = response.credential;

  await signIn(
    userData.email,
    null,
    response.credential,
    'google',
    {
      investor_id: query.investor_id,
      query_string: getQueryString()
    },
  );

  resolver({ ...userData });
};

export default function useGoogleSignIn() {
  if (userData.picture) {
    return Promise.resolve({ ...userData });
  }

  return {
    promise,
    unload,
    load,
  };
}
