/* eslint-disable camelcase */
export default class Company {
  constructor({
    investment_status,
    logo,
    name,
    other,
    country_id,
    linkedin,
    twitter,
    video,
    short_bio,
    username,

    // only advisor fields
    role,
    compensation_expactations,
    expertise,
  }) {
    this.is_personal = false;
    this.investment_status = investment_status;
    this.name = name;
    this.other = other;
    this.country_id = country_id;
    this.linkedin = linkedin;
    this.twitter = twitter;
    this.video = video;
    this.short_bio = short_bio;
    this.logo = logo;
    this.username = username;

    this.role = role;
    this.compensation_expactations = compensation_expactations;
    this.expertise = expertise;
  }

  static fromServerJSON({
    investment_status,
    logo,
    name,
    other,
    country_id,
    linkedin,
    twitter,
    video,
    short_bio,
    username,

    // only advisor fields
    role,
    compensation_expactations,
    expertise,
  }) {
    return new Company({
      investment_status,
      logo,
      name,
      other,
      country_id,
      linkedin,
      twitter,
      video,
      short_bio,
      username,

      // only advisor fields
      role,
      compensation_expactations,
      expertise,
    });
  }
}
