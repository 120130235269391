import { computed, ref } from 'vue';
import { cloneDeep, isEqual } from 'lodash';

import Personal from '@/models/Personal.js';
import { post } from '@/services/httpClient.js';
import SmartForm from '@/models/SmartForm.js';
import Company from '@/models/Company.js';
import {
  BLOCK_INDEX_COMPANY,
  BLOCK_ID_PERSONAL_INFORMATION,
  BLOCK_ID_TO_BLOCK_INDEX_MAP,
  BLOCK_INDEX_FOUNDERS,
  BLOCK_INDEX_PERSONAL,
  USER_ROLE_STARTUP,
  USER_ROLE_INVESTOR,
  USER_ROLE_ADVISOR,
  BLOCK_ID_STARTUP_PROFILE,
} from '@/constants.js';
import makeTreeWalker from '@/services/treeWalker.js';
import useUser from './useUser.js';

const treeWalker = makeTreeWalker();

const { update, user } = useUser();
const initialBlock = new Map();
const steps = [];
const isReady = ref(false);

const blocks = ref([]);
const currentBlockIndex = ref(null);

const getBlockIndexByBlockId = (blockId) =>
  blocks.value.find((block) => blockId === block.id)?.index ?? blockId;

const currentBlock = computed(() =>
  blocks.value.find((item) => item.index === currentBlockIndex.value),
);

const smartForm = ref(null);
const schema = ref(null);
const validationSchema = computed(
  () => SmartForm.getValidationSchema(schema.value) || {},
);
const initialValues = computed(() => smartForm.value?.getInitialValues() || {});
const additionalFields = computed(
  () => smartForm.value?.getAdditionalFields() || {},
);

const visibilityState = computed(
  () => smartForm.value?.nodesWithVisibilitiConditions,
);

const parseCountryId = (data) => {
  if (!data) {
    return undefined;
  }

  return Array.isArray(data.country_id) ? data.country_id[0] : data.country_id;
};

const withBlockIdToBlockIndexReplace =
  (fn) =>
  (blockId, ...params) => {
    const blockIndex =
      BLOCK_ID_TO_BLOCK_INDEX_MAP[blockId] ?? getBlockIndexByBlockId(blockId);
    return fn.apply(this, [blockIndex, ...params]);
  };

const withProxySave = (fn) =>
  withBlockIdToBlockIndexReplace(async (blockIndex, data) => {
    if (blockIndex === BLOCK_INDEX_PERSONAL) {
      return update({
        ...data,
        country_id: parseCountryId(data),
      });
    }

    if (blockIndex === BLOCK_INDEX_FOUNDERS) {
      await update({
        ...data.users_basic,
        country_id: parseCountryId(data.users_basic),
      });

      delete data.users_basic;
    }

    if (blockIndex === BLOCK_INDEX_COMPANY && data.is_personal) {
      const p = {
        ...user,
        position: data.other,
        linkedin: data.linkedin,
        twitter: data.twitter,
        short_bio: data.short_bio,
        country_id: parseCountryId(data),
      };

      return Promise.all([update(p), fn(blockIndex, data)]);
    }

    return fn(blockIndex, data);
  });

const withProxyGetCurrentData = (fn) =>
  withBlockIdToBlockIndexReplace(async (blockIndex, ...props) => {
    if (blockIndex === BLOCK_INDEX_PERSONAL) {
      return Promise.resolve({ ...user });
    }

    const response = await fn.apply(this, [blockIndex, ...props]);

    if (blockIndex === BLOCK_INDEX_FOUNDERS) {
      const userBasic = {
        'users_basic.avatar': user.avatar || '',
        'users_basic.first_name': user.firstname || '',
        'users_basic.last_name': user.lastname || '',
        'users_basic.position': user.position || '',
        'users_basic.country_id': user.country_id || '',
        'users_basic.linkedin': user.linkedIn || '',
        'users_basic.short_bio': user.shortBio || '',
      };

      Object.assign(response, userBasic);
    }

    if (
      blockIndex === BLOCK_INDEX_COMPANY &&
      [USER_ROLE_INVESTOR, USER_ROLE_ADVISOR].includes(user.role)
    ) {
      if (
        response &&
        (!Object.hasOwnProperty.call(response, 'is_personal') ||
          response.is_personal)
      ) {
        return Object.assign(Personal.fromUserJSON(user), {
          is_accredited: response.is_accredited,
          is_follow: response.is_follow,
          is_lead: response.is_lead,
          investment_status: response.investment_status,
          role: response.role,
          compensation_expactations: response.compensation_expactations,
          expertise: response.expertise,
          video: response.video,
        });
      }
    }

    return response;
  });

const getBlocks = async (userRole, type = 'profile') => {
  const res = await post('blocks/', {
    command: 'getblocks',
    user_type: userRole,
    type,
  });

  return res.map((item) => ({
    ...item,
    required: Boolean(Number(item.required)),
  }));
};

const getBlockById = withBlockIdToBlockIndexReplace(
  async (blockIndex, role = USER_ROLE_STARTUP) => {
    const [block] = await post('blocks/', {
      command: 'getblocks',
      block_index: blockIndex,
      user_type:
        blockIndex ===
        BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_PERSONAL_INFORMATION]
          ? 'all'
          : role,
    });

    return { ...block, required: Boolean(Number(block.required)) };
  },
);

const getStructure = withBlockIdToBlockIndexReplace(
  (blockIndex, role = USER_ROLE_STARTUP) =>
    post('blocks/', {
      command: 'structure',
      block_index: blockIndex,
      user_type:
        blockIndex ===
        BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_PERSONAL_INFORMATION]
          ? 'all'
          : role,
    }).then((response) => {
      if (
        blockIndex === BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_STARTUP_PROFILE]
      ) {
        response = response.map((row) =>
          row.map((inputSchema) => {
            if (inputSchema.name === 'username') {
              inputSchema.mask = {
                mask: /^.*$/i,
                commit: (val, masked) => {
                  masked._value = val
                    .split('-')
                    .filter((e) => e)
                    .join('-');
                },
                prepare: (val) => {
                  if (/[A-Za-z0-9]/g.test(val)) {
                    return val.toLowerCase();
                  }
                  if (val === ' ' || val === '-') {
                    return '-';
                  }

                  return '';
                },
              };
            }

            return inputSchema;
          }),
        );
      }

      return response;
    }),
);

const getCurrentData = withProxyGetCurrentData(
  (blockIndex, role = USER_ROLE_STARTUP) =>
    post('blocks/', {
      command: 'get',
      block_index: blockIndex,
      user_type: role,
    }),
);

const filterSchema = (schema, formValues) => {
  const clonedSchema = cloneDeep(schema);
  return clonedSchema
    .map((fields) =>
      fields.filter((field) => {
        if (!field.filters || field.filters.length === 0) {
          return true;
        }

        return field.filters.every(({ name, value }) =>
          isEqual(formValues[name], value),
        );
      }),
    )
    .filter((fields) => fields.length);
};

function setSchema({ structure, currentData }) {
  smartForm.value = new SmartForm(
    treeWalker,
    filterSchema(structure, currentData),
    currentData,
  );
  schema.value = smartForm.value.getSchema();
  isReady.value = true;
}

const getBlock = async (blockIndex, userRole = USER_ROLE_STARTUP) => {
  isReady.value = false;

  const [structure, currentData] = await Promise.all([
    getStructure(blockIndex, userRole),
    getCurrentData(blockIndex, userRole),
  ]);
  initialBlock.set('block', { structure, currentData });
  setSchema(initialBlock.get('block'));
};

const save = withProxySave(async (blockIndex, data) =>
  post('blocks/', {
    command: 'save',
    block_index: blockIndex,
    data,
  }),
);

const getNextBlockIndex = () => {
  const index = steps.indexOf(currentBlockIndex.value);

  return steps[index + 1];
};

const next = async (data, blockIndex, userRole = USER_ROLE_STARTUP) => {
  if (data) {
    if (data.rounds !== undefined && !Array.isArray(data.rounds)) {
      data.rounds = [data.rounds].filter((e) => e);
    }

    await save(currentBlockIndex.value, data);
  }

  currentBlockIndex.value = blockIndex || getNextBlockIndex();

  if (!currentBlockIndex.value) {
    schema.value = null;
    return false;
  }

  await getBlock(currentBlockIndex.value, userRole);

  return true;
};

async function fetchSchema(
  userRole,
  additionalBlocks = [],
  activeBlockIndex = null,
) {
  try {
    const blockList = await getBlocks(userRole);
    const addBlocks = await Promise.all(
      additionalBlocks.map((blockId) => getBlockById(blockId)),
    );

    if (addBlocks.length) {
      blockList.unshift(...addBlocks);
    }

    currentBlockIndex.value = null;
    steps.splice(0, steps.length);

    blocks.value = blockList;

    steps.push(...blocks.value.map((block) => block.index));

    if (steps.length === 0) {
      return;
    }

    currentBlockIndex.value = activeBlockIndex || getNextBlockIndex();

    await getBlock(currentBlockIndex.value, userRole);
  } catch (error) {
    console.log(error);
  }
}

export const getPersonalBlockData = (isPersonal, savedData, userData) => {
  const username = [userData.first_name, userData.last_name]
    .map((item) => item.toLowerCase())
    .join('-');

  const data = savedData.is_personal
    ? Personal.fromServerJSON(savedData)
    : Company.fromServerJSON(savedData);

  if (isPersonal && data instanceof Company) {
    return Personal.fromUserJSON(userData);
  }

  if (!isPersonal && data instanceof Personal) {
    return new Company({ username });
  }

  return data;
};

const handlePersonalChange = (values) => {
  const { structure, currentData } = initialBlock.get('block');

  setSchema({
    structure,
    currentData: getPersonalBlockData(values.is_personal, currentData, user),
  });
};

const buildStartupName = (values) => {
  const { currentData } = initialBlock.get('block');
  const username = values.name
    .split(' ')
    .filter((e) => e)
    .map((e) => e.toLowerCase().replaceAll(/[^0-9a-zA-Z-]*/g, ''))
    .join('-');

  if (!currentData.username) {
    values.username = username;
  }
};

export default function useSmartForm() {
  const handleChange = (values, oldValues) => {
    if (currentBlockIndex.value === BLOCK_INDEX_COMPANY) {
      if (user.role === USER_ROLE_STARTUP) {
        if (values.name) {
          buildStartupName(values);
        }
      }
    }
  };

  return {
    isReady,
    schema,
    currentBlockIndex,
    currentBlock,
    blocks,
    validationSchema,
    additionalFields,
    initialValues,
    visibilityState,
    save,
    next,
    fetchSchema,
    getBlock,
    getBlocks,
    handleChange,
    handlePersonalChange,
  };
}
