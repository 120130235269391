<template>
  <BaseBlock class="py-3 px-4 lg:px-5 lg:py-6 relative">
    <slot />

    <button
      type="button"
      class="absolute right-4 top-3"
      @click="onClick"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7999 19.5524H19.7999M4.19995 19.5524L8.56594 18.6727C8.79771 18.626 9.01053 18.5119 9.17767 18.3446L18.9513 8.56559C19.4199 8.09674 19.4196 7.33675 18.9506 6.86829L16.8802 4.80021C16.4114 4.33194 15.6518 4.33226 15.1834 4.80092L5.40871 14.581C5.2419 14.7479 5.128 14.9603 5.08125 15.1916L4.19995 19.5524Z"
          stroke="#292C2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </BaseBlock>
</template>

<script setup>
import BaseBlock from '@/components/blocks/BaseBlock.vue';

const emit = defineEmits({
  edit: null,
});
const onClick = () => {
  emit('edit');
};
</script>
