<template>
  <MainLayout has-header has-footer>
    <MainContent is-big-width class="!bg-[#EEF2F5] pb-[77px] lg:pb-0">
      <AppHeader
        is-bg-white
        :is-btn-back="state.isAuthenticated"
        is-big-width
        class="lg:hidden"
        has-logo
        is-btn-menu
      >
        <h1
          class="text-base font-bold text-[#292C2E] flex justify-center gap-1 items-center"
        >
          <img class="h-7" src="/src/assets/images/logo-small.png" alt="" />
          My connections
        </h1>
      </AppHeader>
      <div class="mt-[44px] lg:mt-12 mb-10 lg:mb-0 space-y-3 lg:space-y-6">
        <div class="lg:flex gap-4 hidden">
          <button
            class="outline-none w-14 h-12 flex items-center justify-center bg-white rounded"
            @click="router.back()"
          >
            <img src="/src/assets/images/chevron-left-icon.svg" alt="" />
          </button>
          <h1 class="text-[32px] leading-[48px] text-[#292C2E] font-bold">
            My connections
          </h1>
        </div>

        <Card
          v-if="connectionRequest"
          class="divide-y divide-[#DFE4E8] !p-4 lg:!py-6"
        >
          <div class="flex items-center justify-between gap-1">
            <span class="text-base font-bold text-[#676C71]">
              Connections requests
            </span>
            <router-link
              :to="{
                name: 'ConnectionsRequests',
                params: { type: CONNECTION_REQUESTS },
              }"
            >
              <span class="text-base font-bold text-[#008AFC]">
                View all requests...
              </span>
            </router-link>
          </div>

          <div
            class="mt-4 pt-6 lg:pt-8 pb-2 lg:pb-4 flex justify-between items-start gap-y-2 gap-x-3 flex-wrap sm:flex-nowrap cursor-pointer"
            @click="routeToProfile(connectionRequest)"
          >
            <UserPhoto
              :photo="connectionRequest.photo"
              :viewed="connectionRequest.viewed"
            />
            <UserInfo
              class="flex-1"
              :name="connectionRequest.name"
              :headliner="connectionRequest.headliner"
              :country="connectionRequest.geography"
              :type="connectionRequest.type"
              :status="connectionRequest.status"
              :verified="connectionRequest.is_verified"
            />
            <div
              v-if="!connectionRequest.action"
              class="flex gap-2 w-full sm:w-auto ml-[68px]"
            >
              <Btn
                theme="white"
                size="xs"
                class="max-w-max !p-2 sm:!p-3 !leading-4"
                @click.stop="onReaction(connectionRequest, COMMAND_DISLIKE)"
              >
                Pass
              </Btn>
              <Btn
                size="xs"
                class="max-w-max !p-2 sm:!p-3 !leading-4"
                @click.stop="onReaction(connectionRequest, COMMAND_LIKE)"
              >
                Connect
              </Btn>
            </div>
          </div>
        </Card>

        <Card class="!p-4 lg:!py-6 space-y-4">
          <div
            class="inline-flex rounded overflow-hidden divide-x divide-[#DFE4E8] border-[#DFE4E8] border"
          >
            <button
              v-for="(config, index) in userTypes"
              :key="index"
              type="button"
              class="p-2 lg:p-3 outline-none text-sm leading-4 lg:font-bold text-[#292C2E]"
              :class="{ 'bg-[#DFE4E8]': filters.state.type === config.type }"
              @click="filters.state.type = config.type"
            >
              {{ config.text }}
            </button>
          </div>

          <InputSearch
            class="bg-[#F3F5F8]"
            v-model="filters.state.search"
            placeholder="Search"
          />

          <ul class="divide-[#DFE4E8] divide-y">
            <li
              v-for="item in users"
              :key="item"
              class="py-2 lg:py-4 flex justify-between items-start gap-y-2 gap-x-3 flex-wrap sm:flex-nowrap cursor-pointer"
              @click="routeToProfile(item)"
            >
              <UserPhoto :photo="item.photo" :viewed="item.viewed" />
              <UserInfo
                class="flex-1"
                :name="item.name"
                :headliner="item.headliner"
                :country="item.geography"
                :type="item.type"
                :status="item.status"
                :verified="item.is_verified"
              />
              <div class="w-full sm:w-auto ml-[68px]">
                <Btn
                  v-if="item.dialog_id"
                  theme="white"
                  size="xs"
                  class="max-w-max !p-2 sm:!p-3 !leading-4"
                  @click.stop="routeToDialog(item.dialog_id)"
                >
                  Write a message
                </Btn>
              </div>
            </li>
          </ul>
        </Card>
      </div>
      <MenuBottom class="lg:hidden" is-big-width />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import AppHeader from '@/components/AppHeader.vue';
import useAuth from '@/compositions/useAuth.js';
import MenuBottom from '@/components/MenuBottom.vue';
import Card from '@/screens/profile-desktop/components/Card.vue';
import UserPhoto from '@/components/UserPhoto.vue';
import Btn from '@/components/Btn.vue';
import UserInfo from '@/components/UserInfo.vue';
import InputSearch from '@/screens/connections/components/InputSearch.vue';
import useMyConnectionFilters, {
  ALL,
} from '@/compositions/useMyConnectionFilters.js';
import useMyConnections from '@/compositions/useMyConnections.js';
import { onMounted, ref, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants.js';
import usePageScroll from '@/compositions/usePageScroll.js';
import useConnections from '@/compositions/useConnections.js';
import { CONNECTION_REQUESTS } from '@/compositions/useConnectionFilters.js';
import useReaction from '@/compositions/useReaction.js';
import { COMMAND_DISLIKE, COMMAND_LIKE } from '@/compositions/useMatching.js';
import { useDebounceFn } from '@vueuse/core';

const router = useRouter();
const { state } = useAuth();
const filters = useMyConnectionFilters();
const myConnections = useMyConnections();
const connections = useConnections();
const { onPageFullyScrolled, offPageFullyScrolled } = usePageScroll();

const users = ref([]);
const connectionRequest = ref(null);
const limit = 6;
let thereIsNoMore = false;
let offset = 0;
let loadingDisabled = false;

const userTypes = [
  { type: ALL, text: 'All Connections' },
  { type: USER_ROLE_INVESTOR, text: 'Investors' },
  { type: USER_ROLE_STARTUP, text: 'Startups' },
  { type: USER_ROLE_ADVISOR, text: 'Advisors' },
];

onUnmounted(() => {
  offPageFullyScrolled();

  filters.state.search = '';
});

function routeToDialog(dialogId) {
  router.push({ name: 'DialogScreen', params: { id: dialogId } });
}

const loadConnections = useDebounceFn(async (force = false) => {
  if (!loadingDisabled || force) {
    loadingDisabled = true;

    if (force) {
      offset = 0;
    } else {
      offset += limit;
    }

    try {
      const result = await myConnections.load(filters.state, offset, limit);

      if (!offset) {
        users.value = result.list;
      } else {
        users.value.push(...result.list);
      }

      thereIsNoMore = offset >= result.count && result.list.length === 0;
    } catch (e) {
      console.error(e);
    }

    loadingDisabled = false;
  }
}, 500);

async function onReaction(user, type) {
  const { makeReaction } = useReaction(user.id);

  Object.assign(user, { action: type });

  await makeReaction(type, true);
}

function openInNewWindow(routerData) {
  const routeData = router.resolve(routerData);

  window.open(routeData.href, '_blank');
}

function routeToProfile(user) {
  Object.assign(user, { viewed: true });

  if (user.type === USER_ROLE_INVESTOR) {
    openInNewWindow({ name: 'ProfileInvest', params: { id: user.id } });
  } else if (user.type === USER_ROLE_STARTUP) {
    openInNewWindow({ name: 'ProfileStartup', params: { id: user.id } });
  } else if (user.type === USER_ROLE_ADVISOR) {
    openInNewWindow({ name: 'ProfileAdvisor', params: { id: user.id } });
  }
}

onMounted(async () => {
  watch(
      filters.state,
      () => {
        loadConnections(true);
      },
      { immediate: true }
  );

  onPageFullyScrolled(() => {
    if (!thereIsNoMore) {
      loadConnections();
    }
  });

  connections
    .load(
      {
        userType: ALL,
        relevant: true,
        type: CONNECTION_REQUESTS,
      },
      0,
      1,
    )
    .then((result) => {
      connectionRequest.value = result.list[0] || null;
    })
    .catch(() => {});
});
</script>
