<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Verticals
      </h1>
      <Completed has-text />
    </div>

    <p class="mt-2 text-sm text-[#292C2E]">
      Choose up to 8 verticals that fit to your startup. First 3  are showing on the top of your profile.
    </p>

    <div class="mt-4 flex justify-between text-sm font-bold">
      <button class="text-[#008AFC]">
        Select all
      </button>
      <button class="text-[#FC0000] disabled:text-[#DFE4E8]">
        Deselect all
      </button>
    </div>

    <ul class="my-5 space-y-2">
      <li v-for="(item, index) in lists" :key="index" class="pl-1 flex gap-3">
        <FormCheckbox :id="item.id" class="w-4" name="" />
        <label class="text-base text-[#292C2E]" :for="item.id">{{ item.label }}</label>
      </li>
    </ul>

    <Btn class="mt-auto">
      Save & Next
    </Btn>
  </div>
</template>

<script setup>
import Btn from '../../../components/Btn.vue';
import FormCheckbox from '../../../components/FormCheckbox.vue';
import Completed from '../../update/components/Completed.vue';

const lists = [
  {
    id: 'printing',
    label: '3D Printing',
  },
  {
    id: '5g',
    label: '5G',
  },
  {
    id: 'additive',
    label: 'Additive Manufacturing',
  },
  {
    id: 'computing',
    label: 'Advanced Computing',
  },
  {
    id: 'engineering',
    label: 'Advanced Engineering',
  },
  {
    id: 'materials',
    label: 'Advanced Materials',
  },
  {
    id: 'aerospace',
    label: 'Aerospace',
  },
  {
    id: 'ageTech',
    label: 'AgeTech',
  },
];
</script>

<style scoped>
input[type="checkbox"]:disabled + label {
  @apply text-[#676C71];
}
</style>
