/* eslint-disable camelcase */

export default class Personal {
  constructor({
    is_accredited,
    is_follow,
    is_lead,
    investment_status,
    logo,
    name,
    other,
    country_id,
    linkedin,
    twitter,
    video,
    short_bio,
    username,
    position,

    // advisor only
    role,
    compensation_expactations,
    expertise,
  }) {
    this.is_personal = true;
    this.is_accredited = is_accredited;
    this.is_follow = is_follow;
    this.is_lead = is_lead;
    this.investment_status = investment_status;
    this.logo = logo;
    this.name = name;
    this.country_id = country_id;
    this.linkedin = linkedin;
    this.twitter = twitter;
    this.video = video;
    this.short_bio = short_bio;
    this.username = username;
    this.position = position;
    this.other = position || other;

    this.role = role;
    this.compensation_expactations = compensation_expactations;
    this.expertise = expertise;
  }

  static fromUserJSON({
    avatar,
    first_name,
    last_name,
    country_id,
    short_bio,
    linkedin,
    twitter,
    position,
  }) {
    return new Personal({
      logo: avatar,
      name: `${first_name} ${last_name}`,
      other: short_bio,
      country_id,
      linkedin,
      twitter,
      short_bio,
      username: [first_name, last_name]
        .map((item) => item.toLowerCase())
        .join('-'),
      position,
    });
  }

  static fromServerJSON({
    is_accredited,
    is_follow,
    is_lead,
    investment_status,
    logo,
    name,
    other,
    country_id,
    linkedin,
    twitter,
    video,
    short_bio,
    username,
    position,

    role,
    compensation_expactations,
    expertise,
  }) {
    return new Personal({
      is_accredited,
      is_follow,
      is_lead,
      investment_status,
      logo,
      name,
      other,
      country_id,
      linkedin,
      twitter,
      video,
      short_bio,
      username,
      position,
      role,
      compensation_expactations,
      expertise,
    });
  }
}
