<template>
  <MainLayout has-header has-footer>
    <MainContent is-big-width class="!bg-[#EEF2F5] pb-[77px] lg:pb-0">
      <AppHeader
        is-bg-white
        :is-btn-back="state.isAuthenticated"
        is-big-width
        class="lg:hidden"
        has-logo
        is-btn-menu
      >
        <h1
          class="text-base font-bold text-[#292C2E] flex justify-center gap-1 items-center"
        >
          <img class="h-7" src="/src/assets/images/logo-small.png" alt="" />
          {{ header }}
        </h1>
      </AppHeader>
      <div class="mt-[44px] lg:mt-12 mb-10 lg:mb-0 space-y-3 lg:space-y-6">
        <div class="hidden lg:flex gap-4">
          <button
            class="outline-none w-14 h-12 flex items-center justify-center bg-white rounded"
            @click="router.back()"
          >
            <img src="/src/assets/images/chevron-left-icon.svg" alt="" />
          </button>
          <h1 class="text-[32px] leading-[48px] text-[#292C2E] font-bold">
            {{ header }}
          </h1>
        </div>

        <Card class="!p-4 lg:!py-6 space-y-4">
          <div class="lg:flex justify-between gap-3">
            <div
              class="inline-flex rounded overflow-hidden divide-x divide-[#DFE4E8] border-[#DFE4E8] border"
            >
              <button
                v-for="(button, index) in relevantButtons"
                :key="index"
                type="button"
                class="p-2 lg:p-3 outline-none text-sm leading-4 font-bold text-[#292C2E]"
                :class="{
                  'bg-[#DFE4E8]': filters.state.relevant === !Boolean(index),
                }"
                @click="filters.state.relevant = !Boolean(index)"
              >
                {{ button }}
              </button>
            </div>

            <div
              v-if="userTypes.length"
              class="mt-4 lg:mt-0 inline-flex rounded overflow-hidden divide-x divide-[#DFE4E8] border-[#DFE4E8] border"
            >
              <button
                v-for="(config, index) in userTypes"
                :key="index"
                type="button"
                class="p-2 lg:p-3 outline-none text-sm leading-4 font-bold text-[#292C2E]"
                :class="{
                  'bg-[#DFE4E8]': filters.state.userType === config.type,
                }"
                @click="filters.state.userType = config.type"
              >
                {{ config.text }}
              </button>
            </div>
          </div>

          <ul class="divide-[#DFE4E8] divide-y">
            <li
              v-for="item in users"
              :key="item"
              class="py-2 lg:py-4 flex justify-between gap-y-2 gap-x-3 flex-wrap sm:flex-nowrap cursor-pointer"
              @click="routeToProfile(item)"
            >
              <UserPhoto :photo="item.photo" :viewed="item.viewed" />
              <UserInfo
                class="flex-1"
                :name="item.name"
                :headliner="item.headliner"
                :type="item.type"
                :country="item.geography"
                :status="item.status"
                :verified="item.is_verified"
              />
              <div
                v-if="!item.action"
                class="flex gap-2 w-full sm:w-auto ml-[68px]"
              >
                <Btn
                  theme="white"
                  size="xs"
                  class="max-w-max !p-2 sm:!p-3 !leading-4"
                  @click.stop="onReaction(item, COMMAND_DISLIKE)"
                >
                  Pass
                </Btn>
                <Btn
                  size="xs"
                  class="max-w-max !p-2 sm:!p-3 !leading-4"
                  @click.stop="onReaction(item, COMMAND_LIKE)"
                >
                  Connect
                </Btn>
              </div>
            </li>
          </ul>
        </Card>
      </div>
      <MenuBottom class="lg:hidden" is-big-width />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import AppHeader from '@/components/AppHeader.vue';
import useAuth from '@/compositions/useAuth.js';
import MenuBottom from '@/components/MenuBottom.vue';
import Card from '@/screens/profile-desktop/components/Card.vue';
import UserPhoto from '@/components/UserPhoto.vue';
import Btn from '@/components/Btn.vue';
import UserInfo from '@/components/UserInfo.vue';
import { useRouter, useRoute } from 'vue-router';
import usePageScroll from '@/compositions/usePageScroll.js';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants.js';
import useConnectionFilters, {
  ALL,
  FOR_LATER,
  FUNDING_REQUESTS,
  CONNECTION_REQUESTS,
  MY_CONNECTION_REQUESTS,
  MY_FOR_LATER,
  MY_PASS,
} from '@/compositions/useConnectionFilters.js';
import useConnections from '@/compositions/useConnections.js';
import useReaction from '@/compositions/useReaction.js';
import { COMMAND_DISLIKE, COMMAND_LIKE } from '@/compositions/useMatching.js';
import useUser from '@/compositions/useUser.js';

const route = useRoute();
const router = useRouter();
const { state } = useAuth();
const filters = useConnectionFilters(route.params.type);
const connections = useConnections();
const { onPageFullyScrolled, offPageFullyScrolled } = usePageScroll();
const { user } = useUser();

const header = {
  [FOR_LATER]: user.main_type === USER_ROLE_STARTUP ? 'In review' : 'For later',
  [FUNDING_REQUESTS]: 'Funding requests',
  [CONNECTION_REQUESTS]: 'Connections requests',
  [MY_CONNECTION_REQUESTS]: 'My Actions: Connect Requests',
  [MY_FOR_LATER]: 'My Actions: Saved For later',
  [MY_PASS]: 'My actions: Pass',
}[route.params.type];

const users = ref([]);
const limit = 6;
let filterStopWatcher = null;
let thereIsNoMore = false;
let offset = 0;
let loadingDisabled = false;
let debounce = null;

const relevantButtons = ['Relevant', 'Not relevant'];
const userTypes = [
  { type: ALL, text: 'All' },
  { type: USER_ROLE_INVESTOR, text: 'Investors' },
  { type: USER_ROLE_STARTUP, text: 'Startups' },
  { type: USER_ROLE_ADVISOR, text: 'Advisors' },
].filter(() => {
  return user.role !== USER_ROLE_INVESTOR;
});

if (route.query.filter) {
  if (route.query.filter === 'relevant') {
    filters.state.relevant = true;
  } else if (route.query.filter === 'irrelevant') {
    filters.state.relevant = false;
  }
}

onMounted(async () => {
  onPageFullyScrolled(() => {
    !thereIsNoMore && loadConnections();
  });

  filterStopWatcher = watch(
    filters.state,
    () => {
      debounce && clearTimeout(debounce);

      debounce = setTimeout(() => {
        loadConnections(true);
      }, 500);
    },
    {
      immediate: true,
    },
  );
});

onUnmounted(() => {
  filterStopWatcher && filterStopWatcher();
  offPageFullyScrolled();
});

async function onReaction(user, type) {
  const { makeReaction } = useReaction(user.id);

  user.action = type;

  await makeReaction(type, true);
}

async function loadConnections(force = false) {
  filters.save();

  if (!loadingDisabled || force) {
    loadingDisabled = true;

    if (force) {
      offset = 0;
    } else {
      offset += limit;
    }

    try {
      const result = await connections.load(filters.state, offset, limit);

      if (!offset) {
        users.value = result.list;
      } else {
        users.value.push(...result.list);
      }

      thereIsNoMore = offset >= result.count && result.list.length === 0;
    } catch (e) {}

    loadingDisabled = false;
  }
}

function routeToProfile(user) {
  user.viewed = true;

  if (user.type === USER_ROLE_INVESTOR) {
    openInNewWindow({ name: 'ProfileInvest', params: { id: user.id } });
  } else if (user.type === USER_ROLE_STARTUP) {
    openInNewWindow({ name: 'ProfileStartup', params: { id: user.id } });
  } else if (user.type === USER_ROLE_ADVISOR) {
    openInNewWindow({ name: 'ProfileAdvisor', params: { id: user.id } });
  }
}

function openInNewWindow(routerData) {
  const routeData = router.resolve(routerData);

  window.open(routeData.href, '_blank');
}
</script>
