<template>
  <MainLayout :transparent-background="true">
    <MainContent class="px-7">
      <div class="">
        <!-- <img class="w-16" src="/src/assets/images/ggw-logo-blue.svg" alt="" /> -->
        <h1 class="font-bold text-xl leading-10">
          Log in to Go Global World
        </h1>
        <form class="mt-5" @submit="request">
          <div class="mb-2">
            <FormInput v-model="state.email" name="email" placeholder-label>
              Email
            </FormInput>
          </div>

          <FormInput
            v-model="state.password"
            type="password"
            name="passwd"
            placeholder-label
          >
            Password
          </FormInput>

          <router-link
            :to="{ name: 'ForgotPasswordScreen' }"
            class="inline-flex mt-2 font-medium"
          >
            Forgot Password?
          </router-link>

          <Btn
            class="mt-10"
            type="submit"
            :disabled="isProcessing"
            :is-loading="isProcessing"
          >
            Log in
          </Btn>
        </form>
        <p class="mt-4 text-center">
          Don't have an account?
          <a
            class="font-bold text-[#008AFC] cursor-pointer"
            @click="redirectToSignUp"
          >
            Sign up
          </a>
        </p>

        <GoogleSignInButton class="mt-6 flex justify-center" />
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';
import { object, string } from 'yup';
import Btn from '@/components/Btn.vue';
import FormInput from '@/components/FormInput.vue';
import useAuth from '@/compositions/useAuth';
import useRequest from '@/compositions/useRequest';
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';

import GoogleSignInButton from '@/components/GoogleSignInButton.vue';

import parseServerErrors from '@/utils/parseServerErrors';

const router = useRouter();
const route = useRoute();
const { state, signIn } = useAuth();

const emit = defineEmits([
  'switchToSignup',
  'switchToVerification',
  'complete-registration',
]);

const redirectToSignUp = () => {
  emit('switchToSignup');
};
const redirectToVerification = () => {
  emit('switchToVerification');
};

const completeRegistration = () => {
  console.log('testReg1');
  emit('complete-registration');
  console.log('testReg2');
};

const schema = object({
  email: string().email().required().label('Email'),
  passwd: string().required().label('Password'),
});

const { handleSubmit, setErrors } = useForm({ validationSchema: schema });
const { request, isProcessing } = useRequest(
  handleSubmit(async (values) => {
    try {
      const response = await signIn(values.email, values.passwd, null, null, {
        investor_id: route.query.investor_id,
      });

      if (response.code === 'code') {
        redirectToVerification();
      }

      completeRegistration();
    } catch (error) {
      setErrors(parseServerErrors(error.errors));
    }
  }),
);
</script>
