<template>
  <Combobox
    v-model="selectedValue"
    as="div"
    class="relative"
    :multiple="multiple"
  >
    <ComboboxInput
      class="input"
      :class="[{ '!border-red-600': meta.touched && errorMessage, filled: isFilled }, placeholderLabel ? 'pt-6' : 'pt-2']"
      :display-value="displayValue"
      :placeholder="placeholder"
      @change="queryValue = $event.target.value"
    />
    <label class="absolute top-4 left-3 pointer-events-none transition-all text-[#676C71]">
      <slot />
    </label>
    <ComboboxButton
      class="absolute inset-y-0 right-0 flex items-center pr-2"
    >
      <ChevronUpDownIcon
        class="h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
    </ComboboxButton>

    <TransitionRoot
      leave="transition ease-in duration-100"
      leave-from="opacity-100"
      leave-to="opacity-0"
      @after-leave="onAfterLeave"
    >
      <ComboboxOptions
        class="absolute bottom-[68px] mt-1 max-h-[10rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <div
          v-if="isEmptyState"
          class="relative cursor-default select-none py-2 px-4 text-gray-700"
        >
          Nothing found.
        </div>

        <ComboboxOption
          v-for="item in list"
          :key="item.id"
          v-slot="{ selected, active }"
          as="template"
          :value="item"
        >
          <li
            class="relative cursor-default select-none py-2 pl-10 pr-4"
            :class="{
              'bg-[#008AFC] text-white': active,
              'text-gray-900': !active,
            }"
          >
            <span
              class="block truncate"
              :class="{ 'font-medium': selected, 'font-normal': !selected }"
            >
              {{ item && item.name }}
            </span>
            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-3"
              :class="{ 'text-white': active, 'text-[#008AFC]': !active }"
            >
              <CheckIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </TransitionRoot>
  </Combobox>
  <div v-if="meta.touched && errorMessage" class="text-sm text-red-600 mb-2 text-center">
    {{ errorMessage }}
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { useField } from 'vee-validate';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  TransitionRoot,
} from '@headlessui/vue';

const props = defineProps({
  name: { type: String, required: true },
  query: { type: String, required: true },
  list: { type: Array, default: () => [] },
  placeholder: { type: String, default: '' },
  selected: { type: [Array, Object], default: () => null },
  multiple: Combobox.props.multiple,
  placeholderLabel: { type: Boolean, default: false },
});

const emit = defineEmits({
  'update:selected': null,
  'update:query': null,
});

const queryValue = computed({
  get() {
    return props.query;
  },
  set(val) {
    emit('update:query', val);
  },
});

const { value, errorMessage, meta } = useField(
  props.name,
  undefined,
  { initialValue: props.selected?.id || '' },
);

const selectedValue = computed({
  get() {
    return props.selected;
  },
  set(val) {
    value.value = props.multiple ? val.map(({ id }) => id) : val.id;
    emit('update:selected', val);
  },

});

const isEmptyState = computed(() => props.list.length === 0 && props.query !== '');
const isFilled = ref(false);

const displayValue = (item) => {
  if (item === undefined || item === null) {
    isFilled.value = false;
    return 'Loading...';
  }

  if (Array.isArray(item)) {
    isFilled.value = item.length > 0;
    return item.map(({ name }) => name).join(', ');
  }

  isFilled.value = Boolean(item.name);

  return item.name;
};

const onAfterLeave = () => {
  queryValue.value = '';
};

watch(selectedValue, (val) => {
  value.value = props.multiple ? val.map(({ id }) => id) : val.id;
});
</script>

<style scoped>

.input {
  @apply w-full text-base pb-2 px-3;
  @apply rounded border border-transparent bg-[#F3F5F8] text-[#292C2E] outline-none transition;
}

.input.filled, .input:focus {
  @apply border-[#676C71] bg-transparent text-[#292C2E];
}

.input.filled + label,
.input:focus + label {
  @apply text-sm top-2 leading-4;
}
</style>
