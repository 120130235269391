<template>
  <treeselect
    ref="node"
    v-model="value"
    :options="options"
    v-bind="$attrs"
    :class="{ disabled }"
    :default-expand-level="defaultExpandLevel"
    :limit="limit"
    :limit-text="limitText"
    :multiple="multiple"
    :placeholder="placeholder"
  />
  <div
    v-if="errorMessage && (validateOnValueUpdate || meta.touched)"
    class="text-sm text-red-600 mb-2 text-center"
  >
    {{ error || errorMessage }}
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useField } from 'vee-validate';
import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css';

const props = defineProps({
  modelValue: { type: Object, default: null },
  options: { type: Array, default: () => [] },
  name: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  groupedValue: { type: Array, default: () => [] },
  defaultExpandLevel: { type: Number, default: 0 },
  limit: { type: Number, default: Infinity },
  limitText: { type: Function, default: (count) => `${count}+` },
  multiple: { type: Boolean, default: false },
  validateOnValueUpdate: { type: Boolean, default: false },
  error: { type: String, default: '' },
  placeholder: { type: String, default: '' },
});

const emit = defineEmits({
  'update:model-value': null,
});

const {
  value: fieldValue,
  errorMessage,
  meta,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
  validateOnValueUpdate: props.validateOnValueUpdate,
});

const node = ref(null);

const value = computed({
  get() {
    if (props.multiple) {
      const result = props.modelValue || [];

      return buildValueWithoutChildren(
          Array.isArray(result) ? result : [result]
      );
    }

    if (Array.isArray(props.modelValue)) {
      return props.modelValue[0] || null;
    }

    return props.modelValue || null;
  },
  set(val) {
    let res = Array.isArray(val) ? val : [val].filter(Boolean);

    if (props.multiple && !res.includes('Global')) {
      res = buildValueWithChildren(res);
    }

    fieldValue.value = res;

    emit('update:model-value', res);
  },
});

function buildValueWithoutChildren(ids) {
  const shouldBeRemoved = [];

  for (const id of ids) {
    if (!shouldBeRemoved.includes(id)) {
      shouldBeRemoved.push(
          ...getChildren(id)
      );
    }
  }

  return ids.filter(id => !shouldBeRemoved.includes(id));
}

function buildValueWithChildren(ids) {
  const result = [];

  for (const id of ids) {
    result.push(...[id, ...getChildren(id)]);
  }

  return result;
}

function getChildren(id, options = props.options) {
  const children = [];

  for (const option of options) {
    if (children.length) {
      break;
    }

    if (option.id === id) {
      if (option.children?.length) {
        for (const childOption of option.children) {
          children.push(
              ...[childOption.id, ...getChildren(childOption.id, option.children)]
          );
        }
      }
    } else if (option.children?.length) {
      children.push(
          ...getChildren(id, option.children)
      );
    }
  }

  return children;
}
</script>

<style>
.vue-treeselect__control {
  @apply rounded bg-[#F3F5F8] py-4 px-3 border border-transparent;
}

.vue-treeselect svg {
  @apply mx-auto;
}

.vue-treeselect__single-value {
  @apply leading-6;
}

.vue-treeselect__placeholder {
  @apply text-[#676C71] text-base leading-[30px];
}

.vue-treeselect__input {
  @apply text-base;
}

.vue-treeselect__multi-value {
  @apply flex gap-1 flex-wrap;
}

.vue-treeselect__multi-value-item {
  @apply bg-white text-[#292C2E] text-sm py-1.5 px-2 rounded;
}

.vue-treeselect--has-value .vue-treeselect__multi-value {
  @apply p-0 mb-0;
}

.vue-treeselect__icon.vue-treeselect__value-remove {
  @apply text-[#292C2E];
}

.vue-treeselect__icon.vue-treeselect__value-remove svg {
  @apply w-2 h-2;
}

.vue-treeselect__limit-tip-text {
  @apply bg-[#008AFC] text-white px-[5px] py-[3px] m-0 rounded-[2px];
}

.disabled {
  @apply pointer-events-none;
}

.disabled .vue-treeselect__value-remove,
.disabled .vue-treeselect__x-container,
.disabled .vue-treeselect__control-arrow-container {
  @apply hidden;
}

.vue-treeselect__x-container {
  @apply text-[#292C2E];
}

.vue-treeselect__x-container svg {
  @apply w-3 h-3;
}

.disabled .vue-treeselect__control {
  @apply p-0 border-0 h-auto;
}

.disabled .vue-treeselect__multi-value {
  @apply m-0;
}

.disabled .vue-treeselect__multi-value-item-container {
  @apply pt-0;
}

.disabled .vue-treeselect__input-container {
  @apply hidden;
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused)
  .vue-treeselect__control:hover {
  @apply border-transparent;
}

.vue-treeselect--focused:not(.vue-vue-treeselect--open)
  .vue-treeselect__control {
  @apply border-transparent shadow-none;
}

.vue-treeselect--menu {
  @apply border-transparent;
}

.vue-treeselect-items-wrapper {
  @apply bg-red-600;
}

.vue-treeselect__checkbox--checked,
.vue-treeselect__checkbox--indeterminate,
.vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
.vue-treeselect__label-container:hover
  .vue-treeselect__checkbox--indeterminate {
  @apply border-[#008AFC] bg-[#008AFC];
}

.vue-treeselect__checkbox {
  @apply w-4 h-4;
}

.vue-treeselect__option {
  @apply flex items-center;
}

.vue-treeselect__option--hide {
  @apply hidden;
}

.vue-treeselect__option-arrow-container {
  @apply order-last;
}

.vue-treeselect__menu {
  @apply border-none;
}

.vue-treeselect__indent-level-1 {
  @apply border-l border-[#DFE4E8];
  margin-left: 15px;
}

.vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: 16px;
}

.vue-treeselect__indent-level-2 {
  @apply border-l border-[#DFE4E8];
  margin-left: 25px;
}

.vue-treeselect__indent-level-3 {
  @apply border-l border-[#DFE4E8];
  margin-left: 25px;
}

.vue-treeselect__indent-level-4 {
  @apply border-l border-[#DFE4E8];
  margin-left: 25px;
}

.vue-treeselect__check-mark,
.vue-treeselect__minus-mark {
  top: 3px;
  left: 3px;
}
</style>
