import { computed, reactive, ref, watch } from 'vue';
import DateEntity from '@/entities/DateEntity';
import useMessages from './useMessages';

const { dialogs: getDialogs, list: getList, send } = useMessages();

let dialogsIsLoaded = false;
const query = ref('');
const dialogs = ref([]);
const activeDialogId = ref(null);
const activeDialog = reactive({});
const isLoading = ref(false);

export class DialogProfile {
  constructor({
    id = '',
    userId = '',
    name = '',
    logo = '',
    avatar = '',
    unread = 0,
    firstName = '',
    lastName = '',
    role = null,
    position = null,
    isPersonal = false,
    isVerified = false,
    companyName = '',
    companyLogo = '',
    time = new Date(),
  }) {
    this.id = id;
    this.userId = userId;
    this.name = `${firstName} ${lastName}`;
    this.logo = avatar || logo;
    this.type = isPersonal ? '' : name;
    this.unread = unread;
    this.role = role;
    this.position = position;
    this.isVerified = isVerified;
    this.companyLogo = companyLogo;
    this.companyName = companyName;
    this.time = DateEntity.format(
      time,
      DateEntity.isToday(time)
        ? DateEntity.FORMAT_HH_MM_AMPM
        : DateEntity.FORMAT_MM_SLASH_DD_SLASH_YY,
    );
  }

  static fromJSON(json) {
    return new DialogProfile({
      id: json.id,
      userId: json.user_id,
      name: json.name,
      logo: json.logo,
      avatar: json.avatar,
      firstName: json.first_name,
      lastName: json.last_name,
      unread: json.unread,
      role: json.main_type,
      position: json.position,
      isPersonal: json.is_personal,
      isVerified: json.is_verified,
      companyName: json.company_name,
      companyLogo: json.company_logo,
      time: DateEntity.addTZOffset(json.time),
    });
  }

  read() {
    this.unread = 0;
  }
}
export const fetchDialogs = async () => {
  const dialogMap = await getDialogs();
  const scopedDialogs = Object.keys(dialogMap).map((dialogId) => {
    if (dialogMap[dialogId].profile !== null) {
      const { profile, unread, time } = dialogMap[dialogId]
      return DialogProfile.fromJSON({
        ...profile,
        unread,
        id: dialogId,
        time,
      });
    }
  });

  dialogs.value = scopedDialogs;
};

export const fetchUnreadDialogs = async () => {
  const params = { unread_only: true };
  const dialogMap = await getDialogs(params);
  const scopedDialogs = Object.keys(dialogMap).map((dialogId) => {
    if (dialogMap[dialogId].profile !== null) {
      const { profile, unread, time } = dialogMap[dialogId]
      return DialogProfile.fromJSON({
        ...profile,
        unread,
        id: dialogId,
        time,
      });
    }
  });

  dialogs.value = scopedDialogs;
};

export const updateActiveDialog = async (dialogId) => {
  const { profile, list } = await getList(dialogId);

  Object.assign(activeDialog, {
    profile: DialogProfile.fromJSON({ ...profile, id: dialogId }),
    list,
  });
};

const sendDialog = async (id, text) => {
  await send(id, text);

  const { list } = await getList(activeDialogId.value);

  Object.assign(activeDialog, {
    list,
  });
};

watch(activeDialogId, async (id) => {
  isLoading.value = true;
  const { profile, list } = await getList(id);

  Object.assign(activeDialog, {
    profile: DialogProfile.fromJSON({ ...profile, id }),
    list,
  });

  isLoading.value = false;
});

export default function useDialogs() {
  const dialogList = computed(() => {
    const list = dialogs.value;
    if (query.value === '') {
      return list;
    }

    return list.filter(({ name, companyName }) => {
      const value = query.value.toLowerCase();

      return (
        name.toLowerCase().includes(value) ||
        companyName.toLowerCase().includes(value)
      );
    });
  });

  if (!dialogsIsLoaded) {
    fetchDialogs();
    dialogsIsLoaded = true;
  }

  return {
    query,
    dialogs,
    dialogList,
    activeDialogId,
    activeDialog,
    isLoading,
    sendDialog,
    fetchDialogs,
    fetchUnreadDialogs,
    updateActiveDialog,
  };
}
