const dfs = (node, callback) => {
  if (!node) return;

  callback(node);

  if (node.children) {
    for (let i = 0; i < node.children.length; i += 1) {
      dfs(node.children[i], callback);
    }
  }
};

export default dfs;
