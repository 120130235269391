<template>
  <EditableBlock @edit="onEdit">
    <div
      class="rounded w-14 h-14 lg:w-[88px] lg:h-[88px] mx-auto flex justify-center items-center overflow-hidden"
    >
      <img
        v-if="user.avatar"
        :src="user.avatar"
        alt="user avatar"
        class="w-full h-full object-center object-cover"
      >
      <EmptyDiv v-else class="w-14 h-14 lg:w-[88px] lg:h-[88px] flex-none">
        <img class="lg:w-1/3" src="/src/assets/images/profile/camera-icon.svg" alt="user avatar stub">
      </EmptyDiv>
    </div>
    <h1
      class="mt-2 md:mt-4 text-2xl text-[#292C2E] text-center font-bold"
    >
      {{ user.firstname }} {{ user.lastname }}
    </h1>
  </EditableBlock>
</template>

<script setup>
import EditableBlock from '@/components/blocks/EditableBlock.vue';
import useEditableBlock from '@/compositions/useEditableBlock';
import useUser from '@/compositions/useUser';
import { BLOCK_ID_PERSONAL_INFORMATION, BLOCK_INDEX_PERSONAL } from '@/constants';
import EmptyDiv from '@/screens/update/components/EmptyDiv.vue';

const { user } = useUser();
const { onEdit } = useEditableBlock(BLOCK_INDEX_PERSONAL);

</script>
