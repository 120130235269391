import { ref } from 'vue';

export default function useRequest(callback) {
  const isProcessing = ref(false);

  let fn = callback;

  function request(...props) {
    return new Promise((resolve, reject) => {
      isProcessing.value = true;

      fn(...props)
        .then((data) => {
          isProcessing.value = false;

          resolve(data);
        })
        .catch(reject)
        .finally(() => {
          isProcessing.value = false;
        });
    });
  }

  function createRequest(func) {
    fn = func;
    return request;
  }

  return {
    isProcessing,

    request,
    createRequest,
  };
}
