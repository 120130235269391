<template>
  <div>
    <PlanTableMobile
      v-if="rates.length"
      class="lg:hidden block"
      :rates-list="ratesList"
      :info="info"
      :user-role="userRole"
      :has-tariff="hasTariff"
      :tabs="tabs"
      @manage="$emit('manage')"
      @subscribeWithTrial="$emit('subscribeWithTrial', $event)"
      @subscribeWithoutTrial="$emit('subscribeWithoutTrial', $event)"
    />

    <div
      class="isolate hidden lg:block rounded-xl bg-white border border-[#DFE4E8] overflow-hidden"
    >
      <div class="relative">
        <div
          class="absolute inset-x-0 inset-y-0 -z-10 flex"
        >
          <div
            class="flex"
            :class="[rates.length === 3 ? 'w-1/4' : 'w-1/5']"
            aria-hidden="true"
            :style="{
              marginLeft: `${
                rates.length === 3
                  ? ((rates.length - 1) * 25)
                  : ((rates.length - 2) * 30)
              }%`,
            }"
          >
            <div class="w-full rounded-xl border-2 border-[#008AFC]" />
          </div>
        </div>
        <table class="w-full table-fixed text-left">
          <caption class="sr-only"> Pricing plan comparison </caption>
          <colgroup>
            <col v-for="rate of rates" :class="[rates.length === 3 ? 'w-1/4' : 'w-1/5']" />
          </colgroup>
          <thead>
            <tr>
              <td class="border-r border-[#DFE4E8]" />
              <th
                v-for="(rate, index) in rates"
                :key="index"
                scope="col"
                class="p-4 pb-1"
              >
                <div v-if="rate.isPremium" class="text-sm font-bold uppercase text-[#D88200] text-center">{{ rate.title }}</div>
                <div
                  v-else
                  class="text-sm font-bold uppercase text-[#292C2E] flex justify-between items-center"
                >
                  {{ rate.title }}

                  <span
                    v-if="index === rates.length - 2"
                    class="text-white text-sm leading-4 px-1.5 py-1 uppercase bg-[#008AFC] rounded"
                  >
                    Recommended
                  </span>
                  <span v-else-if="rate.safePercent" class="text-white text-sm leading-4 px-1.5 py-1 uppercase bg-[#149911] rounded"> {{ rate.safePercent }}%</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                scope="row"
                class="border-r border-[#DFE4E8] px-4 space-y-4 align-top"
              >
                <PlanTabs :tabs="tabs" :active="selected" @change="selected = $event" />
              </th>
              <td v-for="(rate, index) in rates" :key="index" class="px-4 pb-4">
                <div class="items-baseline gap-x-1 text-gray-900 text-center">
                  <template v-if="rate.isPremium">
                    <span class="text-xl font-bold text-[#292C2E]">Let’s talk</span>
                  </template>
                  <template v-else-if="rate.priceId === 'free'">
                    <span class="text-xl font-bold text-[#292C2E]">Free</span>
                  </template>
                  <template v-else>
                    <span class="text-xl font-bold text-[#292C2E]">{{ rate.priceMonth }}</span>
                    <span class="text-xl font-bold text-[#292C2E]"> /month</span>
                  </template>
                </div>
                <div v-if="rate.isPremium && selected === 1" class="text-[#676C71] mt-1 text-center">Personal billing</div>
                <template v-else>
                  <div v-html="rate.priceOld" class="text-[#676C71] mt-1 text-center"></div>
                </template>
                <div class="mt-4 text-center items-center gap-4">
                  <Btn
                    v-if="rate.isPremium"
                    as="a"
                    href="mailto:info@goglobal.world"
                    size="xs"
                    theme="black"
                    class="!inline-block max-w-max"
                  >
                    Contact us
                  </Btn>
                  <template v-else-if="hasTariff">
                    <span
                        class="flex max-w-max mx-auto p-3 gap-1.5 text-sm leading-4 text-[#292C2E] font-bold rounded cursor-pointer"
                        @click="$emit('manage')"
                        :class="
                          index === rates.length - 2
                            ? 'bg-[#008AFC] text-white'
                            : 'border border-[#292C2E]'
                        "
                    >
                      Manage
                    </span>
                  </template>
                  <template v-else-if="isFreeTariff && rate.priceId === 'free'">
                    <span class="inline-flex mt-2 text-base font-bold text-[#292C2E]">Your current plan</span>
                  </template>
                  <template v-else>
                    <Btn
                        size="xs"
                        class="!inline-block max-w-max"
                        @click="$emit('subscribeWithTrial', rate)"
                        :class="[
                          index === rates.length - 2
                            ? 'bg-[#008AFC] text-white'
                            : 'border border-[#292C2E] !bg-white !text-[#292C2E]',
                        ]"
                    >
                      {{ rate.priceId !== 'free' ? 'Try free for 3 days' : 'Get started' }}
                    </Btn>
                    <span
                        v-if="rate.priceId !== 'free'"
                        class="inline-block text-[] underline text-sm font-bold text-[#292C2E] flex-none cursor-pointer"
                        @click="$emit('subscribeWithoutTrial', rate)"
                    >
                      or buy now
                    </span>
                  </template>
                </div>
              </td>
            </tr>
            <tr v-for="(section, sectionIdx) in info[userRole]" :key="sectionIdx">
              <th
                scope="row"
                class="py-3 px-4 text-base font-medium border-r border-[#DFE4E8]"
              >
                <div class="absolute inset-x-0 -mt-3 h-px bg-[#DFE4E8] -z-20" />
                {{ section.name }}
                <p
                  v-if="section.label"
                  class="mt-1 text-sm text-[#676C71] font-normal"
                >
                  {{ section.label }}
                </p>
              </th>
              <td v-for="(rate, index) in rates" :key="index" class="py-3 px-4">
                <div
                  v-if="typeof section.tiers[rate.type] === 'string'"
                  class="text-center text-base font-medium"
                >
                  <div v-html="section.tiers[rate.type]" />
                </div>
                <template v-else>
                  <img
                    v-if="section.tiers[rate.type] === true"
                    class="mx-auto w-4"
                    src="/src/assets/images/icons/check-blue.svg"
                    alt=""
                  />
                  <span class="sr-only">{{
                      section.tiers[rate.type] === true
                        ? 'Included'
                        : 'Not included'
                    }}
                    in {{ rate.title }}</span>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import Btn from '@/components/Btn.vue';
import PlanTabs from '@/screens/tariffs-plans/components/PlanTabs.vue';
import PlanTableMobile from '@/screens/tariffs-plans/components/PlanTableMobile.vue';
import { computed, ref } from "vue";
import { USER_ROLE_ADVISOR, USER_ROLE_INVESTOR, USER_ROLE_STARTUP } from "@/constants.js";
import {
  TYPE_BUSINESS_M,
  TYPE_BUSINESS_Y,
  TYPE_INVESTOR_M,
  TYPE_INVESTOR_Y, TYPE_MVP_M, TYPE_MVP_Y,
  TYPE_PREMIUM,
  TYPE_SCOUT_M,
  TYPE_SCOUT_Y, TYPE_SEED_M, TYPE_SEED_Y, TYPE_SERIES_M, TYPE_SERIES_Y, TYPE_STARTER_M, TYPE_STARTER_Y
} from "@/compositions/usePayments.js";
import useUser from "@/compositions/useUser.js";

const props = defineProps({
  ratesList: { type: Array, default: [] },
  userRole: { type: String },
  hasTariff: { type: Boolean, default: false },
  tabs: { type: Array, default: [] }
});

defineEmits({
  manage: null,
  subscribeWithTrial: null,
  subscribeWithoutTrial: null,
});

const selected = ref(1);

const rates = computed(() => props.ratesList[selected.value ? 0 : 1]);

const { user } = useUser();
const isFreeTariff = computed(
    () => [TYPE_MVP_M, TYPE_MVP_Y, TYPE_SCOUT_M, TYPE_SCOUT_Y].includes(user.tariff)
);

const info = {
  [USER_ROLE_INVESTOR]: [
    {
      name: 'Set up your investor profile',
      label: 'To make it publicly available to our network and share it with the world beyond GGW via a branded personal link',
      tiers: {
        [TYPE_SCOUT_Y]: true,
        [TYPE_SCOUT_M]: true,
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Direct messaging with your matches',
      tiers: {
        [TYPE_SCOUT_Y]: true,
        [TYPE_SCOUT_M]: true,
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Deal flow analytics dashboard',
      tiers: {
        [TYPE_SCOUT_Y]: true,
        [TYPE_SCOUT_M]: true,
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Follow startups',
      label: 'who sparked your interest and get notified about updates only on relevant deals',
      tiers: {
        [TYPE_SCOUT_Y]: true,
        [TYPE_SCOUT_M]: true,
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'GGW member directory:',
      label: 'investors, startups, and advisors. You can manually browse and expand your network',
      tiers: {
        [TYPE_SCOUT_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SCOUT_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Curated matching',
      label: 'with startups based on the information provided in your profile',
      tiers: {
        [TYPE_SCOUT_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SCOUT_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Additional 5000+ network',
      label: 'of verified investors outside the GGW platform for you to connect with and coinvest',
      tiers: {
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Apply for a verified badge',
      label: 'to boost your profile’s credibility',
      tiers: {
        [TYPE_SCOUT_Y]: '$100 one-time fee',
        [TYPE_SCOUT_M]: '$100 one-time fee',
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our community blog',
      tiers: {
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our monthly newsletter',
      tiers: {
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: '10% Discount for GGW services:',
      label: 'Incorporation, legal, accounting, sales, taxes, and other advisory help',
      tiers: {
        [TYPE_INVESTOR_Y]: true,
        [TYPE_INVESTOR_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Personal manager',
      label: 'who tailors your profile to your needs',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Individual approach:',
      label: 'we can provide help by handpicking matches based on your personal preference and assist you in handling deal flow',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
  ],
  [USER_ROLE_STARTUP]: [
    {
      name: 'Set up your startup profile',
      label: 'to make it publicly available to your network on the platform and share it with the world beyond GGW via a startup branded link',
      tiers: {
        [TYPE_MVP_Y]: true,
        [TYPE_MVP_M]: true,
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Direct messaging with your connections',
      tiers: {
        [TYPE_MVP_Y]: true,
        [TYPE_MVP_M]: true,
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Basic profile analytics',
      tiers: {
        [TYPE_MVP_Y]: true,
        [TYPE_MVP_M]: true,
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'View our MBA video course library',
      tiers: {
        [TYPE_MVP_Y]: true,
        [TYPE_MVP_M]: true,
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Perks from our partners:',
      label: 'get special offers from business platforms like Clerky, Hubspot, and others',
      tiers: {
        [TYPE_SEED_Y]: 'up to $150K in perks',
        [TYPE_SEED_M]: 'up to $150K in perks',
        [TYPE_SERIES_Y]: 'up to $500K in perks',
        [TYPE_SERIES_M]: 'up to $500K in perks',
        [TYPE_PREMIUM]: 'up to $500K in perks',
      }
    },
    {
      name: 'Advanced analytics dashboard',
      tiers: {
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our community blog',
      tiers: {
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Access founder resources:',
      label: 'we have almost everything from pitch deck structure guidelines to legal templates, industry reports, equity calculator, cap table builder, etc.',
      tiers: {
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Attend webinars of licensed US tax & legal advisors',
      tiers: {
        [TYPE_SEED_Y]: true,
        [TYPE_SEED_M]: true,
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'GGW member directory:',
      label: 'investors, startups, and advisors. You can manually browse and expand your network',
      tiers: {
        [TYPE_SEED_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SEED_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Curated matchmaking',
      label: 'with investors and advisors* based on the criteria provided in your profile',
      tiers: {
        [TYPE_SEED_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SEED_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Additional 5000+ network',
      label: 'of verified investors outside GGW platform for you to make cold pitches',
      tiers: {
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Apply for a verified badge to boost your profile’s credibility',
      tiers: {
        [TYPE_MVP_Y]: '$100 one-time fee',
        [TYPE_MVP_M]: '$100 one-time fee',
        [TYPE_SEED_Y]: '$100 one-time fee',
        [TYPE_SEED_M]: '$100 one-time fee',
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our monthly newsletter',
      tiers: {
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: false,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: '10% Discount for GGW services:',
      label: 'Incorporation, legal, accounting, sales, taxes, and other advisory help',
      tiers: {
        [TYPE_SERIES_Y]: true,
        [TYPE_SERIES_M]: false,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Personal manager',
      label: 'who tailors your profile to your needs',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Individual approach:',
      label: 'we can provide help by handpicking matches based on your personal preference and assist you in finding better fundraising opportunities',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
  ],
  [USER_ROLE_ADVISOR]: [
    {
      name: 'Set up your advisor profile',
      label: 'to make it publicly available to our network and share it with the world beyond GGW via a branded personal link',
      tiers: {
        [TYPE_STARTER_Y]: true,
        [TYPE_STARTER_M]: true,
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Direct messaging with your matches',
      tiers: {
        [TYPE_STARTER_Y]: true,
        [TYPE_STARTER_M]: true,
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Advanced analytics dashboard',
      tiers: {
        [TYPE_STARTER_Y]: true,
        [TYPE_STARTER_M]: true,
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'GGW member directory:',
      label: 'investors, startups, and advisors. You can manually browse and expand your network',
      tiers: {
        [TYPE_STARTER_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_STARTER_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Curated matching',
      label: 'with startups based on the information provided in your profile',
      tiers: {
        [TYPE_STARTER_Y]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_STARTER_M]: 'Limited <span class="mt-1 text-sm font-normal text-[#676C71] block">to 10 connects or saves per day</span>',
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Apply for a verified badge to boost your profile’s credibility',
      tiers: {
        [TYPE_STARTER_Y]: '$99 one-time fee',
        [TYPE_STARTER_M]: '$99 one-time fee',
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our community blog',
      tiers: {
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'A feature on our monthly newsletter',
      tiers: {
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: '10% Discount for GGW services:',
      label: 'Incorporation, legal, accounting, sales, taxes, and other advisory help',
      tiers: {
        [TYPE_BUSINESS_Y]: true,
        [TYPE_BUSINESS_M]: true,
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Personal manager',
      label: 'who tailors your profile to your needs',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
    {
      name: 'Individual approach:',
      label: 'we can provide help by handpicking matches based on your personal preference and assist you in handling them',
      tiers: {
        [TYPE_PREMIUM]: true,
      }
    },
  ],
};
</script>
