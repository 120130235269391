import {
  CONNECTION_REQUESTS,
  FOR_LATER,
  FUNDING_REQUESTS,
  MY_CONNECTION_REQUESTS,
  MY_FOR_LATER,
  MY_FUNDING_REQUESTS,
  MY_PASS,
} from '@/compositions/useConnectionFilters.js';

export default function (to, form, next) {
  if (
    [
      CONNECTION_REQUESTS,
      FUNDING_REQUESTS,
      FOR_LATER,
      MY_CONNECTION_REQUESTS,
      MY_PASS,
      MY_FOR_LATER,
      MY_FUNDING_REQUESTS,
    ].includes(to.params.type)
  ) {
    next();
  } else {
    next({ name: 'WelcomeScreen' });
  }
}
