<template>
  <div class="flex gap-2">
    <a target="_blank" href="https://app.goglobal.world/?utm_source=profile-id-1" class="py-3 px-4 flex-none flex items-center justify-center bg-[#FFAB49] rounded">
      <span class="text-base text-[#292C2E] font-bold">Pass</span>
    </a>
    <a target="_blank" href="https://app.goglobal.world/?utm_source=profile-id-1" class="w-full rounded bg-[#F3F5F8] flex items-center justify-center gap-2">
      <img src="/src/assets/images/profile-desktop/hourglass-icon.svg" alt="">
      For later
    </a>
    <a target="_blank" href="https://app.goglobal.world/?utm_source=profile-id-1" class="py-3 px-4 flex-none flex items-center justify-center bg-[#CEFF00] rounded">
      <span class="text-base text-[#292C2E] font-bold">Connect</span>
    </a>
  </div>
</template>
