<template>
  <MainLayout
    class="sm:bg-[url('/src/assets/images/bg-welcome-desktop.jpg')] bg-cover"
    :style="'height:' + vh + 'px'"
  >
    <MainContent class="bg-transparent">
      <div
        class="py-6 flex-1 flex justify-center flex-col px-4 bg-[url('/src/assets/images/bg-welcome.jpg')] sm:bg-none"
      >
        <img
          class="w-[160px]"
          src="/src/assets/images/welcome-logo.png"
          alt=""
        />
        <div class="my-auto text-[40px] leading-[48px] font-bold text-center text-white bg-center">
          <p class="flex justify-center">
            We help
            <span class="w-[158px]">
              <span
                class="italic font-alternative font-bold block ml-1.5"
                :class="{ 'animation-text': isAnimation }"
              >
                {{ firstLabel }}
              </span>
            </span>
          </p>
          <p class="flex justify-center">
            find
            <span class="w-[158px]">
              <span
                class="italic font-alternative font-bold block ml-1.5"
                :class="{ 'animation-text': isAnimation }"
              >
                {{ secondLabel }}
              </span>
            </span>
          </p>
        </div>
        <div>
          <Btn class="w-full" @click="routeToSignUp()">
            Sign Up with Email
          </Btn>

          <GoogleSignInButton
            class="mt-2"
            button-text="continue_with"
            #="{click}"
          >
            <Btn theme="white" @click="click">
              <span class="block h-5 w-5 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  class="LgbsSe-Bz112c"
                ><g>
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                  <path fill="none" d="M0 0h48v48H0z" />
                </g>
                </svg>
              </span>

              Continue with Google
            </Btn>
          </GoogleSignInButton>
          <Btn class="mt-2" theme="white" @click="routeToSignIn()">
            Log in with Email
          </Btn>
        </div>
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Btn from '../../components/Btn.vue';
import MainLayout from '../../layouts/MainLayout.vue';
import MainContent from '../../layouts/MainContent.vue';
import GoogleSignInButton from '../../components/GoogleSignInButton.vue';

const vh = document.documentElement.scrollHeight;

const router = useRouter();
const isAnimation = ref(true);
const routeToSignUp = async () => {
  await router.push({ path: '/sign-up' });
};

const routeToSignIn = async () => {
  await router.push({ path: '/login' });
};

const firstLabels = ['Investors', 'Investors', 'Startups', 'Startups'];
const secondLabels = ['Startups', 'Investors', 'Advisors', 'Investors'];

let i = 1;
const firstLabel = ref(firstLabels[0]);
const secondLabel = ref(secondLabels[0]);

const intervalAnimation = () => {
  setInterval(() => {
    isAnimation.value = false;
    firstLabel.value = firstLabels[i];
    secondLabel.value = secondLabels[i];
    i++;
    if (i > 3) {
      i = 0;
    }
    isAnimation.value = true;
  }, 5000);
};

onMounted(async () => {
  intervalAnimation();
});

onUnmounted(async () => {
  clearInterval(intervalAnimation);
});

</script>

<style scoped>
/* Animation */
.animation-text {
  border-right: solid 1px white;
  white-space: nowrap;
  overflow: hidden;
  width: 0px;
  max-width: max-content;

  animation: animated-text 5s steps(29,end) infinite,
  animated-cursor 600ms steps(29,end) infinite;
  transition-timing-function: ease-out;
}

/* text animation */

@keyframes animated-text{
  25% { width: 0; }
  50% { width: 100%; }
  75% { width: 100%; }
  100% { width: 0px; }
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: white;}
  to{border-right-color: transparent;}
}
</style>
