export const API_HOST = import.meta.env.VITE_API_HOST;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const GOOGLE_CLIENT_ID =
  '479097907086-klq4adub75jrvv09bt3va6tb96lfsu91.apps.googleusercontent.com';
export const API_AUTH = `${API_HOST}/auth/`;
export const API_USER = `${API_HOST}/user/`;
export const API_COUNTRIES = `${API_HOST}/countries/`;

export const USER_ROLE_STARTUP = 'startup';
export const USER_ROLE_ADVISOR = 'advisor';
export const USER_ROLE_INVESTOR = 'investor';

export const USER_TARIFF_MVP_M = 'MVP_M';
export const USER_TARIFF_MVP_Y = 'MVP_Y';
export const USER_TARIFF_SEED_M = 'SEED_M';
export const USER_TARIFF_SEED_Y = 'SEED_Y';
export const USER_TARIFF_SERIES_M = 'SERIES_M';
export const USER_TARIFF_SERIES_Y = 'SERIES_Y';

export const USER_TARIFF_TO_NAME = {
  [USER_TARIFF_MVP_M]: 'MVP',
  [USER_TARIFF_MVP_Y]: 'MVP',
  [USER_TARIFF_SEED_M]: 'Seed',
  [USER_TARIFF_SEED_Y]: 'Seed',
  [USER_TARIFF_SERIES_M]: 'Series А+',
  [USER_TARIFF_SERIES_Y]: 'Series А+',
};

export const PERIOD_ANNUAL = 'annual';
export const PERIOD_MONTHLY = 'monthly';

export const AVAILABLE_TO_MATCH = {
  [USER_ROLE_STARTUP]: [USER_ROLE_INVESTOR, USER_ROLE_ADVISOR],
  [USER_ROLE_INVESTOR]: [USER_ROLE_INVESTOR, USER_ROLE_STARTUP],
  [USER_ROLE_ADVISOR]: [USER_ROLE_STARTUP],
};

export const DEFAULT_ROLE_FOR_MATCHING = {
  [USER_ROLE_INVESTOR]: USER_ROLE_STARTUP,
  [USER_ROLE_STARTUP]: USER_ROLE_INVESTOR,
  [USER_ROLE_ADVISOR]: USER_ROLE_STARTUP,
};

export const KEY_SUCCESS_FEE = 'is_success_fee';
export const KEY_FREE_ADV = 'is_free_adv';
export const KEY_ADV_FOR_EQUITY = 'is_adv_for_equity';
export const KEY_ADV_FOR_MONEY = 'is_adv_for_money';
export const KEY_ACCREDITED = 'is_accredited';
export const KET_LEAD = 'is_lead';
export const KEY_FOLLOW = 'is_follow';

export const KEY_TO_LABEL = {
  [KEY_SUCCESS_FEE]: 'I work on Success Fee',
  [KEY_FREE_ADV]: 'I do free advising',
  [KEY_ADV_FOR_EQUITY]: 'I do advising for equity',
  [KEY_ADV_FOR_MONEY]: 'I charge money for advising',
  [KEY_ACCREDITED]: 'Accredited Investor',
  [KET_LEAD]: 'Lead Investor',
  [KEY_FOLLOW]: 'Follow Investor',
};

export const REVENUE_OPTIONS = [
  { id: -1, name: 'Pre-Revenue' },
  { id: 1, name: "0 - $1'000" },
  { id: 2, name: "$1'000 - $10'000" },
  { id: 3, name: "$10'001 - $100'000" },
  { id: 4, name: "$100'001 - $1'000'000" },
  { id: 5, name: "$1'000'001 - $5'000'000" },
  { id: 6, name: "$5'000'001+" },
];

export const BLOCK_ID_STARTUP_PROFILE = 4;
export const BLOCK_ID_ELEVATOR_PITCH = 5;
export const BLOCK_ID_CUSTOMERS_METRICS = 6;
export const BLOCK_ID_FOUNDERS = 7;
export const BLOCK_ID_SALES_METRICS = 8;
export const BLOCK_ID_FUNDRAISING = 9;
export const BLOCK_ID_OFFICE = 10;
export const BLOCK_ID_PERSONAL_INFORMATION = 14;
export const BLOCK_ID_GOALS = 15;
export const BLOCK_ID_SEARCH_CRITERIA = 16;

export const BLOCK_INDEX_PERSONAL = 'personal';
export const BLOCK_INDEX_FOUNDERS = 'founders';
export const BLOCK_INDEX_USER = 'user';
export const BLOCK_INDEX_EXPERIENCE = 'experience';
export const BLOCK_INDEX_LP_SYNDICATE = 'lpsyndicate';
export const BLOCK_INDEX_INVESTMENTS = 'investments';
export const BLOCK_INDEX_CRITERIA = 'criteria';

export const BLOCK_INDEX_COMPANY = 'company';

export const BLOCK_ID_TO_BLOCK_INDEX_MAP = {
  [BLOCK_ID_STARTUP_PROFILE]: 'company',
  [BLOCK_ID_ELEVATOR_PITCH]: 'pitch',
  [BLOCK_ID_CUSTOMERS_METRICS]: 'users',
  [BLOCK_ID_FOUNDERS]: 'founders',
  [BLOCK_ID_SALES_METRICS]: 'sales',
  [BLOCK_ID_FUNDRAISING]: 'fundraising',
  [BLOCK_ID_OFFICE]: 'office',
  [BLOCK_ID_PERSONAL_INFORMATION]: 'personal',
  [BLOCK_ID_SEARCH_CRITERIA]: 'criteria',
  [BLOCK_ID_GOALS]: 'goals',
};

export const USER_ROLE_TO_ROUTE_NAME = {
  [USER_ROLE_ADVISOR]: 'ProfileAdvisor',
  [USER_ROLE_INVESTOR]: 'ProfileInvest',
  [USER_ROLE_STARTUP]: 'ProfileStartup',
};

export const ERROR_HAS_NOT_TARIFF = 'ERROR_HAS_NOT_TARIFF';
export const ERROR_NEED_UPGRATE_TARIFF = 'ERROR_NEED_UPGRATE_TARIFF';
