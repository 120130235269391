import { createRouter, createWebHistory } from 'vue-router';
import { authentication, onBoarding, profile } from '@/router/modules';

import {
  beforeEach,
  adminModeMiddleware,
  authMiddleware,
  userRoleMiddleware,
  referralMiddleware,
  connectionsMiddleware,
  beforeResolve,
  loadDialogs,
  matchMiddleware
} from '@/router/middlewares';

import Legal from './screens/startup-profile/Legal.vue';
import ExternalResource from './screens/startup-profile/ExternalResource.vue';
import PaymentEnd from './screens/startup-profile/PaymentEnd.vue';
import HelpSigning from './screens/update/HelpSigning.vue';
import ProfileCompleted from './screens/update/ProfileCompleted.vue';
import ProfileGoGlobal from './screens/profile-go-global-world-static/ProfileDesktop.vue';
import ProfileEditDesktop from './screens/update/ProfileEditDesktop.vue';
import Connections from './screens/connections/Connections.vue';
import Events from './screens/events/Events.vue';
import Event from './screens/events/Event.vue';
import MyConnections from './screens/connections/MyConnections.vue';
import ConnectionsRequests from './screens/connections/ConnectionsRequests.vue';
import Plans from './screens/tariffs-plans/Plans.vue';

const routes = [
  ...authentication,
  ...onBoarding,
  ...profile,
  {
    path: '/messages',
    component: () => import('./screens/messages/Messages.vue'),
    name: 'MessagesScreen',
    children: [
      {
        name: 'DialogScreen',
        path: 'dialog/:id',
        component: () =>
          import('./screens/messages/components/ChatWithUser.vue'),
      },
    ],
    meta: {
      middlewares: [authMiddleware(), userRoleMiddleware, loadDialogs],
    },
  },
  {
    component: Legal,
    path: '/legal',
  },
  {
    name: 'UpdatingPlanScreen',
    component: Plans,
    path: '/updating-plan',
    meta: {
      middlewares: [authMiddleware()],
    },
  },
  {
    name: 'ExternalResourceScreen',
    component: ExternalResource,
    path: '/external-resource',
  },
  {
    name: 'ProfileGoGlobal',
    component: ProfileGoGlobal,
    path: '/profile/goglobal',
  },
  {
    name: 'HelpSigning',
    component: HelpSigning,
    path: '/help-signing',
  },
  {
    name: 'ProfileCompleted',
    component: ProfileCompleted,
    path: '/profile-completed',
  },
  {
    name: 'ProfileEditDesktop',
    component: ProfileEditDesktop,
    path: '/profile-edit-desktop',
  },
  {
    name: 'Events',
    component: Events,
    path: '/events',
  },
  {
    name: 'Event',
    component: Event,
    path: '/event/:id',
  },
  {
    name: 'MyConnections',
    component: MyConnections,
    path: '/my-connections',
    meta: {
      middlewares: [
        authMiddleware(),
        userRoleMiddleware
      ]
    },
  },
  {
    name: 'ConnectionsRequests',
    component: ConnectionsRequests,
    path: '/list/:type',
    meta: {
      middlewares: [
        authMiddleware(),
        userRoleMiddleware,
        connectionsMiddleware
      ]
    },
  },
  {
    name: 'Connections',
    component: Connections,
    path: '/members',
    meta: { middlewares: [authMiddleware()] },
  },
  {
    path: '/payment',
    children: [
      {
        name: 'PaymentEndScreen',
        path: ':status',
        component: PaymentEnd,
      },
    ],
  },

  {
    path: '/match',
    name: 'Match',
    component: null,
    children: [
      {
        name: 'MatchWith',
        path: '',
        component: () => import('./screens/match/Match.vue'),
      },
    ],
    meta: {
      middlewares: [
        authMiddleware(),
        userRoleMiddleware,
        referralMiddleware,
        matchMiddleware
      ],
    },
  },
  {
    name: 'GGWAdminScreen',
    path: '/ggw-admin',
    component: null,
    meta: {
      middlewares: [adminModeMiddleware],
    },
  },
  {
    name: 'PagesScreen',
    path: '/pages/:page',
    component: () => import('./screens/PagesScreen.vue'),
  },
  {
    name: 'TestScreen',
    path: '/test',
    component: () => import('./screens/TestScreen.vue'),
    meta: { middlewares: [authMiddleware()] },
  },
  {
    path: '/block',
    children: [
      {
        name: 'EditBlockScreen',
        path: ':id/edit',
        component: () => import('./screens/EditBlockScreen.vue'),
      },
      {
        name: 'OnBoardingBlockScreen',
        path: 'on-boarding',
        component: () => import('./screens/OnBoardingBlockScreen.vue'),
      },
    ],
    meta: {
      middlewares: [authMiddleware()],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEach);
router.beforeResolve(beforeResolve);

export default router;
