<template>
  <Btn v-if="loggedIn && !hasTariff && eventStriction !== ANYONE" @click="emit('setTariff')">Start Free Trial</Btn>
  <Btn v-else-if="loggedIn && hasMvpTariff && eventStriction === MEMBERS_ONLY" @click="emit('setTariff')">Upgrade Subscription</Btn>
  <Btn v-else @click="loggedIn || eventStriction === ANYONE ? emit('register') : emit('signUp')">Register</Btn>
</template>

<script setup>
import useUser from "@/compositions/useUser.js";
import Btn from '@/components/Btn.vue';
import { USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y } from '@/constants';
import { computed } from "vue";
import useAuth from "@/compositions/useAuth.js";

defineProps({
  eventStriction: { type: Number }
});

const emit = defineEmits({
  register: null,
  setTariff: null,
  signUp: null,
});

const
    ANYONE = '1',
    SUBSCRIBED_ONLY = '2',
    MEMBERS_ONLY = '3';

const { state } = useAuth();
const { user } = useUser();

const loggedIn = computed(() => state.isAuthenticated);
const hasTariff = computed(() => user.tariff);
const hasMvpTariff = computed(() => [USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y].includes(user.tariff));
</script>
