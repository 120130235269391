<template>
  <div>
    <div class="md:hidden h-5">
      <button @click="openMenuModal">
        <img src="/src/assets/images/ellipsis-vertical-icon.svg" alt="">
      </button>
      <Modal ref="menuModal" is-new-style>
        <div class="py-4">
          <ul class="mt-4 divide-y divide-[#DFE4E8] border-b border-[#DFE4E8]">
            <li v-for="(item, index) in menuLinks" :key="index">
              <button class="py-3 w-full flex justify-between items-center text-base text-[#292C2E]">
                {{ item.title }}
                <img class="w-6" :src="item.img" alt="">
              </button>
            </li>
          </ul>

          <button class="mt-4 py-3 w-full flex justify-between items-center text-base text-[#FC0000]">
            Delete profile
            <img src="/src/assets/images/trash-icon.svg" alt="">
          </button>
        </div>
      </Modal>
    </div>

    <Popover class="relative hidden md:block h-5">
      <PopoverButton class="outline-none">
        <img src="/src/assets/images/ellipsis-vertical-icon.svg" alt="">
      </PopoverButton>

      <PopoverPanel class="absolute right-0 z-10 mt-3 transform w-52">
        <div
          class="overflow-hidden rounded bg-white py-1 px-4 border border-[#F3F5F8]"
          style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);"
        >
          <ul class="divide-y divide-[#DFE4E8] border-b border-[#DFE4E8]">
            <li v-for="(item, index) in menuLinks" :key="index">
              <button class="py-3 w-full flex justify-between items-center text-base text-[#292C2E]">
                {{ item.title }}
                <img class="w-6" :src="item.img" alt="">
              </button>
            </li>
          </ul>

          <button class="py-3 w-full flex justify-between items-center text-base text-[#FC0000]">
            Delete profile
            <img src="/src/assets/images/trash-icon.svg" alt="">
          </button>
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import Modal from '../../../components/Modal.vue';

const menuModal = ref(null);

function openMenuModal() {
  menuModal.value.openModal();
}

const menuLinks = [
  {
    title: 'Profile preferences',
    img: '/src/assets/images/square-3-stack-3d-icon.svg',
  },
  {
    title: 'Preview profile',
    img: '/src/assets/images/eye-icon.svg',
  },
  {
    title: 'Share Profile',
    img: '/src/assets/images/arrow-up-tray-icon.svg',
  },
];
</script>
