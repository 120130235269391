import { SENTRY_DSN } from '@/constants';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

export function init(app, router) {
  if (SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['app.goglobal.world', /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
}

export default function useSentry() {
  return {
    setUser: SENTRY_DSN ? Sentry.setUser : () => {},
  };
}
