import useSmartForm from '@/compositions/useSmartForm';
import useUser from '@/compositions/useUser';
import {
  BLOCK_ID_CUSTOMERS_METRICS,
  BLOCK_ID_ELEVATOR_PITCH,
  BLOCK_ID_FOUNDERS,
  BLOCK_ID_FUNDRAISING,
  BLOCK_ID_OFFICE,
  BLOCK_ID_SALES_METRICS,
  BLOCK_ID_SEARCH_CRITERIA,
  BLOCK_ID_STARTUP_PROFILE,
  BLOCK_ID_TO_BLOCK_INDEX_MAP,
  BLOCK_INDEX_CRITERIA,
  BLOCK_INDEX_EXPERIENCE,
  BLOCK_INDEX_INVESTMENTS,
  BLOCK_INDEX_LP_SYNDICATE,
  BLOCK_INDEX_PERSONAL,
  BLOCK_INDEX_USER,
} from '@/constants';
import { post } from '@/services/httpClient';
import { computed, reactive, ref, watch } from 'vue';
import useCountriesSearch from './useCountriesSearch';
import useSmartFormStorage from './useSmartFormStorage';

const { user, personalInformationIsFilled } = useUser();

const { countriesMap } = useCountriesSearch();
const { fetchData, geographyMap } = useSmartFormStorage();
const { blocks: blockList, getBlocks } = useSmartForm();

const profileStatus = reactive({
  requiredBlocks: [],
  optionalBlocks: [],
  emptyRequiredBlocks: [],
  emptyOptionalBlocks: [],
});
const blocks = reactive(new Map());
const notFilledBlockIndex = ref(null);
const isPartialFilledRequiredBlocks = computed(
  () =>
    profileStatus.requiredBlocks.length >
    profileStatus.emptyRequiredBlocks.length,
);
const isFullyFilledRequiredBlocks = computed(
  () => profileStatus.emptyRequiredBlocks.length === 0,
);
const isEmptyProfile = computed(
  () =>
    profileStatus.emptyRequiredBlocks.length ===
      profileStatus.requiredBlocks.length &&
    profileStatus.emptyOptionalBlocks.length ===
      profileStatus.optionalBlocks.length,
);

const checkProfile = async () => {
  const result = await post('blocks/', { command: 'checkprofile' });

  profileStatus.emptyRequiredBlocks = [];
  profileStatus.emptyOptionalBlocks = [];
  profileStatus.requiredBlocks = [];
  profileStatus.optionalBlocks = [];

  result.forEach((block) => {
    const isRequired = Boolean(Number(block.required ?? 0));
    const isFilled = Boolean(Number(block.block_filled ?? 0));

    if (isRequired) {
      profileStatus.requiredBlocks.push(block.index);
    } else {
      profileStatus.optionalBlocks.push(block.index);
    }

    if (isRequired && !isFilled) {
      profileStatus.emptyRequiredBlocks.push(block.index);
    }

    if (!isRequired && !isFilled) {
      profileStatus.emptyOptionalBlocks.push(block.index);
    }
  });
};

const getAll = async (userId, command = 'getall') => {
  const res = await post('blocks/', { command, user_id: userId });

  if (user.user_id && user.user_id === userId) {
    await checkProfile();
  }

  blocks.clear();

  Object.keys(res).forEach((key) => {
    const value = key === 'user' ? res[key] : res[key].data;

    blocks.set(key, value);
  });

  return res;
};

const personalInformationBlock = computed(() => {
  if (!blocks.get(BLOCK_INDEX_USER)) {
    return null;
  }
  const data =
    blocks.get(BLOCK_INDEX_USER)?.users_basic ?? blocks.get(BLOCK_INDEX_USER);

  const country = countriesMap.value[data.country_id] || {};

  return { ...data, country: country.name };
});

const verticalsBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])
    .verticals;
});

const companyBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_STARTUP_PROFILE])) {
    return null;
  }
  const data = blocks.get(
    BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_STARTUP_PROFILE],
  );
  const country = countriesMap.value[data.country_id];

  return { ...data, country: country?.name };
});

const roundsBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])
    .rounds;
});

const businessModelBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])) {
    return null;
  }

  return (
    blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])
      .business_model || []
  );
});

const salesMetricsBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SALES_METRICS])) {
    return null;
  }

  const data = blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SALES_METRICS]);

  return {
    ...data,
    sales_geography: (data.sales_geography || [])
  };
});

const customersMetricsBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_CUSTOMERS_METRICS])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_CUSTOMERS_METRICS]);
});

const elevatorPitchBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_ELEVATOR_PITCH])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_ELEVATOR_PITCH]);
});

const foundersBlock = computed(() => {
  const data = blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_FOUNDERS]) || {};
  const userData = blocks.get('user') || {};

  if (user.user_id !== userData.user_id) {
    data.users_basic = blocks.get('user');
  } else {
    data.users_basic = {
      avatar: user.avatar || '',
      first_name: user.firstname || '',
      last_name: user.lastname || '',
      position: user.position || '',
      country_id: user.country_id || '',
      linkedin: user.linkedIn || '',
      short_bio: user.shortBio || '',
    };
  }

  return data;
});

const focusGeographyBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])) {
    return null;
  }

  return (
    blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA])
      .focus_geography || []
  );
});

const fundraisingBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_FUNDRAISING])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_FUNDRAISING]);
});

const officeBlock = computed(() => {
  if (!blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_OFFICE])) {
    return null;
  }

  return blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_OFFICE]);
});

const experienceBlock = computed(() => {
  if (!blocks.get(BLOCK_INDEX_EXPERIENCE)) {
    return null;
  }

  return blocks.get(BLOCK_INDEX_EXPERIENCE);
});

const lpSyndicateBlock = computed(() => {
  if (!blocks.get(BLOCK_INDEX_LP_SYNDICATE)) {
    return null;
  }

  return blocks.get(BLOCK_INDEX_LP_SYNDICATE);
});

const investmentsBlock = computed(() => {
  if (!blocks.get(BLOCK_INDEX_INVESTMENTS)) {
    return null;
  }

  return blocks.get(BLOCK_INDEX_INVESTMENTS);
});

const criteriaBlock = computed(() => {
  if (!blocks.get(BLOCK_INDEX_CRITERIA)) {
    return null;
  }

  const data = blocks.get(BLOCK_INDEX_CRITERIA);

  const country = countriesMap.value[data.country_id] || {};
  const focuses = (data.focuses || []).map((focus) => ({
    ...focus,
    country: (countriesMap.value[focus.country_id] || {}).name,
  }));

  return { ...data, country: country.name, focuses };
});

const makeAction = (field) => ({
  id: field,
  url: 'blocks/',
  params: {
    command: 'criteria',
    field,
  },
});

watch(
  () => blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_STARTUP_PROFILE]),
  (value) => {
    if (!value) return;

    fetchData(makeAction('rounds'));
  },
);

watch(
  () => [
    blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SALES_METRICS]),
    blocks.get(BLOCK_ID_TO_BLOCK_INDEX_MAP[BLOCK_ID_SEARCH_CRITERIA]),
  ],
  () => {
    fetchData(makeAction('region'));
  },
);

const requireBlocksIsFilled = async (userRole) => {
  let list = blockList.value;

  if (list.length === 0) {
    list = await getBlocks(userRole);
  }

  await getAll(user.user_id, 'profile');

  const requiredBlocks = list.reduce((acc, block) => {
    if (block.required && block.index !== BLOCK_INDEX_PERSONAL) {
      acc.push(block.index);
    }

    return acc;
  }, []);

  const isFilled = requiredBlocks.every((index) => {
    const hasBlock = blocks.has(index);

    if (!hasBlock) {
      notFilledBlockIndex.value = index;
    }

    return hasBlock;
  });

  if (isFilled && !personalInformationIsFilled.value) {
    notFilledBlockIndex.value = BLOCK_INDEX_PERSONAL;
    return false;
  }

  return isFilled;
};

const isClean = (blockData) => {
  let clean = true;

  if (Array.isArray(blockData) && blockData.length) {
    for (const item of blockData) {
      clean = clean && isClean(item);

      if (!clean) {
        break;
      }
    }
  } else if (typeof blockData === 'object') {
    const keys = Object.keys(blockData || {});

    for (const key of keys) {
      clean = clean && isClean(blockData[key]);

      if (!clean) {
        break;
      }
    }
  } else {
    clean = !blockData || blockData === '-';
  }

  return clean;
}

export default function useBlocks() {
  return {
    blocks,
    personalInformationBlock,
    verticalsBlock,
    companyBlock,
    roundsBlock,
    businessModelBlock,
    salesMetricsBlock,
    customersMetricsBlock,
    elevatorPitchBlock,
    foundersBlock,
    focusGeographyBlock,
    fundraisingBlock,
    officeBlock,
    experienceBlock,
    lpSyndicateBlock,
    investmentsBlock,
    criteriaBlock,
    isClean,

    notFilledBlockIndex,
    isPartialFilledRequiredBlocks,
    isFullyFilledRequiredBlocks,
    isEmptyProfile,

    requireBlocksIsFilled,
    getAll,
    checkProfile,
  };
}
