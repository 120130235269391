import { authMiddleware } from '@/router/middlewares';
import ChooseRole from '@/screens/sign-up/ChooseRole.vue';

export default [
  {
    name: 'ChooseRoleScreen',
    component: ChooseRole,
    path: '/choose-role',
    meta: { middlewares: [authMiddleware()] },
  },
];
