import { computed, reactive } from 'vue';
import { post } from '@/services/httpClient';
import dfs from '@/utils/dfs';

const makeAction = (field) => ({
  id: field,
  url: 'blocks/',
  params: {
    command: 'criteria',
    field,
  },
});

const storage = reactive({
  region: null,
  verticals: null,
  business_model: null,
});

const geographyMap = computed(() => {
  const result = {};

  if (!storage.region) {
    return result;
  }

  dfs(storage.region[0], (node) => {
    result[node.id] = node.label;
  });

  return result;
});

const verticalsMap = computed(() => {
  if (!storage.verticals) {
    return {};
  }

  return storage.verticals.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.label,
    }),
    {},
  );
});

const businessModelMap = computed(() => {
  if (!storage.business_model) {
    return {};
  }

  return storage.business_model.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.label,
    }),
    {},
  );
});

const fetchData = async (action) => {
  if (storage[action.id]) {
    return;
  }

  storage[action.id] = [];

  try {
    const res = await post(action.url, action.params || {});

    if (Array.isArray(res)) {
      storage[action.id] = res.map((item) => ({ ...item, name: item.name || item.label }));

      return;
    }

    storage[action.id] = res[action.params.field].options;
  } catch (e) {
    console.log(e);
  }
};

export default function useSmartFormStorage() {
  return {
    storage,
    geographyMap,
    verticalsMap,
    businessModelMap,
    fetchData,
    makeAction,
  };
}
