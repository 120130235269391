<template>
  <MainLayout has-footer has-header>
    <MainContent
      is-big-width
      class="lg:pb-0"
      :class="{ 'pb-[77px]': state.isAuthenticated }"
    >
      <AppHeader class="lg:hidden" is-big-width is-btn-menu is-bg-white>
        <div class="flex gap-1 items-center justify-center">
          <img class="h-6" src="/src/assets/images/logo-small.png" alt="" />
          <span class="text-base font-bold text-[#292C2E]">Events</span>
        </div>
      </AppHeader>
      <div v-if="!isLoading" class="mt-14 pt-4 lg:mt-12 px-4 mb-10 lg:mb-0">
        <div class="lg:flex gap-6">
          <div>
            <EventItem :item="firstEvent" />
          </div>
          <div
            class="w-[32%] flex-none rounded overflow-x-hidden pt-2 hidden lg:block bg-[#3793EE]"
          >
            <div class="px-6">
              <img
                class="mx-auto"
                src="/src/assets/images/events/banner.jpg"
                alt=""
              />
            </div>
            <div
              class="pb-12 px-6"
              style="
                background: linear-gradient(180deg, #3793ee 0%, #005ab4 100%);
              "
            >
              <h2 class="mt-2.5 text-center text-2xl font-bold text-white">
                Organize delightful events
              </h2>
              <p class="mt-2 text-center text-base text-white">
                Nurture significant connections through the facilitation of
                events, newsletters. and our community
              </p>
              <Btn
                as="a"
                class="mt-7 max-w-max mx-auto !border-transparent"
                theme="white"
                size="xs"
                href="mailto:info@goglobal.world?subject=Host the event&body=Hi Go Global World Team! I am interested to host my event on your platform. Here is description and additional information"
              >
                Host an event
              </Btn>
            </div>
          </div>
        </div>
        <div class="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-6">
          <EventItem
            v-for="(item, index) in otherEvents"
            :key="index"
            :item="item"
          />
        </div>
        <Btn
          as="a"
          size="xs"
          class="mt-10 max-w-[343px] mx-auto lg:!hidden"
          href="mailto:info@goglobal.world?subject=Host the event&body=Hi Go Global World Team! I am interested to host my event on your platform. Here is description and additional information"
        >
          Host an event
        </Btn>
      </div>
      <MenuBottom v-if="state.isAuthenticated" class="lg:hidden" is-big-width />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import AppHeader from '@/components/AppHeader.vue';
import Btn from '@/components/Btn.vue';
import EventItem from '@/screens/events/components/EventItem.vue';
import MenuBottom from '@/components/MenuBottom.vue';

import useEvents from '@/compositions/useEvents.js';
import useAuth from '@/compositions/useAuth.js';

import { computed } from 'vue';

const { events, isLoading, GET_EVENTS } = useEvents();

const firstEvent = computed(() => events.value && events.value[0]);

const otherEvents = computed(() => events.value && events.value.slice(1));

const { state } = useAuth();

GET_EVENTS();
</script>
