import { ALL } from '@/compositions/useConnectionFilters.js';
import useMatchEndpoint from '@/compositions/useMatchEndpoint.js';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants.js';

const fetchConnections = (params) => {
  return useMatchEndpoint().send('members', {
    requests: 'match',
    data: {
      like: params.search || params.like,
      role:
        params.type === ALL
          ? [USER_ROLE_INVESTOR, USER_ROLE_STARTUP, USER_ROLE_ADVISOR]
          : [params.type],
      offset: params.offset,
      limit: params.limit,
    },
  });
};
function buildFilters(filtersState) {
  return {
    like: filtersState.search,
    type: filtersState.type,
  };
}

async function load(filtersState, offset, limit) {
  const params = {
    ...buildFilters(filtersState),
    offset,
    limit,
  };

  // return {
  //     count: 10,
  //     list: [
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //         {
  //             id: "491",
  //             photo: '/src/assets/images/profile-investor/user-photo-example.jpg',
  //             type: 'startup',
  //             name: 'Jenny Wilson',
  //             headliner: 'Co-Founder & Director of Business',
  //             status: 'Open for investments',
  //             geography: 'United Kindom of Great Britain and Northern Irela',
  //             canHelp: 'United Kindom of Great Britain and Northern Irela',
  //             needHelp: 'United Kindom of Great Britain and Northern Irela',
  //             is_verified: null,
  //             viewed: null,
  //             action: null,
  //             dialog_id: '1'
  //         },
  //     ]
  // };

  return fetchConnections(params);
}

export default function () {
  return {
    load,
  };
}
