import { useCookies } from "@vueuse/integrations/useCookies";
import { post } from "@/services/httpClient.js";

const maxAge = 365 * 24 * 60 * 60;
const cookies = useCookies();

const fetchCriteria = (field) => post('blocks/', { command: 'criteria', field });

function buildSection(label, criteriaOptions, checkedIds) {
    return { label, checkboxes: buildCheckboxes(criteriaOptions, checkedIds) };
}

function buildCheckboxes(criteriaOptions, checkedIds = []) {
    const result = {};

    for (const option of criteriaOptions) {
        const checkbox = {
            label: option.label,
            hidden: false,
            expanded: false,
            value: checkedIds.includes(option.id)
                || checkedIds.includes('Global')
                || checkedIds.includes('All')
                || checkedIds.includes('all')
        };

        if (option.children && option.children.length) {
            if (checkbox.value) {
                checkedIds.push(...option.children.map(child => child.id));
            }

            checkbox.children = buildCheckboxes(option.children, checkedIds);
        }

        result[option.id] = checkbox;
    }

    return result;
}


function clearSection(section) {
    clear(section.checkboxes);
}

function clear(checkboxes) {
    for (const id of Object.keys(checkboxes)) {
        const checkbox = checkboxes[id];

        if (checkbox.children) {
            clear(checkbox.children);
        }

        checkbox.value = false;
    }
}

function getCheckedIds(checkboxes) {
    const result = [];

    for (const id of Object.keys(checkboxes)) {
        const checkbox = checkboxes[id];

        if (checkbox.value) {
            result.push(id);
        } else if (checkbox.children) {
            result.push(...getCheckedIds(checkbox.children));
        }
    }

    return result;
}

function filterSection(section, like) {
    for (const id of Object.keys(section.checkboxes)) {
        setVisibility(section.checkboxes[id], like);
    }
}

function hasCheckedChild(checkbox) {
    if (checkbox.children) {
        const keys = Object.keys(checkbox.children);

        for (const key of keys) {
            if (checkbox.children[key].value || hasCheckedChild(checkbox.children[key])) {
                return true;
            }
        }
    }

    return false;
}

function getSortedKeys(checkboxes) {
    const keys = Object.keys(checkboxes);

    return keys.sort(
        (a, b) => {
            const aChecked = checkboxes[a].value || hasCheckedChild(checkboxes[a]);
            const bChecked = checkboxes[b].value || hasCheckedChild(checkboxes[b]);

            if (aChecked && !bChecked) {
                return -1;
            } else if (!aChecked && bChecked) {
                return 1;
            }

            return a > b ? 1 : -1;
        }
    );
}

function setVisibility(checkbox, like, value = false) {
    let visible = checkbox.label.toLowerCase().search(like.toLowerCase()) !== -1 || value;
    let hasVisibleChild = false;

    if (checkbox.children) {
        for (let id of Object.keys(checkbox.children)) {
            hasVisibleChild = !setVisibility(checkbox.children[id], like) || hasVisibleChild;
        }

        if (visible && !hasVisibleChild) {
            for (let id of Object.keys(checkbox.children)) {
                setVisibility(checkbox.children[id], like, true);
            }
        }
    }

    checkbox.hidden = !visible && !hasVisibleChild;
    checkbox.expanded = hasVisibleChild && Boolean(like);

    return checkbox.hidden;
}

function getLabelById(checkboxes, id) {
    for (const checkboxId of Object.keys(checkboxes)) {
        if (checkboxId === id) {
            return checkboxes[checkboxId].label;
        } else if (checkboxes[checkboxId].children) {
            const result = getLabelById(checkboxes[checkboxId].children, id);

            if (result) {
                return result;
            }
        }
    }

    return null;
}

export default function (name) {
    function store(data) {
        cookies.set(name, JSON.stringify(data), {maxAge, path: '/'});
    }

    function restore() {
        return cookies.get(name);
    }

    return {
        store,
        restore,
        buildSection,
        getSortedKeys,
        getCheckedIds,
        clearSection,
        filterSection,
        fetchCriteria,
        getLabelById
    }
}
