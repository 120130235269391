import { post } from "@/services/httpClient.js";
import useIncompleteProfileModal from "@/compositions/useIncompleteProfileModal.js";
import useUser from "@/compositions/useUser.js";
import { AxiosError } from "axios";

function send(command, params) {
    return post('match/', { command, ...params }, true)
        .catch(
            (error) => {
                const response = error.response;

                if (response.status === 417) {
                    const { open } = useIncompleteProfileModal();

                    open();
                } else if (response.status === 402) {
                    const { user } = useUser();

                    if (user.tariff === null) {
                        window.location = '/updating-plan';
                    } else {
                        window.location = '/profile/settings/subscription';
                    }
                }

                if (error instanceof AxiosError) {
                    if (error.response) {
                        throw error.response.data;
                    }

                    if (error.request) {
                        throw error.request;
                    }
                }

                return  { error: error.message };
            }
        );
}

export default function () {
    return {
        send
    }
}
