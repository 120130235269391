import useBlocks from '@/compositions/useBlocks';

const { getAll } = useBlocks();

export default async function investorProfileMiddleware(to, from, next) {
  const { id } = to.params;

  try {
    await getAll(id, 'profile');

    return next();
  } catch (error) {
    return next({ name: 'WelcomeScreen' });
  }
}
