import { ref, computed, reactive } from 'vue';

import { post } from '../services/httpClient';

const countries = reactive([]);
const countriesMap = computed(
  () => countries.reduce((acc, country) => ({ ...acc, [country.id]: country }), {}),
);

const groupedCountries = reactive([]);

post('countries/').then((res) => countries.push(...res));

post('countries/', { full: 1 }).then(
  (res) => groupedCountries.push(...res),
);

const getValueById = (id) => countries.find((item) => item.id == id);

export default function useCountriesSearch() {
  const query = ref('');

  const list = computed(() => {
    if (query.value === '') return countries;

    return countries.filter((country) =>
      country.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')));
  });

  return {
    query, countriesMap, list, groupedCountries, getValueById,
  };
}
