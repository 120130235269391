<template>
  <div>
    <button
      type="button"
      @click="openModal"
    >
      <img v-if="isFilter" class="w-5" src="/src/assets/images/adjustments-horizontal-icon.svg" alt="">
      <img v-else class="w-4" src="/src/assets/images/pencil-icon.svg" alt="">
    </button>
    <Dialog :open="isOpen" class="relative z-50" @close="closeModal">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full bg-white flex-col flex-1">
          <DialogPanel class="pt-14 flex flex-col flex-1">
            <AppHeader class="inset-x-0 top-0" is-bg-white :is-big-width="isFilter">
              <div class="flex">
                <button class="absolute top-5" :class="isRightClose ? 'right-5' : 'left-5'" @click="closeModal">
                  <img src="/src/assets/images/x-mark-icon-black.svg" alt="">
                </button>
                <img class="mx-auto h-8" src="/src/assets/images/welcome-logo.png" alt="logo Go Global World">
                <ModalContactUs v-if="!isFilter" />
              </div>
            </AppHeader>

            <slot />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import AppHeader from '../../../components/AppHeader.vue';
import ModalContactUs from '../../../components/ModalContactUs.vue';

defineProps({
  isRightClose: {
    type: Boolean,
    default: false,
  },
  isFilter: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}
function openModal() {
  isOpen.value = true;
}
</script>
