import { reactive, watch } from 'vue';
import { useCookies } from '@vueuse/integrations/useCookies';
import { post } from '../services/httpClient';
import useUser from './useUser';
import useCredentials from './useCredentials';

const COMMAND_NEW_EMAIL = 'newemail';
const COMMAND_NEW_PASSWORD = 'newpasswd';

const cookies = useCookies();

const { getEmail, getAuthToken, save, reset } = useCredentials();

const { getUserInfo } = useUser();

const state = reactive({
  firstName: '',
  lastName: '',
  countryId: '',
  email: getEmail(),
  password: '',
  linkedin: '',

  authToken: getAuthToken(),
  rememberMe: true,
  isAuthenticated: false,
  isAlienMode: cookies.get('isAlienMode'),
});

const auth = (params) => post('auth/', params);
const signUp = (params) => auth({ ...params, signup: 1 });
const verify = async (email, password, code) => {
  const response = await auth({ email, passwd: password, code });

  state.authToken = response.auth_token;
  state.isAuthenticated = true;
  await getUserInfo();
};

const signIn = async (
  email,
  password,
  token,
  oAuthType = 'google',
  additionalParams = {},
) => {
  let promise = null;

  if (token) {
    promise = auth({
      email,
      token,
      oauth: oAuthType,
      ...additionalParams,
    });
  } else {
    promise = auth({ email, passwd: password, ...additionalParams });
  }
  const response = await promise;

  if (response.code !== 'code') {
    state.authToken = response.auth_token;
    state.email = email;
    state.isAuthenticated = true;
    await getUserInfo();
  }

  return response;
};
const logOut = () => {
  cookies.remove('password', { path: '/' });

  reset();

  return new Promise(() => {
    window.location.replace('/login');
  });
};

const adminLogin = ({ email, auth_token: authToken }) => {
  if (email && authToken) {
    state.email = email;
    state.authToken = authToken;
    state.isAlienMode = true;
  }

  return new Promise(() => {
    window.location.replace('/');
  });
};

const forgot = async (params) => {
  await post('auth/', { command: 'forgot', ...params });
};

const updateEmail = async (params) => {
  const response = await post('user/', {
    command: COMMAND_NEW_EMAIL,
    ...params,
  });
  const { email, auth_token: authToken } = response;

  if (email && authToken) {
    state.email = email;
    state.authToken = authToken;

    await getUserInfo();
  }

  return response;
};

const updatePassword = async (params) => {
  const { auth_token: authToken } = await post('user/', {
    command: COMMAND_NEW_PASSWORD,
    ...params,
  });

  state.authToken = authToken;
  await getUserInfo();
};

let authPromise = new Promise((resolve) => {
  (async () => {
    try {
      if (state.authToken && state.email) {
        await getUserInfo();
        state.isAuthenticated = true;
      }
    } catch (error) {
      state.isAuthenticated = false;
    }

    resolve(state.isAuthenticated);
  })();
}).finally(() => {
  authPromise = null;
});

const isAuthenticated = () => {
  if (authPromise !== null) {
    return authPromise;
  }

  return Promise.resolve(state.isAuthenticated);
};

watch(
  () => [state.email, state.authToken, state.rememberMe, state.isAlienMode],
  ([email, authToken, rememberMe, alienMode]) => {
    save(
        email || '',
        authToken || '',
        rememberMe
    );

    if (alienMode) {
      cookies.set('isAlienMode', alienMode, { path: '/' });
    }
  },
);

export default function useAuth() {
  return {
    state,

    signUp,
    signIn,
    logOut,
    verify,
    forgot,
    adminLogin,
    updateEmail,
    updatePassword,

    isAuthenticated,
  };
}
