<template>
  <Switch
    class="switch"
    :modelValue="enabled"
    @update:modelValue="change"
    :class="{ [theme]: theme, [size]: size, enabled: enabled }"
  >
    <span class="sr-only">Enable notifications</span>
    <span
      :class="enabled ? 'translate-x-7' : 'translate-x-1'"
      class="inline-block transform rounded-full bg-white transition"
    />
  </Switch>
</template>

<script setup>
import { Switch } from '@headlessui/vue';

const props = defineProps({
  theme: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'base',
    validator: (value) => ['xs', 'base'].includes(value),
  },
  enabled: { type: Boolean, default: false }
});

const emit = defineEmits({
  change: null
});

function change() {
  emit('change');
}
</script>

<style>
.switch {
  @apply relative inline-flex items-center rounded-full;
}

.switch.main {
  @apply bg-[#DFE4E8];
}

.switch.main.enabled {
  @apply bg-[#008AFC];
}

.switch.black {
  @apply bg-[#292C2E]/50;
}

.switch.black.enabled {
  @apply bg-[#292C2E];
}

.switch.base {
  @apply h-8 w-14;
}

.switch.base span {
  @apply h-6 w-6 translate-x-1;
}

.switch.base.enabled span {
  @apply h-6 w-6 translate-x-7;
}

.switch.xs {
  @apply h-4 w-6;
}

.switch.xs span {
  @apply h-3 w-3 translate-x-0.5;
}

.switch.xs.enabled span {
  @apply h-3 w-3 translate-x-2.5;
}
</style>
