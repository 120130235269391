<template>
  <div class="p-4 pb-6 flex flex-col h-full flex-1">
    <div class="flex justify-between items-center">
      <h1 class="text-[#292C2E] text-lg font-bold">
        Current fundraising
      </h1>
      <Completed has-text />
    </div>

    <div class="mt-4 flex gap-2">
      <FormInput name="" placeholder-label label-second="$">
        Minimum
      </FormInput>
      <FormInput name="" placeholder-label label-second="$">
        Maximum
      </FormInput>
    </div>
    <div class="mt-2">
      <FormInput name="" placeholder-label label-second="$">
        Raised
      </FormInput>
    </div>
    Type of share
    Closing Deadline

    <TitleLine>Optional</TitleLine>

    <div class="space-y-2">
      <div v-for="(item, index) in optionalItems" :key="index">
        <OptionalItem :item="item" />
      </div>
    </div>

    <hr class="w-full h-px bg-[#DFE4E8] my-4">

    <div>
      <BtnAdd>Previous fundraising</BtnAdd>

      <div v-if="false">
        <Btn class="mt-4" theme="white">
          Delete Previous fundraising
        </Btn>
      </div>
    </div>

    <Btn class="mt-4">
      Save & Next
    </Btn>
  </div>
</template>

<script setup>
import FormInput from '../../../components/FormInput.vue';
import TitleLine from '../../update/components/TitleLine.vue';
import Btn from '../../../components/Btn.vue';
import OptionalItem from '../../update/components/OptionalItem.vue';
import BtnAdd from '../../update/components/BtnAdd.vue';
import Completed from '../../update/components/Completed.vue';

const optionalItems = [
  {
    labelInput: 'CAC',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    radioItems: [],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'CAC',
    isActiveItem: true,
  },
  {
    labelInput: 'Raising as equity',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    radioItems: [],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'Raising as equity',
    isActiveItem: true,
  },
  {
    labelInput: 'Pre-money valuation',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    radioItems: [],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'Pre-money valuation',
    isActiveItem: true,
  },
  {
    labelInput: '',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    title: 'Pro rata rights',
    radioItems: [
      {
        id: 'included',
        title: 'Included',
      },
      {
        id: 'notIncluded',
        title: 'Not Included',
      },
    ],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'Pro rata rights',
    isActiveItem: true,
  },
  {
    labelInput: 'Syndicate Lead\'s Investment',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    radioItems: [],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'Syndicate Lead\'s Investment',
    isActiveItem: false,
  },
  {
    labelInput: 'Min check',
    labelInputRight: '$',
    labelInputSecond: '',
    labelInputSecondRight: '',
    radioItems: [],
    text: '',
    isPeriodSelect: false,
    buttonAdd: 'Min check',
    isActiveItem: false,
  },
];
</script>
