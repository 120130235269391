<template>
  <ModalFree ref="eventModal">
    <div
      class="relative flex lg:hidden justify-center items-center gap-1.5 bg-white h-14 border-b border-[#DFE4E8]"
    >
      <img class="w-9" src="/src/assets/images/logo-small.png" alt="" />
      <span class="text-base font-bold text-[#292C2E]">Event registration</span>
      <button @click="closeEventModal" class="absolute top-4 right-4">
        <XMarkIcon class="text-[#292C2E] w-6" />
      </button>
    </div>
    <div
      class="lg:pb-0 flex flex-col lg:flex-row lg:rounded bg-white overflow-y-auto lg:overflow-hidden mx-auto w-screen lg:w-[900px] xl:w-[992px] lg:h-[720px]"
    >
      <div class="p-4 lg:p-6 lg:w-[45%]  lg:bg-[#DFE4E8]">
        <img class="w-full" :src="event.picture" alt="" />
        <div class="mt-4 text-xl font-bold text-[#292C2E]">
          {{ event?.name }}
        </div>
        <EventDetails class="hidden lg:block mt-6 lg:mb-6" />
        <div class="lg:flex gap-2 items-center hidden">
          <div v-if="event.attendees.list" class="flex ml-2">
            <RoundImages
              class="!border-white"
              :img="item"
              size="xl"
              v-for="(item, key) in event?.attendees?.list.slice(0, 5)"
              :key="key"
            />
          </div>
          <div class="text-sm text-[#292C2E] font-bold"
            >{{ event?.attendees?.count }} attendees</div
          >
        </div>
      </div>
      <div class="p-4 lg:p-6 lg:w-[55%] lg:overflow-y-auto lg:order-first">
        <slot />
        <EventRegistration  @close="closeEventModal" />
      </div>
    </div>
  </ModalFree>
</template>
<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import ModalFree from '@/components/ModalFree.vue';
import EventDetails from '@/screens/events/components/EventDetails.vue';
import RoundImages from '@/components/RoundImages.vue';
import EventRegistration from '@/screens/events/components/EventRegistration.vue';

import useEvents from '@/compositions/useEvents';

const { event } = useEvents();
const eventModal = ref(null);

function closeEventModal() {
  eventModal.value.closeModal();
}

function openEventModal() {
  eventModal.value.openModal();
}

defineExpose({
  openEventModal,
});
</script>
