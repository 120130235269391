<template>
  <button
    class="flex gap-2 items-center"
    v-for="(tab, index) in tabs"
    :key="index"
    @click="$emit('change', index)"
  >
    <div
      class="w-5 h-5 rounded-full flex justify-center items-center"
      :class="index === active ? 'bg-[#008AFC]' : 'bg-[#DFE4E8]'"
    >
      <div
        class="rounded-full bg-white"
        :class="index === active ? 'w-2 h-2' : 'w-4 h-4'"
      />
    </div>
    <span class="text-base text-[#292C2E] font-normal">{{ tab.title }}</span>
    <span
      class="inline-flex rounded bg-[#149911] py-0.5 px-1.5 uppercase text-[10px] leading-[14px] font-bold text-white"
      v-if="tab.label"
    >
      {{ tab.label }}
    </span>
  </button>
</template>

<script setup>
defineProps({
  tabs: { type: Object, default: () => ({}) },
  active: { type: Number, default: 0 },
});

defineEmits({
  change: null
});
</script>
