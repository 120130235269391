import useBlocks from '@/compositions/useBlocks';
import useUser from '@/compositions/useUser';

const { requireBlocksIsFilled } = useBlocks();
const { user, isAccepted } = useUser();

export default async function profileStatusMiddleware(to, from, next) {
  if (isAccepted.value) {
    next();
    return;
  }

  const isFilled = await requireBlocksIsFilled(user.role);

  if (isFilled) {
    next();
    return;
  }

  next(false);
}
