<template>
  <MainLayout has-header has-footer>
    <MainContent
      class="!bg-[#EEF2F5]"
      :class="{ 'pb-[76px] lg:pb-0': state.isAuthenticated }"
      is-big-width
    >
      <AppHeader
        is-bg-white
        :is-btn-back="state.isAuthenticated"
        is-big-width
        class="lg:hidden"
        is-btn-menu
      >
        <h1
          class="text-base font-bold text-[#292C2E] flex justify-center gap-1 items-center"
        >
          <img class="h-7" src="/src/assets/images/logo-small.png" alt="" />
          Plans
        </h1>
      </AppHeader>
      <div class="mt-14 pt-6 lg:pt-0">
        <h1
          class="text-2xl sm:text-3xl lg:text-5xl font-bold leading-[120%] text-center px-4"
        >
          Upgrade the membership now
        </h1>
        <p class="mt-2 lg:mt-4 text-lg text-center px-4">
          to get unlimited access to everything on the GGW platform
        </p>

        <div class="mt-2 lg:mt-20 px-4 overflow-x-hidden">
          <div
            class="flex justify-between items-center gap-8 sm:gap-3 flex-wrap"
          >
            <div class="sm:order-last">
              <a href="#compare-table" class="text-base text-[#292C2E] font-bold underline">
                Compare plans
              </a>
            </div>
            <div class="flex gap-x-6 gap-y-3 flex-wrap">
              <PlanTabs :tabs="tabs" :active="selected" @change="selected = $event" />
            </div>
          </div>

          <Swiper
              v-if="rates.length"
              :space-between="8"
              :slides-per-view="1"
              :initial-slide="isMobile ? rates.length - 2 : 0"
              :breakpoints="{
                0: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }"
              class="mt-8 max-w-[85%] md:max-w-[95%] !overflow-visible"
          >
            <SwiperSlide
                v-for="(rate, index) in rates"
                :key="index"
                class="!h-auto pt-3"
            >
              <PlanCard
                  :plan="rate"
                  :annual="selected === 1"
                  :subtitleFirst="index ? `Everything in ${rates[index - 1].title.toUpperCase()} and more:` : null"
                  :is-recommended="index === rates.length - 2"
                  :has-tariff="hasTariff"
                  @manage="onManage"
                  @subscribeWithTrial="onStart(rate.priceId)"
                  @subscribeWithoutTrial="onStart(rate.priceId, false)"
                  class="h-full"
              />
            </SwiperSlide>
          </Swiper>

          <p class="mt-8 text-sm text-[#676C71] text-center">
            <span class="text-[#292C2E] font-bold">Please kindly be aware:</span>
            the purchase of a subscription does not guarantee access to investor
            connections. To gain access to investors, it is important that all
            basic mandatory fields are complete and accurate in your profile.
            Our moderation team has the right to reject your access to investor
            matchmaking, if required information is incomplete or inaccurate.
            Ones it’s passed verification, you may enjoy full functionality of
            our platform that come with your selected plan.
          </p>
        </div>

        <div id="compare-table" class="mt-8 lg:mt-20">
          <h2
            class="text-2xl sm:text-3xl lg:text-[32px] leading-[120%] font-bold text-center"
            >Compare {{ user.role }} features</h2>
          <PlanTable
              v-if="user.role"
              class="mt-5 lg:mt-10"
              :rates-list="list"
              :user-role="user.role"
              :has-tariff="hasTariff"
              :tabs="tabs"
              @manage="onManage"
              @subscribeWithTrial="onStart($event.priceId)"
              @subscribeWithoutTrial="onStart($event.priceId, false)"
          />
        </div>

        <!-- <div class="mt-6 lg:mt-20 px-4 mb-6 lg:mb-0">
          <PlanFAQ />
        </div> -->
      </div>
      <MenuBottom v-if="state.isAuthenticated" is-big-width class="lg:hidden" />
    </MainContent>
  </MainLayout>
</template>
<script setup>
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';
import AppHeader from '@/components/AppHeader.vue';
import MenuBottom from '@/components/MenuBottom.vue';
import useAuth from '@/compositions/useAuth';
import PlanCard from '@/screens/tariffs-plans/components/PlanCard.vue';
import PlanFAQ from '@/screens/tariffs-plans/components/PlanFAQ.vue';
import PlanTable from '@/screens/tariffs-plans/components/PlanTable.vue';
import PlanTabs from '@/screens/tariffs-plans/components/PlanTabs.vue';
import { computed, ref } from 'vue';
import 'swiper/css';
import usePayments from '@/compositions/usePayments';
import useRequest from '../../compositions/useRequest';
import useUser from '../../compositions/useUser';
import useBreakpoints from "@/compositions/useBreakpoints.js";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/vue';
import useCurrentPlan from "@/compositions/useCurrentPlan.js";
import {USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y} from "@/constants.js";
// eslint-disable-next-line import/no-unresolved

const { state } = useAuth();
const { user } = useUser();
const { list, getRates, pay, manage } = usePayments();
const { request, isProcessing } = useRequest(pay);
const { isMobile } = useBreakpoints();
const selected = ref(1);

const rates = computed(() => list.value[selected.value ? 0 : 1]);
const { currentPlan } = useCurrentPlan();
const hasTariff = computed(
    () => {
      return !['Free', 'MVP', 'Scout'].includes(currentPlan.value?.tariff_name);
    }
);

const tabs = computed(
    () => {
      const annualSafePercent = list.value[0]?.length
        ? list.value[0].reduce(
              (max, rate) => {
                return rate.safePercent && rate.safePercent > max
                    ? rate.safePercent
                    : max;
              },
              0
          )
        : 0;

      return [
        {
          title: 'Billed monthly',
        },
        {
          title: 'Billed yearly',
          label: `up to -${ annualSafePercent }%`,
        },
      ]
    }
);

const onStart = async (pid, withTrial = true) => {
  await request(pid, withTrial);
};

const onManage = async () => {
  const { link } = await manage();

  window.location.assign(link);
};

getRates();
</script>
