<template>
  <Card class="!pt-4">
    <div class="flex flex-col">
<!--      <InputSearch-->
<!--        class="lg:mt-4 lg:order-last"-->
<!--        :class="{ 'opacity-50 pointer-events-none': [USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y].includes(user.tariff) }"-->
<!--        :value="filtersState.search"-->
<!--        @input="filtersState.search = ($event.target.value || '').trim()"-->
<!--      />-->
      <div class="mt-4 lg:mt-0 flex flex-col md:flex-row gap-y-3 gap-x-4 md:items-center justify-between">
        <h1 class="text-xl text-[#292C2E] font-bold hidden lg:block">
          Members
        </h1>
        <div class="flex gap-1 items-center justify-between sm:justify-start" :class="{ 'opacity-50 pointer-events-none': [USER_TARIFF_MVP_M, USER_TARIFF_MVP_Y].includes(user.tariff) }">
          <div class="text-base text-[#676C71]">
            Hide viewed profiles
          </div>
          <Switch size="xs" theme="black" :enabled="filtersState.hiddenViewedProfiles" @change="filtersState.hiddenViewedProfiles = !filtersState.hiddenViewedProfiles" />
        </div>
      </div>
    </div>

    <div class="mt-4 divide-y divide-[#DFE4E8] border-t border-[#DFE4E8] lg:border-none lg:divide-y-0">
      <MemberItem v-for="(member, index) in members" :key="index" :locked="!fullVersion && members.length === limit && members.length - 1 === index" :member="member" class="first:before:hidden before:lg:w-[96%] before:h-px before:absolute before:bg-[#DFE4E8] before:-top-px before:left-4 before:hidden before:lg:block" />
    </div>
  </Card>
</template>
<script setup>
import Card from '@/screens/profile-go-global-world-static/components/Card.vue';
import Switch from '@/screens/update/components/Switch.vue';
import InputSearch from '@/screens/connections/components/InputSearch.vue';
import MemberItem from '@/screens/connections/components/MemberItem.vue';
import useMembers from '@/compositions/useMembers';
import useMembersFilters from '@/compositions/useMembersFilters';
import {
  watch, ref, onMounted, onUnmounted,
} from 'vue';

import {
  USER_TARIFF_MVP_M,
  USER_TARIFF_MVP_Y,
} from '@/constants';

import useUser from '@/compositions/useUser.js';
import usePageScroll from "@/compositions/usePageScroll.js";

const { user } = useUser();

const props = defineProps({
  filtersState: { type: Object },
  fullVersion: { type: Boolean },
});

const { onPageFullyScrolled, offPageFullyScrolled } = usePageScroll();
const { save, getFromCookies } = useMembersFilters();
const { load } = useMembers();
const members = ref([]);
const limit = props.fullVersion ? 10 : 6;
let debounce = null;

let thereIsNoMore = false;
let offset = 0;
let loadingDisabled = false;

watch(
  props.filtersState,
  async () => {
    if (filtersChanged() || !members.value.length) {
      members.value = [];

      if (debounce) {
        clearTimeout(debounce);
      }

      debounce = setTimeout(
        () => {
          loadMembers(true);
        },
        1000,
      );
    }
  },
  {
    immediate: true,
  },
);

function filtersChanged() {
  const oldValues = getFromCookies();

  save();

  const newValues = getFromCookies();

  return JSON.stringify(oldValues) !== JSON.stringify(newValues);
}

async function loadMembers(force = false) {
  if (!loadingDisabled || force) {
    loadingDisabled = true;

    if (force) {
      offset = 0;
    } else {
      offset += limit;
    }

    if (props.fullVersion || offset === 0) {
      try {
        const result = await load(props.filtersState, offset, limit);

        if (!offset) {
          members.value = result.list;
        } else {
          members.value.push(
            ...result.list,
          );
        }

        thereIsNoMore = offset >= result.count && result.list.length === 0;
      } catch (e) {

      }
    }

    loadingDisabled = false;
  }
}

onMounted(
  () => {
    onPageFullyScrolled(
        () => {
          !thereIsNoMore && loadMembers();
        }
    );
  },
);

onUnmounted(
  () => {
    offPageFullyScrolled();
  },
);
</script>
