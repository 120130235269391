import { ref } from 'vue';

const modal = ref(null);

function open() {
    modal.value?.openModal();
}

export default function () {
    return {
        modal,
        open
    };
}
