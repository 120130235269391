<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" @click="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full justify-center text-center sm:items-center"
          :class="{
            'p-4 sm:p-0': !isNewStyle,
            'items-center px-9': isDesktop,
            '!p-0': isNotPadding,
          }"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform w-full bg-white text-left transition-all"
              :class="[
                isNewStyle
                  ? 'mt-auto md:my-auto rounded-t-2xl md:rounded md:max-w-lg pb-0 md:pb-6'
                  : 'pt-5 rounded-lg overflow-hidden',
                isNotPadding ? '!p-0' : 'p-4 md:px-6',
                { 'rounded-none h-screen': isSizeFull },
                {
                  'w-full overflow-visible': isDesktop,
                },
              ]"
              :style="isDesktop ? `max-width: ${maxWidth};` : ''"
            >
              <button
                v-if="isBtnClose"
                class="absolute"
                :class="[
                  isNewStyle
                    ? '-top-6 -right-6 hidden md:block'
                    : 'left-4 top-5',
                  { '!-top-6 -right-6 block left-auto': isDesktop },
                ]"
                @click="closeModal"
              >
                <img
                  v-if="isNewStyle || isDesktop"
                  src="/src/assets/images/x-mark-icon-white.svg"
                  alt=""
                />
                <img
                  v-else
                  src="/src/assets/images/x-mark-icon-black.svg"
                  alt=""
                />
              </button>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';

const isOpen = ref(false);

const props = defineProps({
  disableClosing: {
    type: Boolean,
    default: false,
  },
  isBtnClose: {
    type: Boolean,
    default: false,
  },
  isNewStyle: {
    type: Boolean,
    default: false,
  },
  isSizeFull: {
    type: Boolean,
    default: false,
  },
  isDesktop: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    type: String,
    default: '896px',
  },
  isNotPadding: {
    type: Boolean,
    default: false,
  },
});

function openModal() {
  isOpen.value = true;
}

function closeModal() {
  if (!props.disableClosing) {
    isOpen.value = false;
  }
}

defineExpose({
  openModal,
  closeModal,
});
</script>
