/* eslint-disable max-classes-per-file */
import { format } from '@/compositions/useIMask';
import useUser from '@/compositions/useUser';
import {
  USER_ROLE_ADVISOR,
  USER_ROLE_INVESTOR,
  USER_ROLE_STARTUP,
} from '@/constants';
import DateEntity from '@/entities/DateEntity';
import { computed, reactive } from 'vue';
import { post } from '@/services/httpClient';

export const TYPE_SEED_Y = 'SEED_Y';
export const TYPE_SEED_M = 'SEED_M';
export const TYPE_SERIES_Y = 'SERIES_Y';
export const TYPE_SERIES_M = 'SERIES_M';
export const TYPE_INVESTOR_Y = 'INVESTOR_Y';
export const TYPE_INVESTOR_M = 'INVESTOR_M';
export const TYPE_MVP_Y = 'MVP_Y';
export const TYPE_MVP_M = 'MVP_M';
export const TYPE_SCOUT_M = 'SCOUT_M';
export const TYPE_SCOUT_Y = 'SCOUT_Y';
export const TYPE_BUSINESS_Y = 'BUSINESS_Y';
export const TYPE_BUSINESS_M = 'BUSINESS_M';
export const TYPE_STARTER_Y = 'STARTER_Y';
export const TYPE_STARTER_M = 'STARTER_M';
export const TYPE_PREMIUM = 'PREMIUM';

const OPTIONS_AMOUNT_INPUT = {
  mask: Number,
  thousandsSeparator: " ",
  radix: '.'
};

const { user } = useUser();

const state = reactive({
  rates: {},
});

const pay = (pid, withTrial = true) =>
  new Promise((resolve, reject) => {
    post('payment/', {
      priceid: pid,
      command: 'pay',
      back: {
        notrial: !withTrial,
        domain: window.location.host,
        success: "payment/success",
        reject: "payment/reject"
      },
    })
      .then((res) => window.location.replace(res.link))
      .catch(reject);
  });

const manage = () => post('payment/', { command: 'manage' });

const cancel = () => post('payment/', { command: 'cancel' });

const getRates = async () => {
  const rates = await post('payment/', { command: 'rates' });

  rates.forEach((rate) => {
    state.rates[rate.type] = rate;
  });
};

export const tariffFeatures = {
  [TYPE_SEED_M]: [
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Advanced analytics dashboard', subtext: 'matches, views, connections, conversion' },
    { text: 'Access founder resources, reports, templates, and analytics', subtext: 'Pitch decks, legal docs, equity calc, etc.' },
    { text: 'Get up to $150K in perks from our partners', subtext: 'Carta, Firstbase, and other platforms' },
    { text: 'A feature on our community blog' },
    { text: 'Attend webinars with US tax & legal advisors' },
  ],
  [TYPE_SEED_Y]: [
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Advanced analytics dashboard', subtext: 'matches, views, connections, conversion' },
    { text: 'Access founder resources, reports, templates, and analytics', subtext: 'Pitch decks, legal docs, equity calc, etc.' },
    { text: 'Get up to $150K in perks from our partners', subtext: 'Carta, Firstbase, and other platforms' },
    { text: 'A feature on our community blog' },
    { text: 'Attend webinars with US tax & legal advisors' },
  ],
  [TYPE_SERIES_M]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'Connect with up to 5000+ verified investors outside GGW platform', subtext: 'we make the cold pitches for you' },
    { text: 'Get up to $500K in perks from our partners', subtext: 'Clerky, Hubspot, and other platforms' },
  ],
  [TYPE_SERIES_Y]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'Connect with up to 5000+ verified investors outside GGW platform', subtext: 'we make the cold pitches for you' },
    { text: '10% Discount for GGW services', subtext: 'Incorporation, accounting, taxes, etc.' },
    { text: 'Get up to $500K in perks from our partners', subtext: 'Clerky, Hubspot, and other platforms' },
    { text: 'A feature on our monthly newsletter' },
  ],
  [TYPE_SCOUT_Y]: [
    { text: 'Set up your investor profile', subtext: 'easy to share with the world via a personal link' },
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Direct messaging with your matches' },
    { text: 'Follow startups and get updates on relevant deals' },
    { text: 'Deal flow analytics dashboard' },
  ],
  [TYPE_SCOUT_M]: [
    { text: 'Set up your investor profile', subtext: 'easy to share with the world via a personal link' },
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Direct messaging with your matches' },
    { text: 'Follow startups and get updates on relevant deals' },
    { text: 'Deal flow analytics dashboard' },
  ],
  [TYPE_INVESTOR_Y]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'A feature on our community blog' },
    { text: 'A feature on our monthly newsletter' },
    { text: '<b>10% Discount</b> on GGW services' },
    { text: 'Expand your network with <b>5000+</b> verified investors outside GGW', subtext: 'we connect you with our pool of relevant contacts' },
  ],
  [TYPE_INVESTOR_M]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'A feature on our community blog' },
    { text: 'A feature on our monthly newsletter' },
    { text: '<b>10% Discount</b> on GGW services' },
    { text: 'Expand your network with <b>5000+</b> verified investors outside GGW', subtext: 'we connect you with our pool of relevant contacts' },
  ],
  [TYPE_BUSINESS_Y]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'A feature on our community blog' },
    { text: 'A feature on our monthly newsletter' },
    { text: '<b>10% Discount</b> on GGW services' },
  ],
  [TYPE_BUSINESS_M]: [
    { text: '<span class="text-[#008AFC]">Unlimited</span> curated matchmaking', subtext: 'no limits on connects and saves' },
    { text: 'GGW member directory', subtext: 'no limits on connects and saves' },
    { text: 'Get verified badge', subtext: 'boosts your profile’s credibility', isVerified: true },
    { text: 'A feature on our community blog' },
    { text: 'A feature on our monthly newsletter' },
    { text: '<b>10% Discount</b> on GGW services' },
  ],
  [TYPE_STARTER_Y]: [
    { text: 'Set up your advisor profile', subtext: 'easy to share with the world via a personal link' },
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'easy to share with the world via a personal link' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Direct messaging with your matches' },
    { text: 'Advanced analytics dashboard', subtext: 'matches, views, connections, conversion' },
  ],
  [TYPE_STARTER_M]: [
    { text: 'Set up your advisor profile', subtext: 'easy to share with the world via a personal link' },
    { text: 'GGW member directory <span class="text-[#D63D3D]">Limited</span>', subtext: 'easy to share with the world via a personal link' },
    { text: 'Curated matchmaking <span class="text-[#D63D3D]">Limited</span>', subtext: 'up to 10 connects or saves per day' },
    { text: 'Direct messaging with your matches' },
    { text: 'Advanced analytics dashboard', subtext: 'matches, views, connections, conversion' },
  ],
  [TYPE_MVP_Y]: [
    { text: 'Set up your startup’s profile', subtext: 'easy to share with the world via your startup branded link' },
    { text: 'Get viewed and receive incoming connects from GGW members' },
    { text: 'Direct messaging with your connections' },
    { text: 'Basic profile analytics', subtext: 'See info on your connections and views' },
    { text: 'View our MBA video course library' },
  ],
  [TYPE_MVP_M]: [
    { text: 'Set up your startup’s profile', subtext: 'easy to share with the world via your startup branded link' },
    { text: 'Get viewed and receive incoming connects from GGW members' },
    { text: 'Direct messaging with your connections' },
    { text: 'Basic profile analytics', subtext: 'See info on your connections and views' },
    { text: 'View our MBA video course library' },
  ],
  [`${USER_ROLE_STARTUP}_${TYPE_PREMIUM}`]: [
    { text: 'Personal manager', subtext: 'tailors your profile to your needs'},
    { text: 'Individual approach', subtext: 'we handpick matches & screen investors'},
  ],
  [`${USER_ROLE_INVESTOR}_${TYPE_PREMIUM}`]: [
    { text: 'Personal manager', subtext: 'tailors your profile to your needs'},
    { text: 'Individual approach', subtext: 'we handpick matches & screen deals'},
  ],
  [`${USER_ROLE_ADVISOR}_${TYPE_PREMIUM}`]: [
    { text: 'Personal manager', subtext: 'tailors your profile to your needs'},
    { text: 'Individual approach', subtext: 'we handpick matches & screen them'},
  ],
};

export const tariffList = {
  [USER_ROLE_INVESTOR]: [
    [TYPE_SCOUT_Y, TYPE_INVESTOR_Y, TYPE_PREMIUM],
    [TYPE_SCOUT_M, TYPE_INVESTOR_M, TYPE_PREMIUM],
  ],
  [USER_ROLE_ADVISOR]: [
    [TYPE_STARTER_Y, TYPE_BUSINESS_Y, TYPE_PREMIUM],
    [TYPE_STARTER_M, TYPE_BUSINESS_M, TYPE_PREMIUM],
  ],
  [USER_ROLE_STARTUP]: [
    [TYPE_MVP_Y, TYPE_SEED_Y, TYPE_SERIES_Y, TYPE_PREMIUM],
    [TYPE_MVP_M, TYPE_SEED_M, TYPE_SERIES_M, TYPE_PREMIUM],
  ],
};

const PREMIUM_TARIFF_TO_MAIN_TYPE = {
  [USER_ROLE_STARTUP]: TYPE_SERIES_Y,
  [USER_ROLE_ADVISOR]: TYPE_BUSINESS_Y,
  [USER_ROLE_INVESTOR]: TYPE_INVESTOR_Y,
};

const USER_ROLE_TO_TARIFF_LINK = {
  [USER_ROLE_STARTUP]: 'https://new.goglobal.world/startups/#startupsTariffs',
  [USER_ROLE_ADVISOR]: null,
  [USER_ROLE_INVESTOR]: 'https://new.goglobal.world/#tariffs',
};

class TariffInfo {
  constructor(info = {}, userInfo = {}) {
    this.isActive = userInfo.tariff === info.type;
    this.till = userInfo.tariff_ended_at
      ? DateEntity.format(
          userInfo.tariff_ended_at,
          DateEntity.FORMAT_DD_SLASH_MM_SLASH_YY,
        )
      : null;
    this.safePercent = info.old_price
      ? 100 - Math.floor((info.price / info.old_price) * 100)
      : null;
    this.priceOld = info.old_price
      ? `Billed at <span class="line-through">$${format(
          info.old_price ?? 0,
          OPTIONS_AMOUNT_INPUT,
        )}</span> $${format(info.price, OPTIONS_AMOUNT_INPUT)}`
      : null;
    this.priceNew = info.price
      ? `$${format(info.price, OPTIONS_AMOUNT_INPUT)}`
      : null;
    this.priceMonth = info.month_price
      ? `$${format(info.month_price, OPTIONS_AMOUNT_INPUT)}`
      : null;

    this.sale = info.tariff_discount;
    this.lists = tariffFeatures[info.type] || [];
    this.priceId = info.pid;
    this.title = info.name;
    this.type = info.type;
    this.promocode_value = info.promocode_value;
    this.promocode = info.promocode;
    this.link = USER_ROLE_TO_TARIFF_LINK[userInfo.role];
    this.theme = [
      TYPE_SERIES_Y,
      TYPE_SERIES_M,
      TYPE_INVESTOR_Y,
      TYPE_INVESTOR_M,
      TYPE_BUSINESS_M,
      TYPE_BUSINESS_Y,
    ].includes(info.type)
      ? 'yellow'
      : [TYPE_MVP_M, TYPE_MVP_Y].includes(info.type)
      ? 'white'
      : 'blue';
  }
}

class PremiumTariff {
  constructor(baseTariff = {}, role) {
    this.isPremium = true;
    this.lists = tariffFeatures[`${role}_${TYPE_PREMIUM}`] || [];
    this.title = TYPE_PREMIUM;
    this.type = TYPE_PREMIUM;
  }
}

export default function usePayments() {
  const list = computed(() => {
    if (Object.keys(state.rates).length === 0) {
      return [[], []];
    }

    const [annual, monthly] = tariffList[user.role] || [[], []];

    const annualResult = annual.map((type) => {
      const info = state.rates[type];

      if (type === TYPE_PREMIUM) {
        return new PremiumTariff(
          state.rates[PREMIUM_TARIFF_TO_MAIN_TYPE[user.role]],
          user.role
        );
      }

      return info ? new TariffInfo(info, user) : null;
    });

    const monthlyResult = monthly.map((type) => {
      const info = state.rates[type];

      if (type === TYPE_PREMIUM) {
        return new PremiumTariff(
          state.rates[PREMIUM_TARIFF_TO_MAIN_TYPE[user.role]],
          user.role
        );
      }

      return info ? new TariffInfo(info, user) : null;
    });

    return [annualResult.filter(Boolean), monthlyResult.filter(Boolean)];
  });

  return {
    state,
    list,
    getRates,
    pay,
    manage,
    cancel
  };
}
