<template>
  <div class="relative">
    <div class="sm:w-[80px] w-14 h-14 sm:h-[80px] rounded overflow-hidden">
      <img
        v-if="photo"
        class="w-full h-full object-cover object-center"
        :src="photo"
        alt="photo"
      />
      <EmptyDiv v-else class="w-full h-full flex-none">
        <img
          class="lg:w-1/3"
          src="/src/assets/images/profile/camera-icon.svg"
          alt=""
        />
      </EmptyDiv>
    </div>
    <div
      v-if="isViewed"
      class="absolute top-0.5 left-0.5 px-1 rounded-sm bg-black/70 text-white text-[10px] leading-[14px] font-bold"
    >
      Viewed
    </div>
  </div>
</template>
<script setup>
import EmptyDiv from '@/screens/update/components/EmptyDiv.vue';

defineProps({
  isViewed: {
    type: Boolean,
    default: false,
  },
  photo: {
    type: String,
    default: '',
  },
});
</script>
