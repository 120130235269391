import { ref } from 'vue';
import { useElementBounding } from '@vueuse/core';

const el = ref(null);

const { height } = useElementBounding(el);

export default function useFloatingPanel() {
  return {
    el,
    height,
  };
}
