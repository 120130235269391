import { reactive } from 'vue';
import { debounce } from 'lodash';

import DateEntity from '../entities/DateEntity';
import { post } from '../services/httpClient';

const state = reactive({
  unread: 0,
  dialogs: {},
});

const prepareDate = (dialogs) =>
  dialogs.map((dialog) => ({
    ...dialog,
    time: DateEntity.format(
      DateEntity.addTZOffset(dialog.time),
      DateEntity.FORMAT_SERVER,
    ),
  }));

const groupDialogs = (dialogs) =>
  dialogs.reduce((acc, item) => {
    const date = new Date(item.time);

    date.setHours(0, 0, 0);

    const group = date.toISOString();

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].unshift({
      ...item,
      time: DateEntity.format(item.time, DateEntity.FORMAT_HH_MM_AMPM),
    });

    return acc;
  }, {});

const sortDialogs = (dialogs) =>
  Object.keys(dialogs)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((time) => {
      let groupName;

      if (DateEntity.isToday(time)) {
        groupName = 'today';
      } else if (DateEntity.isYesterday(time)) {
        groupName = 'yesterday';
      } else {
        groupName = DateEntity.format(time, DateEntity.FORMAT_MMMM_DD);
      }

      return {
        name: groupName,
        items: dialogs[time],
      };
    });

const messages = (command, params = {}) =>
  post('messages/', {
    command,
    ...params,
  });

const unread = debounce(
  () =>
    messages('unread').then((response) => {
      state.unread = response.unreaded;
    }),
  400,
);
const dialogs = (params = {}) => messages('dialogs', params).finally(unread);
const list = (id) =>
  messages('list', { dialog_id: id })
    .then((response) => {
      response.list = sortDialogs(groupDialogs(prepareDate(response.list)));

      return response;
    })
    .finally(unread);
const send = (id, text) => messages('send', { dialog_id: id, text });

export default function useMessages() {
  return {
    state,

    dialogs,
    list,
    unread,
    send,
  };
}
