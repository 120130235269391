import useDialogs from '@/compositions/useDialogs';

export default async function loadDialogs(to, from, next) {
  const { fetchDialogs } = useDialogs();
  try {
    await fetchDialogs();
    next();
  } catch (error) {
    next(false);
  }
}
