import {
  authMiddleware,
  investorProfileMiddleware,
  profileMiddleware,
  profileStatusMiddleware,
  userRoleMiddleware,
  prefetchDashboardMiddleware,
} from '@/router/middlewares';

export default [
  {
    path: '/profile',
    children: [
      {
        name: 'SettingsScreen',
        path: 'settings',
        component: () => import('../../screens/settings/Settings.vue'),
        children: [
          {
            name: 'AccountSettings',
            path: 'account',
            component: () =>
              import('@/screens/settings/components/Account.vue'),
          },
          {
            name: 'SubscriptionSettings',
            path: 'subscription',
            component: () =>
              import('@/screens/settings/components/Subscription.vue'),
          },
          {
            name: 'EmailSettings',
            path: 'email',
            component: () =>
              import('@/screens/settings/components/ChangeEmail.vue'),
          },
          {
            name: 'PasswordSettings',
            path: 'password',
            component: () =>
              import('@/screens/settings/components/ChangePassword.vue'),
          },
          {
            name: 'LegalSettings',
            path: 'legal',
            component: () => import('@/screens/settings/components/Legal.vue'),
          },
        ],
        meta: {
          middlewares: [authMiddleware(), userRoleMiddleware],
          root: true,
        },
      },
    ],
  },
  {
    name: 'ProfileInvestor',
    component: () => import('@/screens/profile-investor/ProfileInvestor.vue'),
    path: '/demo/:alias',
  },
  {
    name: 'ProfileInvest',
    component: () => import('@/screens/profile-investor/ProfileInvest.vue'),
    path: '/investor/:id',
    meta: {
      prefetchMiddlewares: [investorProfileMiddleware],
    },
  },
  {
    name: 'ProfileAdvisor',
    component: () => import('@/screens/profile-advisor/ProfileAdvisor.vue'),
    path: '/advisor/:id',
    meta: {
      prefetchMiddlewares: [investorProfileMiddleware],
    },
  },
  {
    name: 'DemoProfileAdvisor',
    component: () => import('@/screens/profile-advisor/DemoProfileAdvisor.vue'),
    path: '/demo-profile-advisor',
  },
  {
    name: 'ProfileStartup',
    component: () => import('@/screens/profile-desktop/ProfileDesktop.vue'),
    path: '/startup/:id',
    meta: {
      middlewares: [
        profileMiddleware,
        userRoleMiddleware,
        profileStatusMiddleware,
      ],
    },
  },
  {
    name: 'PublicStartupProfile',
    component: () => import('@/screens/profile-desktop/ProfileDesktop.vue'),
    path: '/startup/:id',
  },
  {
    name: 'Dashboard',
    component: () => import('@/screens/dashboard/Dashboard.vue'),
    path: '/apply-success',
    meta: {
      middlewares: [authMiddleware()],
    },
  },
  {
    name: 'ProfileDashboard',
    component: () => import('@/screens/Dashboard.vue'),
    path: '/dashboard',
    meta: {
      middlewares: [authMiddleware(), userRoleMiddleware],
      prefetchMiddlewares: [prefetchDashboardMiddleware],
    },
  },
];
