<template>
  <router-link :to="{ path: `event/${item.id}` }" class="block">
    <img class="rounded w-full" :src="item.picture" alt="" />
    <p class="mt-4 text-[#D1410C] text-base lg:text-sm font-bold">
      <!-- {{ item.label }}-->
      {{ startDateFormatted }} • {{ startTimeFormatted }} PST
    </p>
    <p class="mt-2 text-xl lg:text-2xl text-[#292C2E] font-bold">
      {{ item.name }}
    </p>
    <p class="mt-2 text-[#676C71] text-sm">
      {{ item.summary }}
    </p>
    <div
      class="mt-3 lg:mt-4 inline-flex bg-[#F3F5F8] rounded py-1 lg:py-0.5 px-1.5 uppercase text-[#292C2E] font-bold text-xs lg:text-[10px] lg:leading-[14px]"
    >
      {{ item.price_level }}
    </div>
  </router-link>
</template>

<script setup>
import useEvents from '@/compositions/useEvents';

const { formatEventDate, formatEventTime } = useEvents();

const props = defineProps({
  item: { type: Object, default: () => ({}) },
});

const startDateFormatted = formatEventDate(props.item?.started_at);
const startTimeFormatted = formatEventTime(props.item?.started_at);
</script>
