<template>
  <MainLayout :transparent-background="true">
    <MainContent>
      <div class="p-4 mt-14 h-full flex justify-center flex-col">
        <h2 class="text-2xl font-bold"> Please check your email </h2>
        <p class="mt-1 text-[#292C2E] text-base">
          We’ve sent a code <br />
          to {{ state.email }}
        </p>

        <p class="mt-4 text-sm text-[#676C71]">
          Please enter the verification code from the email
        </p>
        <OtpCodeInput
          ref="otpRef"
          v-model="otpCode"
          class="mt-2"
          :is-error="Boolean(error)"
          @update:is-error="error = ''"
        />
        <div v-if="error" class="mt-2 text-sm text-[#EB3B5A] text-center">
          {{ error }}
        </div>

        <div class="my-3 flex justify-between">
          <Btn
            v-if="canResendCode"
            type="button"
            class="button xs max-w-max text-base text-[#008AFC]"
            :disabled="isProcessing"
            @click="onResend"
          >
            Resend code to my email
          </Btn>
          <Countdown
            v-else
            #="{ seconds }"
            :date="new Date(Date.now() + 60000)"
            @finish="canResendCode = true"
          >
            <span class="tabular-nums text-base text-[#AAAAAA]">
              Resend code 00:{{ seconds }}
            </span>
          </Countdown>

          <button class="text-[#008AFC] text-right" @click="onPaste">
            Paste
          </button>
        </div>

        <div class="mt-auto relative flex items-center gap-2">
          <input
            id="comments"
            v-model="state.rememberMe"
            aria-describedby="comments-description"
            type="checkbox"
            class="h-4 w-4 rounded border-[#DFE4E8] text-[#008AFC]"
          />
          <label
            class="text-base leading-5 text-left text-[#676C71]"
            for="comments"
          >
            Remember this device
          </label>
        </div>

        <Btn
          class="mt-4"
          type="button"
          :is-loading="submitIsProcessing"
          :disabled="otpCode.length === 0"
          @click="submitRequest"
        >
          Next
        </Btn>
      </div>
    </MainContent>
  </MainLayout>
</template>

<script setup>
import { ref } from 'vue';

import useAuth from '@/compositions/useAuth.js';
import Btn from '@/components/Btn.vue';
import useRequest from '@/compositions/useRequest.js';
import MainLayout from '@/layouts/MainLayout.vue';
import MainContent from '@/layouts/MainContent.vue';

import OtpCodeInput from '@/components/OtpCodeInput.vue';
import Countdown from '@/components/Countdown.vue';
import { getClipboardText } from '@/services/clipboardService.js';

const { state, signIn, verify } = useAuth();

const otpRef = ref(null);
const otpCode = ref('');
const error = ref('');
const canResendCode = ref(true);

const resend = async () => {
  await signIn(state.email, state.password);
};
const { isProcessing, request: resendRequest } = useRequest(resend);

const onResend = () => {
  canResendCode.value = false;
  resendRequest();
};

const submit = async () => {
  try {
    await verify(state.email, state.password, otpCode.value);
  } catch (errorResponse) {
    if (Array.isArray(errorResponse.errors)) {
      return errorResponse.errors.forEach((item) => {
        if (item.field === 'code') {
          error.value = item.error;
        }
      });
    }
  }
};

const { isProcessing: submitIsProcessing, request: submitRequest } =
  useRequest(submit);

const onPaste = async () => {
  const text = await getClipboardText();

  otpRef.value.paste(text);
};
</script>
