<template>
  <div
    class="px-0 py-4 lg:p-4 rounded flex gap-3 sm:gap-4 hover:bg-[#F3F5F8] group transition relative"
    :class="{ 'cursor-pointer': !locked }"
    @click="routeToProfile"
  >
    <LockBlock v-if="locked" link="/updating-plan" style="z-index: 100">
      <template #link> Upgrade plan </template>
      <template #text> to see all members </template>
    </LockBlock>
    <div class="relative">
      <div class="sm:w-[80px] w-14 h-14 sm:h-[80px] rounded overflow-hidden">
        <img
          v-if="member.photo"
          class="w-full h-full object-cover object-center"
          :src="member.photo"
          alt=""
        />
        <EmptyDiv v-else class="w-full h-full flex-none">
          <img
            class="lg:w-1/3"
            src="/src/assets/images/profile/camera-icon.svg"
            alt=""
          />
        </EmptyDiv>
      </div>
      <div
        v-if="member.viewed"
        class="absolute top-0.5 left-0.5 px-1 rounded-sm bg-black/70 text-white text-[10px] leading-[14px] font-bold"
      >
        Viewed
      </div>
    </div>
    <div class="sm:flex gap-2 justify-between items-start flex-1">
      <div>
        <div class="flex gap-1 items-center">
          <div
            class="text-base text-[#292C2E] font-bold group-hover:underline transition"
          >
            {{ member.name }}
          </div>
          <img
            v-if="member.is_verified"
            class="w-4"
            src="/src/assets/images/profile-desktop/check-verification.svg"
            alt=""
          />
          <div
            class="text-[#292C2E] text-[10px] leading-[14px] font-bold py-0.6 px-1.5 border border-[#DFE4E8] bg-[#F3F5F8] rounded uppercase"
          >
            {{ member.type }}
          </div>
        </div>
        <div class="text-sm text-[#676C71]">
          {{ member.headliner }}
        </div>
        <div
          :class="[
            member.status.includes('Actively')
              ? 'text-[#149911]'
              : 'text-[#292C2E]',
          ]"
          class="text-sm font-bold"
        >
          {{ member.status }}
        </div>
        <div class="mt-1 text-sm text-[#676C71] flex gap-1.5 items-center">
          <img
            src="/src/assets/images/profile-desktop/map-pin-icon.svg"
            alt=""
          />
          <span>{{ member.geography }}</span>
        </div>
        <p
          v-if="!minified && member.canHelp"
          class="mt-1 text-sm text-[#676C71]"
        >
          I can help with:
          <span class="text-[#292C2E]">{{ member.canHelp }}</span>
        </p>
        <p
          v-if="!minified && member.needHelp"
          class="mt-1 text-sm text-[#676C71]"
        >
          I need help with:
          <span class="text-[#292C2E]">{{ member.needHelp }}</span>
        </p>
      </div>
      <Btn
        v-if="member.dialog_id"
        theme="outline"
        class="mt-2 sm:mt-0 max-w-max bg-white disabled:bg-[#F3F5F8] disabled:!text-[#9DA1A6]"
        size="xs"
        @click.stop="routeToDialog"
      >
        Message
      </Btn>
      <template v-else>
        <Btn
          v-if="member.action === COMMAND_LIKE"
          theme="outline"
          class="mt-2 sm:mt-0 max-w-max bg-white disabled:bg-[#F3F5F8] disabled:!text-[#9DA1A6]"
          size="xs"
          :disabled="true"
        >
          Request sent
        </Btn>
	<Btn
	  v-if="member.action === COMMAND_DISLIKE"
	  theme="outline"
	  class="mt-2 sm:mt-0 max-w-max bg-white disabled:bg-[#F3F5F8] disabled:!text-[#9DA1A6]"
	  size="xs"
	  :disabled="true"
	>
	  Pass
	</Btn>
        <Btn
          v-if="member.action === COMMAND_THINK"
          theme="outline"
          class="mt-2 sm:mt-0 max-w-max bg-white disabled:bg-[#F3F5F8] disabled:!text-[#9DA1A6]"
          size="xs"
          :disabled="true"
        >
          Saved for later
        </Btn>
      </template>
    </div>
  </div>
</template>

<script setup>
import Btn from '@/components/Btn.vue';
import LockBlock from '@/screens/dashboard-desktop/components/LockBlock.vue';
import { COMMAND_LIKE, COMMAND_THINK, COMMAND_DISLIKE } from '@/compositions/useMatching';
import { useRouter } from 'vue-router';
import { USER_ROLE_INVESTOR, USER_ROLE_STARTUP, USER_ROLE_ADVISOR } from '@/constants.js';
import EmptyDiv from '../../update/components/EmptyDiv.vue';

const props = defineProps({
  member: { type: Object, default: () => ({}) },
  locked: { type: Boolean, default: false },
  minified: { type: Boolean, default: false },
});

const router = useRouter();

function routeToDialog() {
  router.push({ name: 'DialogScreen', params: { id: props.member.dialog_id } });
}
function routeToProfile() {
  if (!props.locked) {
    props.member.viewed = true;

    if (props.member.type === USER_ROLE_INVESTOR) {
      openInNewWindow({
        name: 'ProfileInvest',
        params: { id: props.member.id },
      });
    } else if (props.member.type === USER_ROLE_STARTUP) {
      openInNewWindow({
        name: 'ProfileStartup',
        params: { id: props.member.id },
      });
    } else if (props.member.type === USER_ROLE_ADVISOR) {
      openInNewWindow({
        name: 'ProfileAdvisor',
        params: { id: props.member.id },
      });
    }
  }
}

function openInNewWindow(routerData) {
  const routeData = router.resolve(routerData);

  window.open(routeData.href, '_blank');
}
</script>
