import { reactive } from 'vue';
import useFilters from './useFilters.js';

export const ALL = 'all';
export const CONNECTION_REQUESTS = 'connection-requests';
export const FUNDING_REQUESTS = 'funding-requests';
export const FOR_LATER = 'for-later';
export const MY_CONNECTION_REQUESTS = 'action-connect';
export const MY_PASS = 'action-pass';
export const MY_FOR_LATER = 'action-for-later';

export const MY_FUNDING_REQUESTS = 'my-funding-requests';

const state = reactive({
  userType: null,
  relevant: null,
  type: null,
});

export default function (type) {
  const { store, restore } = useFilters(`${type}-filters`);

  if (state.type !== type) {
    //        try {
    //            const fromCookies = restore();
    //
    //            state.userType = fromCookies.userType;
    //            state.relevant = fromCookies.relevant;
    //            state.type = fromCookies.type;
    //        } catch (e) {
    state.userType = ALL;
    state.relevant = true;
    state.type = type;
    //        }
  }

  return {
    state,
    save: () => store(state),
  };
}
