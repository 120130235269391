<template>
  <div
    class="bg-white relative px-4 py-3 sm:px-6 sm:py-4 rounded"
  >
    <slot />

    <button
      v-if="isShowEditButton"
      type="button"
      class="absolute right-4 top-3 sm:top-4 p-1 rounded-full hover:bg-gray-300 transition-colors z-10"
      @click="onClick"
    >
      <img class="w-4" src="/src/assets/images/pencil-icon.svg" alt="">
    </button>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { BLOCK_ID_TO_BLOCK_INDEX_MAP } from '@/constants';

const router = useRouter();
const props = defineProps({
  blockId: { type: [Number, String], default: null },
  isShow: { type: Boolean, default: false },
});

const isOwnAccount = inject('isOwnAccount');

const isShowEditButton = computed(
  () => props.isShow && isOwnAccount.value,
);
const onClick = () => {
  if (props.blockId) {
    const blockIndex = BLOCK_ID_TO_BLOCK_INDEX_MAP[props.blockId]
        ?? props.blockId;

    router.push({
      name: 'OnBoardingBlockScreen',
      query: { edit: blockIndex },
    });
  }
};

</script>
