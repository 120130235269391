import {
  authMiddleware,
  logOutMiddleware,
} from '@/router/middlewares';

import Login from '@/screens/log-in/Login.vue';
import SignUp from '@/screens/sign-up/SignUp.vue';
import Verification from '@/screens/sign-up/Verification.vue';
import WelcomeScreen from '@/screens/sign-up/WelcomeScreen.vue';

export default [
  {
    name: 'WelcomeScreen',
    component: WelcomeScreen,
    path: '/',
    meta: {
      middlewares: [authMiddleware(true)],
    },
  },
  {
    name: 'SignUp',
    component: SignUp,
    path: '/sign-up',
    meta: {
      middlewares: [authMiddleware(true)],
    },
  },
  {
    name: 'LoginScreen',
    component: Login,
    path: '/login',
    meta: {
      middlewares: [authMiddleware(true)],
    },
  },
  {
    name: 'VerificationScreen',
    component: Verification,
    path: '/verification',
    meta: {
      middlewares: [authMiddleware(true)],
    },
  },
  {
    name: 'LogOut',
    path: '/logout',
    meta: {
      middlewares: [logOutMiddleware],
    },
    component: null,
  },
  {
    name: 'ForgotPasswordScreen',
    component: () => import('@/screens/log-in/ForgotPassword.vue'),
    path: '/forgot-password',
    meta: {
      middlewares: [authMiddleware(true)],
    },
  },
];
