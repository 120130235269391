import useUser from '@/compositions/useUser';
import useBlocks from '@/compositions/useBlocks';
import useDashboard from '@/compositions/useDashboard';

const { user } = useUser();
const { getAll } = useBlocks();
const { getAll: getAllStats } = useDashboard();

export default async function prefetchDashboardMiddleware(to, from, next) {
  try {
    await Promise.all([
      getAll(user.user_id, 'profile'),
      getAllStats(),
    ]);

    next();
  } catch (e) {
    next(false);
  }
}
