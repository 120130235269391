<template>
  <slot
    :days="days"
    :hours="hours"
    :minutes="minutes"
    :seconds="seconds"
  />
</template>

<script setup>
import { watch } from 'vue';
import useCountdown, { MINUTE } from '../compositions/useCountdown';

const props = defineProps({
  date: { type: Date, default: new Date(Date.now() + MINUTE) },
});

const emit = defineEmits({
  finish: null,
});

const {
  days, hours, minutes, seconds, isExpired,
} = useCountdown(props.date);

watch(isExpired, () => {
  emit('finish');
});
</script>
