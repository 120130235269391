const callbacks = [];

function onPageFullyScrolled(callback) {
    callbacks.push(callback);

    window.addEventListener('scroll', onScrollDesktop);
    document.querySelector('main>div').addEventListener('scroll', onScrollMobile);
}

function offPageFullyScrolled() {
    window.removeEventListener('scroll', onScrollDesktop);
    document.querySelector('main>div').removeEventListener('scroll', onScrollMobile);
}

function onScrollDesktop() {
    const scrolled = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;

    if (documentHeight - (scrolled + windowHeight) < 1) {
        for (const callback of callbacks) {
            callback();
        }
    }
}

function onScrollMobile(e) {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;

    if ((scrollTop + offsetHeight) >= scrollHeight) {
        for (const callback of callbacks) {
            callback();
        }
    }
}

export default function () {
    return {
        onPageFullyScrolled,
        offPageFullyScrolled
    };
}
