<template>
  <form ref="formRef" class="flex gap-2 justify-center">
    <input
      v-for="(_, index) in length"
      :key="index"
      v-model="code[index]"
      class="otp-input"
      :class="{ filled: code[index], error: isError }"
      maxlength="1"
      inputmode="numeric"
      @input="onInput(index)"
      @keydown.delete="onDelete($event.target.value, index)"
      @paste="onPaste"
      @click="onClick(index)"
      @focus="onFocus(index)"
    />
    <input :name="name" :value="value" type="hidden" />
  </form>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: { type: String, default: '' },
  isError: { type: Boolean, default: false },
  duration: { type: Number, default: 2000 },
  name: { type: String, default: 'otp' },
});

const emit = defineEmits({
  'update:model-value': null,
  'update:is-error': null,
});

useField(props.name);

const length = 5;
const formRef = ref(null);
const code = ref(
  Array.from(Array(length)).map((_, index) =>
    props.modelValue[index] ? props.modelValue[index] : '',
  ),
);

const inputs = ref([]);

function goTo(index) {
  if (inputs.value[index]) inputs.value[index].focus();
}

function onInput(index) {
  if (code.value[index] !== '') goTo(index + 1);
}

function onDelete(value, index) {
  if (value === '') {
    goTo(index - 1);
  }
}

function onPaste(event) {
  const data = event.clipboardData
    .getData('text/plain')
    .trim()
    .slice(0, length);

  code.value = data.split('');
  goTo(data.length - 1);
}

function onClick(index) {
  const input = inputs.value[index];

  input.setSelectionRange(input.value.length, input.value.length);
  input.focus();
}

function onFocus(index) {
  const codeLength = code.value.join('').length;

  if (index > codeLength) {
    goTo(codeLength);
  }
}

function paste(value) {
  code.value = Array.from(Array(length)).map((_, index) =>
    value[index] ? value[index] : '',
  );
}

watch(
  code,
  (array) => {
    emit('update:model-value', array.join(''));
  },
  { deep: true },
);

watch(
  () => props.isError,
  (isError) => {
    if (isError) {
      setTimeout(() => {
        emit('update:is-error', false);
      }, props.duration);
    }
  },
);

onMounted(() => {
  inputs.value = formRef.value.elements;
  inputs.value[0].focus();
});

defineExpose({ paste });
</script>

<style scoped>
.otp-input {
  @apply max-w-[62px] h-14 text-center;
  @apply rounded border w-full text-lg outline-none;
  @apply bg-[#F3F5F8] border-transparent;
}

.otp-input.filled {
  @apply border-[#676C71] bg-transparent;
}
.otp-input.error,
.otp-input.filled.error {
  @apply border-[#EB3B5A];
}
</style>
